import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { CircularProgress, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: NovaColours.N100[theme.palette.mode],
      opacity: '0.95',
      textAlign: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0px',
      padding: '24px',
      marginTop: '30px',
    },
    spinner: {
      color: NovaColours.AP060[theme.palette.mode],
    },
  }),
)

interface Props {
  loading?: boolean
}

export const LoadingOverlay: React.FC<Props> = ({ loading }) => {
  const classes = useStyles({})

  return (
    <Plugin>
      <Template name="root">
        <div>
          <TemplatePlaceholder />
          {loading && (
            <div className={classes.root}>
              <CircularProgress className={classes.spinner} />
            </div>
          )}
        </div>
      </Template>
    </Plugin>
  )
}
