import dayjs from 'dayjs'
import React, { useRef } from 'react'

export interface IDateFilterProps {
  date: dayjs.Dayjs | null
  setDate: (value: dayjs.Dayjs | null | string) => void
  dateLabel: string
  dateStructure: string
  error: boolean
  helperText: () => JSX.Element
}

export interface IHookProps {
  setDate: (value: dayjs.Dayjs | null | string) => void
  dateStructure: string
}

export interface IDatePickerProps {
  errorHandler: (error: React.ReactNode) => void
  date: dayjs.Dayjs | null
  handleDateChange: (date: dayjs.Dayjs | null) => void
  dateLabel: string
  dateStructure: string
  error?: boolean
  helperText?: () => JSX.Element
}

export const useDatePicker = ({
  setDate,
  dateStructure,
}: IHookProps): [
  (error: React.ReactNode) => void,
  (date: dayjs.Dayjs | null) => void,
] => {
  const errorRef = useRef<any>()

  const handleDateChange = (date: dayjs.Dayjs | null): void => {
    if (date !== null) {
      return setDate(dayjs(date).format(dateStructure))
    }
    return setDate(null)
  }

  const errorHandler = (error: React.ReactNode) => {
    errorRef.current = true
    if (error) {
      errorRef.current = false
    }
  }

  return [errorHandler, handleDateChange]
}
