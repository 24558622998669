import { SvgIcon, SvgIconProps } from '@mui/material'
import { forwardRef } from 'react'

export const MobileNotificationIcon = forwardRef(
  (iconProps: SvgIconProps, ref) => {
    return (
      <SvgIcon {...iconProps} ref={ref as any}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 1L17 1.01C18.1 1.01 19 1.9 19 3V21C19 22.1 18.1 23 17 23H7C5.9 23 5 22.1 5 21V3C5 1.9 5.9 1 7 1ZM7 19H17V5H7V19Z"
        />
        <path d="M13 7.5H11V11.5H13V7.5Z" />
        <path d="M13 13.5H11V15.5H13V13.5Z" />
      </SvgIcon>
    )
  },
)
