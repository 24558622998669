import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      display: 'flex',
      padding: theme.spacing(3, 3),
      backgroundColor: '#FAFBFC',
      alignItems: 'center',
    },
    dropdown: {
      background: '#FFFFFF',
      border: '1px solid #E9E9E9',
      boxSizing: 'border-box',
      borderRadius: '6px',
      width: '20%',
      marginRight: '15px',
    },
    customisationIcon: {
      // fill: theme.palette.primary.main,
      marginRight: theme.spacing(2.25),
    },
    chip: {
      display: 'none',
    },
  }),
)

export default useStyles
