import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material'
import { BasicTextFields } from '@novafuturltd/shared'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import {
  ActionButton,
  CancelButton,
} from '@novafuturltd/shared'

interface IFormInput {
  id: string
  title: string
  description: string
}

interface Props {
  open: boolean
  handleClose: () => void
  duplicate: any
  createDuplicate: any,
  title:string
  subTitle?:string
  isVisisbleDescription?:boolean
}

export const DuplicateModal: React.FC<Props> = ({
  open,
  handleClose,
  createDuplicate,
  duplicate,
  title,
  subTitle,
  isVisisbleDescription
}) => {
  const intl = useIntl()
  const { control, errors, handleSubmit } = useForm<IFormInput>()

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle style={{ paddingBottom: '16px' }}>
        {title}
        </DialogTitle>
        <DialogContent>
          <form>
            <DialogContentText id="alert-dialog-description">
             {subTitle}
            </DialogContentText>
              <Grid container={true} spacing={2}>
                <Grid item={true} sm={12}>
                  <Controller
                    as={
                      <BasicTextFields
                        label="Duplicate Name"
                        id="duplicateName"
                        size="medium"
                        variant="outlined"
                        fullWidth={true}
                        error={errors.title && true}
                        helperText={errors.title && errors.title.message}
                        style={{
                          marginTop: '24px',
                        }}
                      />
                    }
                    name="title"
                    rules={{
                      required: {
                        value: true,
                        message: 'Please input a duplicate title',
                      },
                    }}
                    control={control}
                    defaultValue={duplicate?.title && duplicate.title}
                  />
                </Grid>
                {isVisisbleDescription && (
                  <Grid item={true} sm={12}>
                    <Controller
                      as={
                        <BasicTextFields
                          label="Description"
                          id="description"
                          variant="outlined"
                          multiline={true}
                          fullWidth={true}
                          rows={4}
                          style={{
                            marginTop: '24px',
                          }}
                        />
                      }
                      name="description"
                      control={control}
                      defaultValue=""
                    />
                  </Grid>
                )}
              </Grid>
            
          </form>
        </DialogContent>
        <DialogActions style={{ marginRight: 24, paddingRight: 0 }}>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ActionButton onClick={handleSubmit(createDuplicate)}>
            {intl.formatMessage({
              id: 'campaign.duplicate.modal.submit',
            })}
          </ActionButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
//export default DuplicateModal
