import { UKFlagIconSvg } from '@novafuturltd/shared'
import { EUFlagIconSvg } from '@novafuturltd/shared'
import { USAFlagIconSvg } from '@novafuturltd/shared'
import { createContext, useContext, useMemo } from 'react'
import { captureMessage, Severity } from '../modules/sentry-integration'
import { useGetExchangeRatesQuery } from '../hooks/useGetExchangeRatesQuery'
import { COLFlagIcon } from '@novafuturltd/shared'
import { NGNFlagIcon } from '@novafuturltd/shared'
import { useOAuth } from '@novafuturltd/core'
import { useOrganizationSettings } from '../containers/OrganisationSettings/context'

import { useUserSettings } from '../containers/UserSettings/context'
import useUpdateUserSettingsParams from '../containers/UserSettings/hooks/useUpdateUserSettingsParams'

const style = { height: '16px' }

export const currencies = [
  {
    code: 'USD',
    symbol: '$',
    flag: <USAFlagIconSvg style={style} />,
  },
  {
    code: 'EUR',
    symbol: '€',
    flag: <EUFlagIconSvg style={style} />,
  },
  {
    code: 'GBP',
    symbol: '£',
    flag: <UKFlagIconSvg style={style} />,
  },
  {
    code: 'COP',
    symbol: '₱',
    flag: <COLFlagIcon style={style} />,
  },
  {
    code: 'NGN',
    symbol: '₦',
    flag: <NGNFlagIcon style={style} />,
  },
] as const

export type CurrencyCode = typeof currencies[number]['code']

export type CurrencyPreferenceContextType = {
  currencyPreference: typeof currencies[number] | null
  setCurrencyPreference: (currency: typeof currencies[number]['code']) => void
  effectiveCurrency: typeof currencies[number]
  exchangeRatesQuery?: ReturnType<typeof useGetExchangeRatesQuery>['query']
}

const defaultValue = currencies[0]

export const CurrencyPreferenceContext = createContext<
  CurrencyPreferenceContextType
>({
  currencyPreference: null,
  effectiveCurrency: defaultValue,
  setCurrencyPreference: () => {},
  exchangeRatesQuery: undefined,
})

export const useCurrencyPreferenceContext = () => {
  return useContext(CurrencyPreferenceContext)
}

const CurrencyPreferenceContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { organizationId } = useOAuth()
  const { mutate: updateUserSetting } = useUpdateUserSettingsParams()

  const {
    selectedUserCurrency,
    query: { data: module },
  } = useUserSettings()

  const selectedUserCurrencyValue = useMemo(
    () => currencies.find(currency => currency.code === selectedUserCurrency),
    [module],
  )

  const setCurrencyPreferenceCode = (currency: CurrencyCode) => {
    updateUserSetting({
      body: {
        params: {
          currency: currency,
        },
      },
    })
  }

  const { query: exchangeRatesQuery } = useGetExchangeRatesQuery({
    queryParams: {
      // date: '2021-01-01',
    },
    onError: error => {
      captureMessage(`Error fetching exchange rates for organization`, {
        level: 'warning',
        extra: {
          organizationId,
          error,
        },
      })
      setCurrencyPreferenceCode(defaultValue.code)
    },
    onSuccess: () => {},
  })

  const {
    defaultOrgCurrency,
    query: { data: modules },
  } = useOrganizationSettings()

  const defaultOrgCurrencyValue = useMemo(
    () => currencies.find(currency => currency.code === defaultOrgCurrency),
    [modules],
  )

  const currencyPreference = useMemo(() => {
    let result = currencies.find(
      currency => currency.code === selectedUserCurrencyValue?.code,
    )
    if (!result) result = defaultOrgCurrencyValue
    return result || null
  }, [defaultOrgCurrencyValue, selectedUserCurrencyValue])

  const setCurrencyPreference = (
    currency: typeof currencies[number]['code'],
  ) => {
    // check if currency in currencies
    if (currencies.find(c => c.code === currency)) {
      setCurrencyPreferenceCode(currency)
    } else {
      captureMessage(
        `Could not find currency config for code: ${currency}`,
        'warning' as Severity,
      )
    }
  }

  const effectiveCurrency = currencyPreference || defaultValue

  return (
    <CurrencyPreferenceContext.Provider
      value={{
        currencyPreference,
        setCurrencyPreference,
        exchangeRatesQuery,
        effectiveCurrency,
      }}
    >
      {children}
    </CurrencyPreferenceContext.Provider>
  )
}

export default CurrencyPreferenceContextProvider
