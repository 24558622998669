import { FC, useEffect, useState } from 'react'

import {
  Column,
  CustomGrouping,
  CustomGroupingProps,
  CustomPaging,
  DataTypeProvider,
  FilteringState,
  Grouping,
  GroupingState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  Row,
  Sorting,
  SortingState,
  SummaryState,
  SummaryStateProps,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import { Paper, TableCell, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel'
import React from 'react'
import { ActionColumns } from '../../../helpers/grids/action.column'
import { InfinityPagingPanel } from '../../../helpers/grids/infinity.paging.panel'
import { LoadingOverlay } from '../../../helpers/grids/loading.spinner'

interface ActionColumn {
  columnName: string
  callBack: (props: any) => JSX.Element
}

interface IInfinityPaging {
  show: boolean
  handleNextPage?: (props: any) => void
  handlePreviousPage?: (props: any) => void
  currentPage?: number
}

interface Props {
  columns: Column[]
  rows: object[]
  showSortingControls?: boolean
  pagination?: any
  columnExtensions?: Table.ColumnExtension[]
  defaultSorting?: Sorting[]
  allowedPageSizes?: number[]
  actionColumn?: ActionColumn
  rowComponent?: Row
  changePageSize?: (pageSize: number) => void
  setCurrentPage?: (currentPage: number) => void
  setSorting?: (sorting: Sorting[]) => void
  sorting?: Sorting[]
  sortingExtensions?: SortingState.ColumnExtension[]
  filters?: any
  setFilters?: any
  filterExtensions?: FilteringState.ColumnExtension[]
  infinityPaging?: boolean
  infinityPagingPanel?: IInfinityPaging
  customTestId?: any
  loading?: boolean
  showFilterSelector?: boolean
  disableFiltering?: boolean
  defaultHiddenColumnNames?: string[]
  customFiltering?: boolean
  grouping?: Grouping[]
  getChildGroups?: CustomGroupingProps['getChildGroups']
  currencyColumns?: string[]
  totalSummaryItems?: SummaryStateProps['totalItems']
  groupSummaryItems?: SummaryStateProps['groupItems']
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      width: '100%',
      padding: theme.spacing(0),
      position: 'relative',
      boxShadow: (props: any) =>
        props.leftScroll === 1
          ? ''
          : 'inset -5px 0px 5px 0px rgba(121, 121, 121, 0.1)',
    },
    filterInput: {
      [`& fieldset`]: {
        border: 'none',
      },
    },
  }),
)

const FilterCellBase = () => <TableCell />

const DefaultFilterInput = (props: any) => {
  const [inputText, setInputText] = React.useState(
    props.filter ? props.filter.value : '',
  )
  return (
    <TableCell
      style={{
        position: 'relative',
        minWidth: '200px',
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        value={inputText}
        onChange={e => setInputText(e.target.value)}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            props.onFilter({ value: inputText })
          }
        }}
        placeholder="Search..."
        style={{ width: '100%' }}
        className={props.className}
        key={props.column}
      />
      {inputText !== '' && (
        <CancelIcon
          fontSize="small"
          style={{
            position: 'absolute',
            top: '25%',
            right: '0',
            marginRight: '20px',
          }}
          onClick={() => {
            if (props.filter) {
              props.onFilter({ value: '' })
            } else {
              setInputText('')
            }
          }}
        />
      )}
    </TableCell>
  )
}

const FilterCell = (props: any) => {
  if (props.filteringEnabled) {
    if (!props.children[1].props.column) {
      return <DefaultFilterInput {...props} />
    }
    return <TableFilterRow.Cell {...props} />
  }
  return <FilterCellBase />
}

const CurrencyFormatter = ({ value }: any) =>
  value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })

const CurrencyTypeProvider: FC<any> = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
)

const GroupingTable: FC<Props> = ({
  children,
  rows,
  columns,
  actionColumn,
  // defaultSorting,
  pagination,
  allowedPageSizes,
  columnExtensions,
  showSortingControls,
  // replace components
  rowComponent,
  setCurrentPage,
  setSorting,
  changePageSize,
  sorting,
  sortingExtensions,
  infinityPaging,
  infinityPagingPanel,
  customTestId,
  loading,
  filters,
  setFilters,
  showFilterSelector,
  filterExtensions,
  disableFiltering,
  defaultHiddenColumnNames,
  customFiltering,
  grouping,
  getChildGroups,
  currencyColumns,
  totalSummaryItems,
  groupSummaryItems,
}) => {
  const [leftScroll, setLeftScroll] = useState<number>(0)
  const classes = useStyles({ leftScroll })
  const createFilterCell = (props: any) =>
    FilterCell({ ...props, className: classes.filterInput })

  const CellComponentBase = (props: any) => {
    return <Table.Cell {...props} />
  }

  const RowComponent = (props: any) => <Table.Row {...props} />

  const TableComponentBase = ({ ...restProps }) => {
    useEffect(() => {
      window.addEventListener('scroll', handleScroll, true)
    }, [])

    const handleScroll = (event: any) => {
      if (
        event?.path?.length !== 2 &&
        event.target.className?.includes('TableContainer-root')
      ) {
        const scrollbarWidth =
          event.target.scrollWidth - event.target.clientWidth
        setLeftScroll(event.target.scrollLeft / scrollbarWidth)
      }
    }

    return (
      <Table.Table
        {...restProps}
        data-testid="default-table"
        style={{ tableLayout: 'initial', overflow: 'hidden' }}
      />
    )
  }

  const ContainerComponent: FC<{}> = props => {
    return <>{props.children}</>
  }

  return (
    <Paper
      data-testid={customTestId ? customTestId : 'grouping-table-test-id'}
      elevation={0}
      className={classes.root}
    >
      <Grid rows={rows} columns={columns}>
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingExtensions}
        />
        {!disableFiltering && (
          <FilteringState
            filters={filters}
            onFiltersChange={setFilters}
            columnExtensions={filterExtensions}
          />
        )}
        {customFiltering && <FilteringState filters={filters} />}
        <IntegratedSorting />
        <IntegratedFiltering />
        {pagination && (
          <PagingState
            currentPage={pagination.page - 1}
            onCurrentPageChange={setCurrentPage}
            pageSize={pagination.per_page}
            onPageSizeChange={changePageSize}
          />
        )}
        {!pagination && <PagingState />}
        {pagination && setCurrentPage && (
          <CustomPaging totalCount={pagination.total} />
        )}
        {pagination && !setCurrentPage && <IntegratedPaging />}
        {!pagination && <IntegratedPaging />}
        {currencyColumns && <CurrencyTypeProvider for={currencyColumns} />}
        {grouping && <GroupingState grouping={grouping} />}
        {grouping && (totalSummaryItems || groupSummaryItems) && (
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
        )}
        {getChildGroups && <CustomGrouping getChildGroups={getChildGroups} />}
        {grouping && <IntegratedSummary />}
        <Table
          tableComponent={TableComponentBase}
          cellComponent={CellComponentBase}
          rowComponent={rowComponent ? rowComponent : RowComponent}
          columnExtensions={columnExtensions}
        />

        {// @ts-ignore
        grouping && <TableGroupRow containerComponent={ContainerComponent} />}

        <TableHeaderRow showSortingControls={showSortingControls} />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        {!infinityPaging && <PagingPanel pageSizes={allowedPageSizes} />}
        {infinityPagingPanel?.show && (
          <InfinityPagingPanel
            pageSizes={allowedPageSizes}
            pageSize={pagination.per_page}
            currentPage={infinityPagingPanel.currentPage}
            handlePageSizeChange={changePageSize}
            handleNextPage={infinityPagingPanel.handleNextPage}
            handlePreviousPage={infinityPagingPanel.handlePreviousPage}
          />
        )}
        <LoadingOverlay loading={loading} />
        {actionColumn && (
          <ActionColumns
            actionColumns={[
              {
                columnName: actionColumn.columnName,
                render: actionColumn.callBack,
              },
            ]}
          />
        )}

        {children}
        {disableFiltering || (
          <>
            {showFilterSelector ? (
              <TableFilterRow
                showFilterSelector={showFilterSelector}
                cellComponent={createFilterCell}
              />
            ) : (
              <TableFilterRow cellComponent={createFilterCell} />
            )}
          </>
        )}
      </Grid>
    </Paper>
  )
}

export { GroupingTable }
