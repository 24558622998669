import { IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { BasicTextFields } from '@novafuturltd/shared'
import React, { FC, forwardRef, SetStateAction, Dispatch } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Form } from '../../../components/CampaignMngt/templates/hooks/useDeletedTemplatesQuery'

interface Props {
  form: UseFormMethods<Form>
  placeholder?: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClear?: () => void
}

export const Search: FC<Props> = ({
  form,
  placeholder,
  open,
  setOpen,
  onClear,
}) => {
  const { formatMessage } = useIntl()

  const toggleOpen = () => setOpen((prev: boolean) => !prev)
  if (!open) {
    return (
      <Tooltip
        title={placeholder || formatMessage({ id: 'search.placeholder' })}
      >
        <IconButton
          data-testid="show-search-bar-deleted-templates-list"
          onClick={toggleOpen}
          size="large"
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
    )
  }
  return (
    <Controller
      as={forwardRef((props, _ref) => {
        return (
          <BasicTextFields
            {...props}
            placeholder={
              placeholder || formatMessage({ id: 'search.placeholder' })
            }
            autoFocus={true}
            InputProps={{
              endAdornment: (
                <Tooltip title={formatMessage({ id: 'search.clear' })}>
                  <IconButton onClick={onClear || toggleOpen} size="large">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ),

              startAdornment: <SearchIcon />,
            }}
          />
        )
      })}
      control={form.control}
      name="searchTerm"
    />
  )
}

export default Search
