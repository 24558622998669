// added unstable_createMuiStrictModeTheme just for testing need to move prod method or wait for fix from mui
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider
} from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'
import { useAppContext } from '../AppContext'

import { getDesignTokens } from './theme'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useNovaTheme = () => {
  const { mode: contextMode } = useAppContext()
  const mode = contextMode

  const theme = React.useMemo(() => {
    const designTokens = getDesignTokens(mode)
    return createTheme(designTokens)
  }, [mode])
  return {
    theme,
  }
}

export const NovaThemeProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { theme } = useNovaTheme()
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default NovaThemeProvider
