import { SvgIcon, SvgIconProps } from '@mui/material'

export const NGNFlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clip-path="url(#clip0_816_31)">
        <mask
          id="mask0_816_31"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="3"
          width="24"
          height="18"
        >
          <path
            d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_816_31)">
          <path
            d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 3H8V21H0V3ZM16 3H24V21H16V3Z"
            fill="#00884F"
          />
          {/* <path
            d="M2.28571 3.5H21.7143C22.6778 3.5 23.5 4.32739 23.5 5.4V18.6C23.5 19.6726 22.6778 20.5 21.7143 20.5H2.28571C1.3222 20.5 0.5 19.6726 0.5 18.6V5.4C0.5 4.32739 1.3222 3.5 2.28571 3.5Z"
            stroke="black"
            stroke-opacity="0.1"
          /> */}
        </g>
      </g>
      <defs>
        <clipPath id="clip0_816_31">
          <rect
            width="24"
            height="18"
            fill="white"
            transform="translate(0 3)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
