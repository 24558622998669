import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((_theme: Theme) =>createStyles({
  Layout:{
    display: 'flex',
    padding: '40px 20px',
    flexFlow: 'row wrap',

    '@media only screen and (max-width: 767px)': {
      padding: '50px 20px',
    },

    '@media (max-width: 580px)': {
      padding: '15px',
    },
  }
}))

const LayoutContentWrapper: React.FC= ({children}) => {
  const classes = useStyles({})
  return <div className={classes.Layout}>{children}</div>
}

export { LayoutContentWrapper };
