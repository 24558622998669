import { Theme } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      padding: theme.spacing(2.5, 3),
      backgroundColor: '#FAFBFC',
      alignItems: 'center',
    },
    icon: {
      fill: theme.palette.primary.main,
    },
    label: {
      color: theme.palette.primary.main,
      zIndex: 1,
      lineHeight: 0,
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
    },
    select: {
      width: theme.spacing(24),
      backgroundColor: '#fff',
    },
    check: {
      color: theme.palette.primary.main,
    },
    search: {
      width: '90%',
      marginLeft: '5%',
    },
    menu: {
      width: '10%',
    },
  }),
)
