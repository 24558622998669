import { NovaColours } from '@novafuturltd/core';
import { Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// @ts-ignore
import { withDirection as WithDirection } from '../withDirection'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ComponentTitle: {
      fontSize: '19px',
      fontWeight: 500,
      width: '100%',
      color:  NovaColours.SCR050[theme.palette.mode],
      marginRight: '17px',
      marginBottom: '30px',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      '&:before': {
        content: '""',
        width: '5px',
        height: '40px',
        backgroundColor: NovaColours.SCR050[theme.palette.mode],
        display: 'flex',
        margin: (props: any) =>
          props['data-rtl'] === 'rtl' ? '0 0 0 15px' : '0 15px 0 0',
      },
      '&:after': {
        content: '""',
        width: '100%',
        height: '1px',
        backgroundColor: NovaColours.SCR050[theme.palette.mode],
        display: 'flex',
        margin: (props: any) =>
          props['data-rtl'] === 'rtl' ? '0 15px 0 0' : '0 0 0 15px',
      },

      '@media only screen and (max-width: 767px)': {
        margin: '0 10px',
        marginBottom: '30px',
      },
    },
  }),
)

const WDComponentTitleWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const styles = useStyles({})
  return (
    <Typography
      component="h1"
      className={styles.ComponentTitle}
      // color="info.main"
    >
      {children}
    </Typography>
  )
}
const ComponentTitleWrapper = WithDirection(WDComponentTitleWrapper)
export { ComponentTitleWrapper }
