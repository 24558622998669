import { InputBase, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { NovaColours } from '@novafuturltd/core'

export const CustomInput = withStyles((theme: Theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: NovaColours.N090[theme.palette.mode],
    border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    fontSize: 14,
    padding: '5px 26px 5px 12px',
    marginLeft: '7px',
    color: NovaColours.N030[theme.palette.mode],
    width: '63px',
  },
}))(InputBase)

export const useStyles = makeStyles(theme => ({
  search: {
    color: NovaColours.N030[theme.palette.mode],
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  icon: {
    fill: (props: any) =>
      props.width === '10ch' ? '#797979' : theme.palette.primary.main,
  },
  select: {
    display: (props: any) => (props.width === '10ch' ? 'initial' : 'none'),
    // width: '100px',
    marginLeft: '40px',
  },
  dropdown: {
    marginTop: '40px',
  },
  inputRoot: {
    color: 'inherit',
    paddingRight: theme.spacing(1),
    borderRadius: '6px',
    border: (props: any) =>
      props.width === '10ch'
        ? `1px solid ${NovaColours.N070[theme.palette.mode]}`
        : 'none',
  },
  inputInput: (props: any) => ({
    '&:hover': {
      cursor: 'pointer',
    },
    height: '19px',
    padding: '18px 0',
    paddingLeft:
      props.width === '10ch'
        ? `calc(1em + ${theme.spacing(0)})`
        : `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: props.width,
  }),
}))
