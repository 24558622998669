import { useEffect, useState } from 'react'
import FullScreenIcon from '@mui/icons-material/Fullscreen'
import FullScreenExitIcon from '@mui/icons-material/FullscreenExit'
import { IconButton, Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'

interface Props {
  disabled?: boolean
  isFullWindow: boolean
  toggleFullWindow: () => void
}

export const FullWindowIconButton = ({
  disabled,
  isFullWindow,
  toggleFullWindow,
}: Props) => {
  const [show, setShow] = useState(false)
  const { formatMessage } = useIntl()
  useEffect(() => {
    setShow(false)
  }, [isFullWindow])
  return (
    <Tooltip
      title={formatMessage({
        id: isFullWindow
          ? 'general.fullwindow.button.exit'
          : 'general.fullwindow.button.enter',
      })}
      aria-label={formatMessage({
        id: isFullWindow
          ? 'general.fullwindow.button.exit'
          : 'general.fullwindow.button.enter',
      })}
      open={show}
      disableHoverListener
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <IconButton disabled={disabled} onClick={() => toggleFullWindow()} size="large">
        {isFullWindow ? <FullScreenExitIcon /> : <FullScreenIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default FullWindowIconButton
