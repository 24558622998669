import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info'
import { NovaColours } from '@novafuturltd/core'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBox: {
      backgroundColor: (props: any) =>
        props.isError
          ? NovaColours.RN010[theme.palette.mode]
          : props.isInfo
          ? NovaColours.N080[theme.palette.mode]
          : props.isSuccessInfo
          ? NovaColours.RP010[theme.palette.mode]
          : NovaColours.RI010[theme.palette.mode],
      width: (props: any) => (props.width ? props.width : '100%'),
      display: 'flex',
      alignItems: 'center',
      marginBottom: (props: any) =>
        props.marginBottom ? props.marginBottom : theme.spacing(3),
      fontSize: '12px !important',
      padding: (props: any) =>
        props?.padding?.(theme) ?? theme.spacing(0, 1, 0, 1),
      borderRadius: theme.spacing(0.75),
      '& svg': {
        fill: (props: any) =>
          props.isError
            ? NovaColours.RN050[theme.palette.mode]
            : props.isSuccessInfo
            ? NovaColours.RP080[theme.palette.mode]
            : NovaColours.RI050[theme.palette.mode],
        marginRight: theme.spacing(1.125),
        width: (props: any) => (props.iconWidth ? props.iconWidth : '10px'),
        height: (props: any) => (props.iconHeight ? props.iconHeight : '10px'),
      },
      '& span': {
        color: (props: any) =>
          props.isError
            ? NovaColours.RN080[theme.palette.mode]
            : props.isInfo
            ? NovaColours.N040[theme.palette.mode]
            : props.isSuccessInfo
            ? NovaColours.RP080[theme.palette.mode]
            : NovaColours.RI080[theme.palette.mode],
      },
    },
    infoContainer: {
      width: '100%',
      '& > *': {
        marginTop: (props: any) =>
          props.marginTop ? props.marginTop : theme.spacing(2),
      },
    },
  }),
)

interface InfoContainerProps {
  width?: string
  height?: string
  bgColor?: any
  iconHeight?: string
  iconWidth?: string
  fontColor?: any
  fontSize?: string
  marginTop?: string
  marginBottom?: string
  padding?: (theme: Theme) => string
  intlId?: string
  text?: any
  iconColor?: string
  isError?: boolean
  isInfo?: boolean
  isDangerous?: boolean
  isSuccessInfo?: boolean
  startAdornment?: ReactNode | undefined
}

export const InfoContainer: React.FC<InfoContainerProps> = ({
  width,
  intlId,
  isError,
  marginTop,
  height,
  bgColor,
  iconHeight,
  iconWidth,
  fontColor,
  fontSize,
  marginBottom,
  padding,
  iconColor,
  text,
  isInfo,
  isDangerous,
  isSuccessInfo,
  startAdornment,
  ...props
}) => {
  const intl = useIntl()
  const classes = useStyles({
    width,
    marginTop,
    height,
    isError,
    bgColor,
    iconHeight,
    iconWidth,
    fontColor,
    fontSize,
    padding,
    marginBottom,
    iconColor,
    isInfo,
    isDangerous,
    isSuccessInfo,
    startAdornment,
  })
  return (
    <div className={classes.infoContainer} {...props}>
      <Box className={classes.infoBox}>
        {!isInfo && !startAdornment ? <InfoIcon fontSize="small" /> : null}
        <div style={{ display: 'flex' }}>
          {startAdornment}
          <Typography variant="caption" display="block">
            {intlId ? (
              intl.formatMessage({
                id: intlId,
              })
            ) : isDangerous ? (
              <span dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              text
            )}
          </Typography>
        </div>
      </Box>
    </div>
  )
}
