import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip'
import { NovaColours } from '@novafuturltd/core'
import { CSSProperties, FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: (props: any) => (props.width ? props.width : 'auto'),
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      paddingBottom: '1px',
      paddingTop: '1px',
      paddingLeft: theme.spacing(0.75),
      paddingRight: (props: any) =>
        props.icon === undefined ? theme.spacing(0.75) : theme.spacing(0),
      color: NovaColours.N030[theme.palette.mode],
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: 1.125,
      height: '20px',
    },
  }),
)

interface Props {
  label?: string | undefined
  size?: 'small' | 'medium' | undefined
  variant?: 'outlined' | 'filled' | undefined
  icon?: any
  width?: number
  dataTest?: string
  sx?: CSSProperties
}

const NovaChip: FC<Props> = ({
  label,
  size = 'small',
  variant = 'outlined',
  icon,
  width,
  dataTest,
  sx,
}) => {
  const classes = useStyles({ width, icon })

  return (
    <Chip
      label={label}
      size={size}
      variant={variant}
      icon={icon}
      classes={{ root: classes.root }}
      data-test={dataTest}
      sx={{...sx}}
    />
  )
}

export default NovaChip
