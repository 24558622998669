import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      backgroundColor: NovaColours.RI010[theme.palette.mode],
      color: NovaColours.AP060[theme.palette.mode],
      fontWeight: 600,
      padding: theme.spacing(2, 0),
      paddingLeft: '8px',
      paddingRight: '8px',
      border: `1px solid ${NovaColours.AP020[theme.palette.mode]}`,
      '&:hover': {
        backgroundColor: NovaColours.RI010[theme.palette.mode],
      },
      '&:focus': {
        backgroundColor: NovaColours.RI010[theme.palette.mode],
      },
      marginLeft: theme.spacing(2),
    },
    sortIcon: {
      marginRight: theme.spacing(2.25),
    },
    header: {
      color: NovaColours.N030[theme.palette.mode],
      whiteSpace: 'nowrap',
    },
    sortText: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0.5),
      color: NovaColours.N030[theme.palette.mode],
      whiteSpace: 'nowrap',
    },
    sortingHelp: {
      display: 'flex',
      alignItems: 'center',
    },
    helpIcon: {
      width: theme.spacing(2.5),
    },
    cancelIcon: {
      width: theme.spacing(2),
    },
    icon: {
      marginRight: theme.spacing(2.25),
    },
    headingContainer: {
      backgroundColor: NovaColours.N090[theme.palette.mode],
      display: 'flex',
      borderRight: `2px solid ${NovaColours.N070[theme.palette.mode]}`,
      padding: theme.spacing(1.75, 3),
      paddingRight: theme.spacing(1),
    },
    headingContainerTotals: {
      backgroundColor: NovaColours.N090[theme.palette.mode],
      display: 'flex',
      borderRight: `2px solid ${NovaColours.N070[theme.palette.mode]}`,
      padding: theme.spacing(1.75, 3),
      paddingRight: theme.spacing(5.4),
    },
    headingContainerFilter: {
      backgroundColor: NovaColours.N090[theme.palette.mode],
      display: 'flex',
      borderRight: `2px solid ${NovaColours.N070[theme.palette.mode]}`,
      padding: theme.spacing(1.75, 3),
    },
    chipContainer: {
      padding: theme.spacing(1.75, 0),
      overflowX: 'auto',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: NovaColours.N090[theme.palette.mode],
    },
    removeIconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    totalsBar: {
      border: '1px solid',
      borderColor: NovaColours.N070[theme.palette.mode],
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      background: NovaColours.N090[theme.palette.mode],
      height: '64px',
    },
    innerBar: {
      gap: theme.spacing(3),
      border: '1px solid',
      borderRadius: '6px',
      borderColor: NovaColours.N070[theme.palette.mode],
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      background: NovaColours.S010[theme.palette.mode],
      height: '52px',
      flexWrap: 'nowrap',
      padding: theme.spacing(2),
    },
    innerBarTitleText: {
      color: NovaColours.N040[theme.palette.mode],
      display: 'flex',
      cursor: 'default',
    },
    innerBarText: {
      color: NovaColours.N010[theme.palette.mode],
      display: 'flex',
      cursor: 'default',
      fontVariantNumeric: 'tabular-nums',
    },

    innerBarContainer: {
      marginLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflowX: 'hidden',
      gap: theme.spacing(2),
    },
    barsContainer: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(1, 0),
      overflowX: 'auto',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: NovaColours.N090[theme.palette.mode],
      gap: theme.spacing(2),
    },
  }),
)

export default useStyles
