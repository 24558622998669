import { useEffect, useState } from 'react'

interface TDynamicColumn {
  title: string
  name: string
  key: string
  dataIndex: string
  queryKey: string
  ellipsis: boolean
  order: number
}

export const useDynamicColumnsState = (
  availableColumnsToBeSelected: TDynamicColumn[],
  defaultSelectedColumnNames: string[],
) => {
  const [selectedColumnsState, setSelectedColumnsState] = useState<
    TDynamicColumn[]
  >([])

  useEffect(() => {
    const columns = availableColumnsToBeSelected.filter(c =>
      defaultSelectedColumnNames.includes(c.queryKey),
    )
    setSelectedColumnsState(columns)
  }, [availableColumnsToBeSelected])

  return { selectedColumnsState }
}
