import { Box, Grid, GridSize, IconButton, Theme, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { NovaColours } from '@novafuturltd/core'
import { Heading } from '..'
import React from 'react'

export const useSmallBorderedContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderRadius: '6px',
      padding: (props: any) =>
        props.padding ? props.padding : theme.spacing(2, 3),
      paddingBottom: (props: any) =>
        props.paddingBottom ? props.paddingBottom : '',
      marginBottom: '16px',
      margin: (props: any) => (props.margin ? props.margin : ''),
      marginRight: (props: any) => (props.marginRight ? props.marginRight : ''),
      width: (props: any) => (props.width ? props.width : ''),
      overflow: 'hidden',
      opacity: (props: any) => props.loading && '0.3',
      background: NovaColours.N100[theme.palette.mode],
      flexGrow: (props: any) => (props.flexGrow ? props.flexGrow : 0),
    },
    footerLink: {
      borderTop: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },

    containerWithFooter: {
      margin: (props: any) =>
        props.margin ? props.margin : theme.spacing(0, 3),
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderRadius: '6px',
      width: (props: any) => (props.width ? props.width : ''),
      background: NovaColours.N100[theme.palette.mode],
    },
    innerContainer: {
      padding: theme.spacing(3, 0),
      height: '85%',
    },
    footerContainer: {
      borderTop: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      padding: theme.spacing(0, 1.5, 0, 0),
      textAlign: 'right',
      height: '15%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& svg': {
        fontSize: 12,
      },
    },
    containerWithFooterHeader: {
      padding: theme.spacing(0, 3),
      fontWeight: 500,
    },
    detailsContainerColor: {
      background: NovaColours.N090[theme.palette.mode],
    },
    detailsContainer: {
      margin: (props: any) =>
        props.margin ? props.margin : theme.spacing(0, 3),
      padding: theme.spacing(3),
      width: (props: any) => props.width && props.width,
    },
    detailsSmallCardContainer: {
      borderLeft: `4px solid ${NovaColours.N070[theme.palette.mode]}`,
      padding: theme.spacing(1, 2, 1, 3),
    },
    smallCardReport: {
      borderLeft: `4px solid ${NovaColours.N070[theme.palette.mode]}`,
      padding: theme.spacing(1, 2, 1, 3),
      [theme.breakpoints.down('lg')]: {
        paddingRight: '0',
        marginBottom: theme.spacing(1),
        borderLeft: `4px solid ${NovaColours.N070[theme.palette.mode]}`,
        padding: theme.spacing(1, 2, 1, 3),
      },
    },
    firstCardReport: {
      padding: theme.spacing(1, 2, 0, 0),
      [theme.breakpoints.down('lg')]: {
        paddingRight: '0',
        marginBottom: theme.spacing(1),
        borderLeft: `4px solid ${NovaColours.N070[theme.palette.mode]}`,
        padding: theme.spacing(1, 2, 1, 3),
      },
    },
    detailsMainSection: {
      marginBottom: '0',
      paddingRight: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        paddingRight: '0',
        marginBottom: theme.spacing(3),
      },
    },
    detailsCardTitle: {
      color: NovaColours.N030[theme.palette.mode],
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 500,
      lineHeight: '26px',
    },
    detailsCardValue: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: '28px',
      fontWeight: 500,
      fontVariantNumeric: 'tabular-nums',
    },
  }),
)

interface Props {
  width?: string
  margin?: string
  id?: string
  loading?: boolean
  children: any
  customClass?: string
  flexGrow?: number
  marginRight?: number
  paddingBottom?: number | string
  padding?: string | number
}

export const Container: React.FC<Props> = ({
  width,
  margin,
  id,
  loading,
  children,
  customClass,
  flexGrow,
  marginRight,
  paddingBottom,
  padding,
  ...props
}) => {
  const classes = useSmallBorderedContainerStyles({
    width,
    margin,
    loading,
    flexGrow,
    marginRight,
    paddingBottom,
    padding,
  })

  return (
    <div id={id} className={`${classes.container} ${customClass}`} {...props}>
      {children}
    </div>
  )
}

interface WithFooterProps {
  width?: string
  margin?: string
  children: any
  handleClick: () => void
  tooltipNarative?: string
  heading?: any
  id?: string
}

export const ContainerWithFooter: React.FC<WithFooterProps> = ({
  width,
  margin,
  children,
  handleClick,
  tooltipNarative,
  heading,
  id,
  ...props
}) => {
  const classes = useSmallBorderedContainerStyles({ width, margin })
  return (
    <div className={classes.containerWithFooter} id={id} {...props}>
      <div className={classes.innerContainer}>
        <div className={classes.containerWithFooterHeader}>
          <Heading variant="h4" fontWeight="500">
            {heading}
          </Heading>
        </div>
        {children}
      </div>
      <div className={classes.footerContainer}>
        {tooltipNarative ? (
          <Tooltip title={tooltipNarative}>
            <IconButton onClick={handleClick} size="large" style={{ height: '24px', width: '24px'}} >
              <ArrowForwardIosIcon style={{ fill: '#2A6EFB' }} />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton onClick={handleClick} size="large" style={{ height: '24px', width: '24px' }}>
            <ArrowForwardIosIcon style={{ fill: '#2A6EFB' }} />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export interface ContainerWithSectionsPropsCard {
  title: string
  format?: string
  data: any
  size?: number
}

interface ContainerWithSectionsProps {
  mainSection: React.ReactNode
  cards: ContainerWithSectionsPropsCard[]
  margin?: string
  width?: string
  headerSection?: React.ReactNode
  uniqueCard?: React.ReactNode
}

export const ContainerWithSections: React.FC<ContainerWithSectionsProps> = ({
  mainSection,
  cards,
  margin,
  width,
  headerSection,
  uniqueCard,
  ...props
}) => {
  const classes = useSmallBorderedContainerStyles({ margin, width })

  const defaultCardSize: GridSize = Math.floor(
    12 / (uniqueCard ? cards.length + 1 : cards.length),
  ) as GridSize

  return (
    <Container customClass={classes.detailsContainer} {...props}>
      {headerSection}
      <Grid container={true} style={{ alignItems: 'center' }}>
        <Grid
          lg={6}
          md={12}
          sm={12}
          item={true}
          className={classes.detailsMainSection}
          data-testid="main-detail"
        >
          {mainSection}
        </Grid>
        <Grid
          lg={6}
          md={12}
          sm={12}
          container={true}
          item={true}
          style={{ alignItems: 'center' }}
        >
          {uniqueCard && (
            <Grid
              item={true}
              lg={defaultCardSize}
              md={defaultCardSize}
              sm={defaultCardSize}
            >
              {uniqueCard}
            </Grid>
          )}
          {cards.map((card: any, index: number) => (
            <Grid
              item={true}
              lg={card?.size ? card?.size : defaultCardSize}
              md={card?.size ? card?.size : defaultCardSize}
              sm={card?.size ? card?.size : defaultCardSize}
              key={index}
              data-test={`detail-card-${index}`}
            >
              <Box
                className={classes.detailsSmallCardContainer}
                data-testid={`detail-container-${card.title}`}
              >
                <div className={classes.detailsCardTitle}>{card.title}</div>
                <div className={classes.detailsCardValue}>{card.data}</div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}
