import { SidebarToggle } from '../../sidebar-toggle'
import { useContext, useRef, PropsWithChildren } from 'react'
import { Box, Popper, useTheme } from '@mui/material'
import { AppContext, NovaColours } from '@novafuturltd/core'

export const FullWindowSidebar = (props: PropsWithChildren<{}>) => {
  const ref = useRef<HTMLButtonElement>(null)

  const { mode, toggleOpenDrawer, openDrawer } = useContext(AppContext)

  const theme = useTheme()

  return (
    <>
      <div style={{ marginRight: theme.spacing(1.5) }}>
        <SidebarToggle
          ref={ref}
          onToggleHandle={() => toggleOpenDrawer(!openDrawer)}
        />
        <Box />
        <Popper
          style={{
            zIndex: 9990,
            background: NovaColours.N100[mode],
            marginTop: theme.spacing(7.5),
            height: `calc(100% - calc(${theme.spacing(1)} + 64px))`,
            boxShadow: `0px 0px 10px ${
              mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)'
            }`,
            borderRadius: '12px',
            overflow: 'auto',
          }}
          open={openDrawer}
          anchorEl={ref.current}
          transition
          placement="bottom"
        >
          {props.children}
        </Popper>
      </div>
      <div
        style={{
          height: '90%',
          borderLeft: `1px solid ${NovaColours.N060[theme.palette.mode]}`,
          marginRight: theme.spacing(1),
        }}
      ></div>
    </>
  )
}

export default FullWindowSidebar
