import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useIconPanelStyles = makeStyles(() =>
  createStyles({
    flexPanel: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
)
