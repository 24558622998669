import { FC, memo, ReactNode, RefObject, useMemo } from 'react'

import {
  Column,
  CustomPaging,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import CancelIcon from '@mui/icons-material/Cancel'
import { Paper, TableCell, TextField, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { ActionColumns } from '../../../helpers/grids/action.column'
import { NumberFormatterProvider } from '../../../helpers/grids/formatters'
import { InfinityPagingPanel } from '../../../helpers/grids/infinity.paging.panel'
import { LoadingOverlay } from '../../../helpers/grids/loading.spinner'
import { CustomPagination } from './Components/CustomPagination'
import { useColumnReorder } from './hooks/useColumnReorder'
import useColumnResizing from './hooks/useColumnResizing'
export interface TColumn extends Column {
  format?: string
}

interface ActionColumn {
  columnName: string
  callBack: (props: any) => JSX.Element
}

interface IInfinityPaging {
  show: boolean
  handleNextPage?: (props: any) => void
  handlePreviousPage?: (props: any) => void
  currentPage?: number
  disablePreviousButton?: boolean
  disableNextButton?: boolean
  lastPage?: boolean
  totalRows?: number
  totalUsers?: number
}

interface Props {
  innerRef?: RefObject<HTMLTableElement>
  columns: TColumn[]

  disablePageSizes?: boolean
  disableIntegratedSorting?: boolean
  rows: object[]
  showSortingControls?: boolean
  pagination?: {
    page?: number
    per_page?: number
    total?: number
  }
  columnExtensions?: Table.ColumnExtension[]
  defaultSorting?: Sorting[]
  columnResizingKey?: string
  columnOrderKey?: string
  allowedPageSizes?: number[]
  actionColumn?: ActionColumn
  rowComponent?: React.ComponentType<Table.DataRowProps>
  changePageSize?: (pageSize: number) => void
  setCurrentPage?: (currentPage: number) => void
  setSorting?: (sorting: Sorting[]) => void
  sorting?: Sorting[]
  sortingExtensions?: SortingState.ColumnExtension[]
  filters?: any
  setFilters?: any
  filterExtensions?: FilteringState.ColumnExtension[]
  infinityPaging?: boolean
  infinityPagingPanel?: IInfinityPaging
  customTestId?: any
  loading?: boolean
  showFilterSelector?: boolean
  disableFiltering?: boolean
  defaultHiddenColumnNames?: string[]
  customFiltering?: boolean
  showColumnHeaders?: boolean
  infinityPanelLeftBox?: ReactNode | undefined
  customPagination?: boolean
  defaultInitialPage?: number
}

export type DataRowProps = Table.DataRowProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      width: '100%',
      padding: theme.spacing(0),
      position: 'relative',
    },
    filterInput: {
      [`& fieldset`]: {
        border: 'none',
      },
    },
    bottomBorderlessRow: {
      '& .MuiTableRow-root': {
        '&:not(:hover)': {
          background: 'transparent',
        },
        '&:last-child td': {
          borderBottom: 0,
        },
      },
      borderRadius: '6px',
    },
  }),
)

const FilterCellBase = memo(() => <TableCell />)

const CellComponentBase = memo((props: any) => {
  return <Table.Cell {...props} />
})

const DefaultFilterInput = memo((props: any) => {
  const [inputText, setInputText] = React.useState(
    props.filter ? props.filter.value : '',
  )
  return (
    <TableCell
      style={{
        position: 'relative',
        minWidth: '200px',
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        value={inputText}
        onChange={e => setInputText(e.target.value)}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            props.onFilter({ value: inputText })
          }
        }}
        placeholder="Search..."
        style={{ width: '100%' }}
        className={props.className}
        key={props.column}
      />
      {inputText !== '' && (
        <CancelIcon
          fontSize="small"
          style={{
            position: 'absolute',
            top: '25%',
            right: '0',
            marginRight: '20px',
          }}
          onClick={() => {
            if (props.filter) {
              props.onFilter({ value: '' })
            } else {
              setInputText('')
            }
          }}
        />
      )}
    </TableCell>
  )
})

const FilterCell = memo((props: any) => {
  if (props.filteringEnabled) {
    if (!props.children[1].props.column) {
      return <DefaultFilterInput {...props} />
    }
    return <TableFilterRow.Cell {...props} />
  }
  return <FilterCellBase />
})

const RowComponent = memo((props: any) => <Table.Row {...props} />)

/**
 *
 * @param columnResizingKey: if supplied, the column resizing will be enabled. This key needs to be unique for each datatable.
 * @param columnOrderKey: if supplied, the column reorder(persist) will be enabled. This key needs to be unique for each datatable.
 * @returns
 */
const DefaultTable: FC<Props> = ({
  innerRef,
  children,
  disablePageSizes,
  disableIntegratedSorting,
  rows,
  columns,
  actionColumn,
  columnResizingKey,
  columnOrderKey,
  // defaultSorting,
  pagination,
  allowedPageSizes,
  columnExtensions,
  showSortingControls,
  // replace components
  rowComponent,
  setCurrentPage,
  setSorting,
  changePageSize,
  sorting,
  sortingExtensions,
  infinityPaging,
  infinityPagingPanel,
  customTestId,
  loading,
  filters,
  setFilters,
  showFilterSelector,
  filterExtensions,
  disableFiltering,
  defaultHiddenColumnNames,
  customFiltering,
  showColumnHeaders,
  infinityPanelLeftBox,
  customPagination,
  defaultInitialPage = 1,
}) => {
  const classes = useStyles({})
  const createFilterCell = (props: any) => (
    <FilterCell {...props} className={classes.filterInput} />
  )
  const { columnOrder, setColumnOrder } = useColumnReorder(
    columns,
    columnOrderKey,
  )
  const columnResizingEnabled = !!columnResizingKey
  const columnOrderEnabled = !!columnOrderKey

  const resizing = useColumnResizing({
    columns,
    localStorageKey: columnResizingKey,
  })

  const defaultColumnExtensions: Table.ColumnExtension[] = useMemo(
    () =>
      columns
        .filter((col: TColumn) => col?.format === 'number')
        .map((col: TColumn) => {
          return {
            columnName: col.name,
            align: 'right',
          }
        }),
    [columns],
  )

  const allColumnExtensions = useMemo(
    () =>
      columnExtensions && columnExtensions?.length > 0
        ? [...defaultColumnExtensions, ...columnExtensions]
        : defaultColumnExtensions,
    [columnExtensions, defaultColumnExtensions],
  )

  const columnsWithNumberFormatter = useMemo(
    () =>
      columns
        .filter((col: TColumn) => col?.format === 'number')
        .map((col: TColumn) => col.name),
    [columns],
  )

  const TableComponentBase = useMemo(() => {
    return ({ ...restProps }: any) => {
      const defaultStyle = {
        overflow: 'hidden',
      }

      // only include property in object if column resizing is not enabled
      const style = columnResizingEnabled
        ? defaultStyle
        : { ...defaultStyle, tableLayout: `initial` as 'initial' }

      return (
        <Table.Table
          {...restProps}
          data-testid="default-table"
          style={style}
          className={
            infinityPaging && !infinityPagingPanel
              ? classes.bottomBorderlessRow
              : ''
          }
        />
      )
    }
  }, [columnResizingEnabled])

  return (
    <Paper
      data-testid={customTestId ? customTestId : 'default-table-test-id'}
      elevation={0}
      className={classes.root}
      ref={innerRef}
    >
      <Grid rows={rows} columns={columns}>
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingExtensions}
        />
        {!disableFiltering && (
          <FilteringState
            filters={filters}
            onFiltersChange={setFilters}
            columnExtensions={filterExtensions}
          />
        )}
        {customFiltering && <FilteringState filters={filters} />}
        {!disableIntegratedSorting && <IntegratedSorting />}
        <IntegratedFiltering />
        {pagination && (
          <PagingState
            currentPage={pagination?.page ? pagination.page - 1 : 0}
            onCurrentPageChange={setCurrentPage}
            pageSize={pagination.per_page}
            onPageSizeChange={changePageSize}
          />
        )}
        {!pagination && <PagingState />}
        {pagination && setCurrentPage && (
          <CustomPaging totalCount={pagination.total} />
        )}
        {pagination && !setCurrentPage && <IntegratedPaging />}
        {!pagination && <IntegratedPaging />}
        {columnOrderEnabled && <DragDropProvider />}
        <Table
          tableComponent={TableComponentBase}
          cellComponent={CellComponentBase}
          rowComponent={rowComponent ? rowComponent : RowComponent}
          columnExtensions={allColumnExtensions}
        />

        {columnResizingEnabled && <TableColumnResizing {...resizing} />}
        {showColumnHeaders === false || (
          <TableHeaderRow showSortingControls={showSortingControls} />
        )}
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />

        {!infinityPaging && !customPagination && (
          <PagingPanel pageSizes={allowedPageSizes} />
        )}
        {infinityPagingPanel?.show && pagination && (
          <InfinityPagingPanel
            disablePageSizes={disablePageSizes}
            totalRows={infinityPagingPanel.totalRows}
            totalUsers={infinityPagingPanel.totalUsers}
            pageSizes={allowedPageSizes}
            pageSize={pagination.per_page}
            currentPage={infinityPagingPanel.currentPage}
            handlePageSizeChange={(e: {
              target: { value: number | undefined }
            }) => {
              if (changePageSize) {
                changePageSize(e.target.value || pagination?.per_page || 25)
              }
            }}
            handleNextPage={infinityPagingPanel.handleNextPage}
            handlePreviousPage={infinityPagingPanel.handlePreviousPage}
            disablePreviousButton={infinityPagingPanel?.disablePreviousButton}
            infinityPanelLeftBox={infinityPanelLeftBox}
            disableNextButton={infinityPagingPanel?.disableNextButton}
            lastPage={infinityPagingPanel?.lastPage}
          />
        )}
        {columnOrderEnabled && (
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
          />
        )}
        <LoadingOverlay loading={loading} />
        {actionColumn && (
          <ActionColumns
            actionColumns={[
              {
                columnName: actionColumn.columnName,
                render: actionColumn.callBack,
              },
            ]}
          />
        )}
        {children}
        <NumberFormatterProvider for={columnsWithNumberFormatter} />
        {!disableFiltering && showFilterSelector && (
          <TableFilterRow
            showFilterSelector={showFilterSelector}
            cellComponent={createFilterCell}
          />
        )}
        {!disableFiltering && !showFilterSelector && (
          <TableFilterRow cellComponent={createFilterCell} />
        )}
      </Grid>
      {!infinityPaging && customPagination && (
        <CustomPagination
          allowedPageSizes={allowedPageSizes}
          pagination={pagination}
          changePageSize={changePageSize}
          setCurrentPage={setCurrentPage}
          defaultInitialPage={defaultInitialPage}
        />
      )}
    </Paper>
  )
}

DefaultTable.defaultProps = {
  allowedPageSizes: [5, 10, 25, 50, 100],
  showSortingControls: true,
}

export { DefaultTable }
