import React, { useEffect } from 'react'
import { TUser } from '@novafuturltd/core'

interface Props {
  config: {
    sdkSrc: string
    applicationCode: string
    popupClickTrackerUrl: string
    sdkApiUri: string
    inappMessagesUrl: string
    pushSubscribeUri: string
  }
  profile: TUser
}

const InitSdk: React.FC<Props> = ({ config, profile }) => {
  useEffect(() => {
    if (profile) {
      // @ts-ignore
      window.NovaSDK = window.NovaSDK || []
      // @ts-ignore
      window.NovaSDK.push([
        'init',
        {
          logLevel: 'debug',
          applicationCode: config.applicationCode,
          defaultNotificationTitle: 'Nova Push',
          defaultNotificationImage: '/images/nova-logo.jpeg',
          serviceWorkerUrl: '/service-worker.js',
          inappMessagesUrl: config.inappMessagesUrl,
          apiUrl: config.sdkApiUri,
          subscribePushUrl: config.pushSubscribeUri,
          popupClickTrackerUrl: config.popupClickTrackerUrl,
          autoSubscribe: true,
          userId: profile.idUserAccount,
          country: 'UK',
          tags: { Name: profile.firstName },
        },
      ])
    }
  }, [profile, config])
  return <></>
}

export default InitSdk
