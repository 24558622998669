import { useState } from 'react'
import { useBrowserQuery } from '../hooks/use.browserQuery'

export type UpdateFilterState = (
  filtersState: {
    [key: string]: any
  },
  page?: any,
) => void

export interface QueryFilterState {
  filterState: { [key: string]: any }
  removeFilterByKey: (key: string) => void
  updateFilterState: UpdateFilterState
}

export interface LocalFilterState {
  filterState: { [key: string]: any }
  filterView: any[]
  updateFilterState: (filtersState: { [key: string]: any }) => void
}

export const useFilterState = (
  defaultFiltering?: { [key: string]: any },
  queryKey?: string,
): QueryFilterState => {
  const [query, setQuery, path] = useBrowserQuery()
  const filterState: { [key: string]: any } = queryKey ? query[queryKey] : query.filter

  // tslint:disable-next-line: no-shadowed-variable
  const updateFilterState = (
    filtersState: { [key: string]: any },
    page?: any,
  ) => {
    if (page || page === 0) {
      return setQuery(
        { ...query, page, [queryKey || 'filter']: filtersState },
        path,
      )
    }

    return setQuery({ ...query, [queryKey || 'filter']: filtersState }, path)
  }

  const removeFilterByKey = (key: string) => {
    const { [key]: omit, ...restOfFilterState } = filterState
    setQuery(
      {
        ...query,
        [queryKey || 'filter']: { ...restOfFilterState },
      },
      path,
    )
  }

  const defaultReturnValues = {
    updateFilterState,
    removeFilterByKey,
  }

  if (defaultFiltering && Object.keys(defaultFiltering).length > 0) {
    return {
      filterState:
        Object.keys(filterState).length > 0 ? filterState : defaultFiltering,
      ...defaultReturnValues,
    }
  }
  return { filterState, ...defaultReturnValues }
}

export const useLocalFilterState = (): LocalFilterState => {
  const [filterState, setFilterState] = useState<{ [key: string]: any }>({})
  const [filterView, setFiterView] = useState<any[]>([])

  const constructFilterStateForView = (object: object) => {
    const filteredArray: { columnName: string; value: any }[] = []
    Object.entries(object).forEach(([key, value]) => {
      filteredArray.push({ columnName: key, value })
    })
    return filteredArray
  }

  // tslint:disable-next-line: no-shadowed-variable
  const updateFilterState = (filterState: { [key: string]: any }) => {
    setFilterState(filterState)
    setFiterView(constructFilterStateForView(filterState))
  }

  return { filterState, filterView, updateFilterState }
}
