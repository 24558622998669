import { Sorting } from '@devexpress/dx-react-grid'
import { useState } from 'react'

interface ITableSortingState {
  sortingState: Sorting[]
  updateSorting: (column: Sorting) => void
  removeSortingColumn: (columnName: string) => void
}

export function useTableSortingState(
  defaultSorting: Sorting[],
): ITableSortingState {
  const [sortingState, setSortingState] = useState<Sorting[]>(defaultSorting)

  const updateSorting = (column: Sorting) => {
    const updatedSorting = [...sortingState]
    const existingColumnIndex = sortingState.findIndex(
      (v: Sorting) => v.columnName === column.columnName,
    )

    if (existingColumnIndex !== -1) {
      updatedSorting[existingColumnIndex] = { ...column }
    } else {
      updatedSorting.push(column)
    }

    return setSortingState(updatedSorting)
  }

  const removeSortingColumn = (columnName: string) =>
    setSortingState(
      sortingState.filter((sort: Sorting) => sort.columnName !== columnName),
    )

  return {
    sortingState,
    updateSorting,
    removeSortingColumn,
  }
}
