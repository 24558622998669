import { AxiosPromise } from 'axios'
import client from 'axios'
import qs from 'qs'
import { THeaders, TQueryParams } from '@novafuturltd/shared'
import { services, toggleFeatureToken } from '../../../config'
import { IFeature, TFeaturesResponse } from '../types'

const methods = {
  get: (url: string, params: TQueryParams = {}, headers: THeaders = {}): any =>
    client({
      method: 'GET',
      url,
      baseURL: services.toggleFeature,
      params,
      headers: {
        Authorization: toggleFeatureToken,
        ...headers,
      },
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
}

export const getAll = (
  params?: TQueryParams,
): AxiosPromise<TFeaturesResponse<IFeature[]>> => {
  return methods.get('v2/client/features', params)
}
