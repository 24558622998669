import { useState } from 'react'
import { useBrowserQuery } from '../hooks/use.browserQuery'

interface TPagination {
  page?: number
  page_size?: number
}

export const usePaginationState = () => {
  const [query, setQuery, path] = useBrowserQuery()
  const [paginationState] = useState<TPagination>({
    page: query.page,
    page_size: query.page_size,
  })

  const nextPage = (currentPage: number) => {
    setQuery({ ...query, page: currentPage + 1 }, path)
  }

  const previousPage = (currentPage: number) => {
    if (currentPage - 1 >= 0) {
      setQuery({ ...query, page: currentPage - 1 }, path)
    }
  }

  const changePageSize = (size: number) => {
    setQuery({ ...query, page_size: size })
  }

  return { paginationState, nextPage, previousPage, changePageSize }
}
