export const numberToSixDP = (value: any) => value?.toFixed(6)

export const numberWithCommas = (value: any, decimalPlaces?: number) => {
  const options = {}
  if (decimalPlaces) {
    Object.assign(options, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  }
  return value?.toLocaleString('en-GB', options)
}

export const NumberWithCommasBox = ({
  value,
  decimalPlaces,
}: {
  value: any
  decimalPlaces?: number
}) => {
  return (
    <div
      style={{
        fontFeatureSettings: `'tnum' on, 'lnum' on`,
        letterSpacing: '-0.01em',
      }}
    >
      {numberWithCommas(value, decimalPlaces)}
    </div>
  )
}
