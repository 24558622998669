import InputBase from '@mui/material/InputBase'
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search'
import React, { FC, useState } from 'react'

const useStyles = makeStyles(theme => ({
  search: {
    color: theme.palette.primary.main,
    position: 'relative',
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(1, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fill: theme.palette.primary.main,
    zIndex: 1,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: (props: any) => ({
    '&:hover': {
      cursor: 'pointer',
    },
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    // width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: props.width,
    //   '&:focus': {
    //     width: '20ch',
    //     border: `1px solid ${theme.palette.primary.main}`,
    //     borderRadius: '4px'
    //   },
    // },
    width: props.width,
    '&:focus': {
      width: '20ch',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
  }),
}))

interface Props {
  onChange: Function
  filter?: string
}

export const ExpandableSearch: FC<Props> = ({ onChange, filter }) => {
  const [width, setWidth] = useState('0ch')
  const classes = useStyles({ width })

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon className={classes.icon} />
      </div>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={({ target: { value } }) => {
          // The next two if statment exist to reduce number of re-renders
          if (value !== '' && width !== '20ch') {
            setWidth('20ch')
          }
          if (value === '' && width !== '0ch') {
            setWidth('0ch')
          }
          if (filter) {
            onChange(filter, value)
          } else {
            onChange(value)
          }
        }}
      />
    </div>
  )
}

export default ExpandableSearch
