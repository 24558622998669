// MUI skeleton table reusable component

import React from 'react'
import { Paper, Skeleton, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

interface Props {
  rows: number
  columns: string[]
}

const MuiSkeletonTable: React.FC<Props> = ({ rows, columns }) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        background: 'transparent',
        width: '100%',
        padding: theme.spacing(0),
        position: 'relative',
      },
    }),
  )

  const classes = useStyles({})

  return (
    <Paper
      elevation={0}
      className={classes.root}
      data-testid="mui-skeleton-table"
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell key={index}>
                <p style={{ margin: '8px' }}>{col}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(new Array(rows)).map((_, index) => (
            <TableRow key={index}>
              {columns.map((_, index) => (
                <TableCell key={index}>
                  <Skeleton sx={{ borderRadius: 0, margin: '8px' }} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export { MuiSkeletonTable }
