import { ThemeOptions } from '@mui/material'
import { NovaColours } from '.'

// const primary = NovaColours.AP060.dark
const secondary = NovaColours.RI010.light
// const defaultBorderColor = NovaColours.N070.light
// const tableRowHover = NovaColours.AP010.light

export const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => ({
         palette: {
           mode,
           secondary: {
             main: secondary,
             light: NovaColours.S000.light,
             dark: NovaColours.S000.dark,
           },
           primary: {
             main: NovaColours.AP060[mode],
             light: '#FFFFFF',
             dark: NovaColours.AP060[mode],
           },
           info: {
             main: '#1890FF',
             light: '#E6F7FF',
             dark: '#33719E',
           },
           success: {
             main: '#97BD6F',
             light: '#F6FFED',
             dark: '#689844',
           },
           error: {
             main: '#F5222D',
             light: '#FFF1F0',
             dark: '#C74545',
           },
         },
         typography: {
           h1: {
             fontWeight: 'normal',
             fontSize: 32,
             lineHeight: '40px',
           },
           h2: {
             fontWeight: 'normal',
             fontSize: 24,
             lineHeight: '36px',
           },
           h3: {
             fontWeight: 'normal',
             fontSize: 16,
             lineHeight: '24px',
           },
           h4: {
             fontWeight: 600,
             fontSize: 18,
             lineHeight: '36px',
           },
           body1: {
             fontWeight: 'normal',
             fontSize: 16,
             lineHeight: '24px',
           },
           fontFamily: 'Inter',
         },
         components: {
           MuiButton: {
             defaultProps: {
               disableElevation: true,
             },
             styleOverrides: {
               containedPrimary: {
                 '> .MuiButton-startIcon': {
                   '> .MuiSvgIcon-root': {
                     fill: NovaColours.N100[mode],
                   },
                 },
               },
               root: {
                 textTransform: 'inherit',
                 '&.Pagination-activeButton.Pagination-text': {
                   color: `${NovaColours.N020[mode]} !important`, // needed to use important here to get the color to apply to number in default table pagination
                 },
               },
             },
           },
           MuiAccordion: {
             styleOverrides: {
               root: {
                 backgroundColor: `${NovaColours.N100[mode]}`,
               },
             },
           },
           MuiSvgIcon: {
             styleOverrides: {
               root: {
                 fill: NovaColours.N040[mode],
               },
               colorPrimary: {
                 fill: NovaColours.AP060[mode],
               },
             },
           },
           MuiIconButton: {
             styleOverrides: {
               root: {
                 padding: '7px',
                 margin: '0px 5px',
                 '& .MuiSvgIcon-root': {
                   fill: NovaColours.N040[mode],
                 },
                 '&:hover': {
                   '& .MuiSvgIcon-root': {
                     fill: NovaColours.AP060[mode],
                   },
                 },
                 '&.Mui-disabled': {
                   '& .MuiSvgIcon-root': {
                     fill: NovaColours.N050[mode],
                   },
                 },
               },
             },
           },
           MuiTab: {
             styleOverrides: {
               root: {
                 textTransform: 'inherit',
               },
               textColorPrimary: {
                 '&.Mui-selected': {
                   '& .MuiSvgIcon-root': {
                     fill: NovaColours.AP060[mode],
                   },
                 },
               },
             },
           },
           MuiInputLabel: {
             styleOverrides: {
               root: {
                 borderRadius: '3px',
                 color: `${NovaColours.AP090[mode]} !important`,
                 fontSize: '.75rem !important',
               },
               shrink: {
                 background: 'transparent !important',
                 color: `${NovaColours.AP090[mode]} !important`,
                 transform: 'translate(14px, -6px) scale(1) !important',
               },
             },
           },
           MuiOutlinedInput: {
             styleOverrides: {
               root: {
                 borderRadius: '6px',
               },
               input: {
                 '&:-webkit-autofill': {
                   WebkitBoxShadow: `0 0 0 100px ${NovaColours.N100[mode]} inset`,
                   WebkitTextFillColor: `${NovaColours.AP100[mode]}`,
                   borderRadius: '0px',
                   fontSize: 'inherit',
                 },
               },
             },
           },
           MuiInputBase: {
             styleOverrides: {
               root: {
                 background: NovaColours.N100[mode],
                 padding: '0px',
                 '& fieldset': {
                   borderColor: NovaColours.N070[mode],
                 },
                 '&:hover': {
                   '& fieldset.MuiOutlinedInput-notchedOutline': {
                     borderColor: NovaColours.N070[mode],
                   },
                   filter:
                     mode === 'light'
                       ? 'drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.18))'
                       : 'none',
                 },
                 '&.Mui-focused': {
                   '& fieldset.MuiOutlinedInput-notchedOutline': {
                     borderColor: NovaColours.N070[mode],
                   },
                   filter:
                     mode === 'light'
                       ? 'drop-shadow(0px 8px 10px rgba(42, 110, 251, 0.18))'
                       : 'none',
                 },
               },
             },
           },
           MuiSelect: {
             styleOverrides: {
               select: {
                 color: NovaColours.AP030[mode],
               },
             },
           },
           MuiDialog: {
             styleOverrides: {
               paper: {
                 background: NovaColours.S010[mode],
               },
               root: {
                 '& .MuiBackdrop-root': {
                   backgroundColor: `${NovaColours.S090[mode]}3C`,
                   backdropFilter: 'blur(4px)',
                 },
               },
             },
           },
           MuiTable: {
             styleOverrides: {
               root: {},
             },
           },
           MuiTableRow: {
             styleOverrides: {
               head: {
                 '&:hover': {
                   boxShadow: 'none',
                   backgroundColor: NovaColours.N100[mode],
                 },
               },
               root: {
                 backgroundColor: NovaColours.N100[mode],
                 '&:hover': {
                   boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                   backgroundColor: NovaColours.AP010[mode],
                 },
                 '&:hover td.action-column': {
                   display: 'flex',
                 },
               },
             },
           },
           MuiTableCell: {
             styleOverrides: {
               root: {
                 padding: 0,
                 height: '36px',
                 borderColor: NovaColours.N070[mode],
                 alignItems: 'center',
                 fontSize: '0.75rem',
               },
             },
           },
           MuiCheckbox: {
             styleOverrides: {
               colorPrimary: {
                 '& .MuiIconButton-label > .MuiSvgIcon-root': {
                   fill: NovaColours.AP060[mode],
                 },
                 '& .MuiSvgIcon-root': {
                   fill: NovaColours.AP060[mode],
                 },
               },
             },
           },
           MuiListItemText: {
             styleOverrides: {
               primary: {
                 fontSize: '0.875rem',
               },
             },
           },
           MuiDialogTitle: {
             styleOverrides: {
               root: {
                 padding: '22px 24px',
               },
             },
           },
           MuiDialogContent: {
             styleOverrides: {
               root: {
                 padding: '0px 24px',
               },
             },
           },
           MuiPaper: {
             styleOverrides: {
               rounded: {
                 borderRadius: '6px',
               },
             },
           },
           MuiDrawer: {
             styleOverrides: {
               paper: {
                 background: NovaColours.N100[mode],
               },
             },
           },
           MuiTypography: {
             styleOverrides: {
               root: {
                 color: NovaColours.N030[mode],
               },
               h5: {
                 color: NovaColours.N030[mode],
               },
             },
           },
           MuiFormHelperText: {
             styleOverrides: {
               root: {
                 backgroundColor: NovaColours.RN010[mode],
                 color: `${NovaColours.RN080[mode]}!important`,
                 padding: '2px 12px',
                 borderRadius: '6px',
                 fontSize: '10px',
                 lineHeight: '16px',
               },
               contained: {
                 marginLeft: '0px',
                 marginRight: '0px',
               },
             },
           },
           MuiToolbar: {
             styleOverrides: {
               root: {
                 background: NovaColours.N100[mode],
               },
             },
           },
           MuiFormControl: {
             styleOverrides: {
               marginNormal: {
                 marginTop: '0px',
                 marginBottom: '0px',
               },
             },
           },
           MuiAutocomplete: {
             styleOverrides: {
               input: {
                 '&:first-of-type': {
                   paddingLeft: '20px !important',
                 },
               },
               inputRoot: {
                 padding: '6px !important',
               },
             },
           },
           MuiButtonBase: {
             styleOverrides: {
               root: {
                 textTransform: 'inherit',
                 '&.Pagination-text': {
                   color: `${NovaColours.N040[mode]} !important`, // needed to use important here to get the color to apply to number in default table pagination
                 },
               },
             },
           },
         },
       })
