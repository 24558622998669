import React from 'react'
import { BoxSubTitle, BoxTitle } from './style'

export const BoxTitleComponent = (props: any) => {
  return (
    <div>
      {props.title ? (
        <BoxTitle> {props.title} </BoxTitle>
      ) : (
        ''
      )}
      {props.subtitle ? (
        <BoxSubTitle> {props.subtitle} </BoxSubTitle>
      ) : (
        ''
      )}
    </div>
  )
}
