import React from 'react'
import { useIntl } from 'react-intl'
import { BarChart, TableView, TimeSeriesChart } from './charts'
import { BarchartProps } from './charts/BarChart'
import { TableViewProps } from './charts/TableView'
import { TimeSeriesChartProps } from './charts/TimeSeriesChart'
import { UseDivSize } from './hooks/useDivSize'
import { CHART_OPTION } from './SelectChartType'
import { SizeConfig } from './types'

export type BarchartConfig<DataPoint> = Omit<BarchartProps<DataPoint>, "data" | "divSize" | "height">
export type TableViewConfig<DataPoint> = Omit<TableViewProps<DataPoint>, "data">
export type TimeSeriesConfig<DataPoint> = Omit<TimeSeriesChartProps<DataPoint>, "data" | "divSize" | "height">

interface Props<DataPoint> {
  currChartType: CHART_OPTION
  data: DataPoint[] | undefined
  divSize: UseDivSize
  error?: boolean
  graphHeight: SizeConfig<number>
  barchartConfig?: BarchartConfig<DataPoint>
  tableViewConfig?: TableViewConfig<DataPoint>
  timeseriesConfig?: TimeSeriesConfig<DataPoint>
}

const AllCharts = <Data,>({ currChartType, data, divSize, error, graphHeight, barchartConfig, tableViewConfig, timeseriesConfig }: Props<Data>) => {
  const { formatMessage } = useIntl()
  const renderChart = () => {
    if (!data || data.length < 1) {
      if (!!error) {
        return <p>{formatMessage({ id: "dataviz.charts.api.error" })}</p>
      } else {
        return <p>{formatMessage({ id: "dataviz.charts.no.data" })}</p>
      }
    }
    switch (currChartType) {
      case CHART_OPTION.timeseries:
        if (!timeseriesConfig) {
          return <p>{formatMessage({ id: "dataviz.charts.proper.config.not.found.timeseries" })}</p>
        }
        return <TimeSeriesChart data={data} divSize={divSize} height={graphHeight[divSize.size]} {...timeseriesConfig} />
      case CHART_OPTION.barchart:
        if (!barchartConfig) {
          return <p>{formatMessage({ id: "dataviz.charts.proper.config.not.found.barchart" })}</p>
        }
        return <BarChart data={data} divSize={divSize} height={graphHeight[divSize.size]} {...barchartConfig} />
      case CHART_OPTION.table:
        if (!tableViewConfig) {
          return <p>{formatMessage({ id: "dataviz.charts.proper.config.not.found.table" })}</p>
        }
        return <TableView data={data} {...tableViewConfig} />
      default:
        return <p>{formatMessage({ id: "dataviz.charts.unsupported.chart.type" })}</p>
    }
  }
  return (
    <div data-testid="all-charts-data-viz">
      {renderChart()}
    </div>
  )
}

export default AllCharts
