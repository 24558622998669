import { IconButton, Theme, Tooltip } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import AssessmentIcon from '@mui/icons-material/Assessment'
import TableChartIcon from '@mui/icons-material/TableChart'
import { Skeleton } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import { LighteningChart } from './ChartIcons'

export const useSelectChartTypeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    unselected: {
      padding: '7px',
      margin: '0px 5px',
      background: '',
      '&:hover': {
        background: '',
        fill: theme.palette.primary.main,
      },
    },
    selected: {
      background: theme.palette.primary.main,
      '& svg': {
        fill: 'white !important',
      },
      '&:hover': {
        background: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
  }),
)

export enum CHART_OPTION {
  table,
  timeseries,
  barchart,
}

export interface SelectChartTypeProps {
  chartOptions: CHART_OPTION[]
  currChart: CHART_OPTION
  error?: boolean
  loading?: boolean
  rootClassName?: string
  setCurrChart: Dispatch<SetStateAction<CHART_OPTION>>
}

const SelectChartType: React.FC<SelectChartTypeProps> = ({
  chartOptions,
  currChart,
  error,
  loading = false,
  rootClassName = '',
  setCurrChart,
}) => {
  const { formatMessage } = useIntl()
  const classes = useSelectChartTypeStyles({})

  const optionTooltips = {
    [CHART_OPTION.table]: formatMessage({ id: 'dataviz.charts.table.tooltip' }),
    [CHART_OPTION.timeseries]: formatMessage({
      id: 'dataviz.charts.timeseries.tooltip',
    }),
    [CHART_OPTION.barchart]: formatMessage({
      id: 'dataviz.charts.barchart.tooltip',
    }),
  }

  if (!!error) {
    return <div></div>
  }

  if (loading) {
    return (
      <div className={`${rootClassName} ${classes.root}`}>
        <Skeleton
          style={{ marginLeft: '6px', marginRight: '6px' }}
          height={36}
          width={36}
          variant="circular"
        />
        <Skeleton
          style={{ marginLeft: '6px', marginRight: '6px' }}
          height={36}
          width={36}
          variant="circular"
        />
        <Skeleton
          style={{ marginLeft: '6px', marginRight: '6px' }}
          height={36}
          width={36}
          variant="circular"
        />
      </div>
    )
  }

  return (
    <div
      className={`${rootClassName} ${classes.root}`}
      data-testid="select-chart-type"
    >
      {chartOptions.includes(CHART_OPTION.table) && (
        <Tooltip title={optionTooltips[CHART_OPTION.table]}>
          <IconButton
            data-testid="select-table-chart"
            className={`${
              currChart === CHART_OPTION.table
                ? classes.selected
                : classes.unselected
            }`}
            onClick={() => setCurrChart(CHART_OPTION.table)}
            size="large"
          >
            <TableChartIcon />
          </IconButton>
        </Tooltip>
      )}
      {chartOptions.includes(CHART_OPTION.timeseries) && (
        <Tooltip title={optionTooltips[CHART_OPTION.timeseries]}>
          <IconButton
            data-testid="select-timeseries-chart"
            className={`${
              currChart === CHART_OPTION.timeseries
                ? classes.selected
                : classes.unselected
            }`}
            onClick={() => setCurrChart(CHART_OPTION.timeseries)}
            size="large"
          >
            <LighteningChart />
          </IconButton>
        </Tooltip>
      )}
      {chartOptions.includes(CHART_OPTION.barchart) && (
        <Tooltip title={optionTooltips[CHART_OPTION.barchart]}>
          <IconButton
            data-testid="select-bar-chart"
            className={`${
              currChart === CHART_OPTION.barchart
                ? classes.selected
                : classes.unselected
            }`}
            onClick={() => setCurrChart(CHART_OPTION.barchart)}
            size="large"
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default SelectChartType
