import { Context } from '../types'
import { Strategy } from './abstract.strategy'

export default class UserWithIdStrategy extends Strategy {
  constructor() {
    super('userWithId')
  }

  isEnabled(parameters: any, context: Context) {
    const userIdList = parameters.userIds.split(/\s*,\s*/)
    const trimmedUserIdList = userIdList.map((userId: string) => userId.trim())
    return trimmedUserIdList.includes(context.userId)
  }
}
