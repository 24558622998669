import { Strategy } from './abstract.strategy'
import DefaultStrategy from './default.strategy'
import RemoteAddressStrategy from './remote.address'
import UserWithIdStrategy from './user.ids.strategy'

export const defaultStrategies: Strategy[] = [
  new DefaultStrategy(),
  new RemoteAddressStrategy(),
  new UserWithIdStrategy(),
]
