import {
  Getter,
  Getters,
  Plugin,
  Template,
  TemplateConnector,
} from '@devexpress/dx-react-core'
import { TableColumn } from '@devexpress/dx-react-grid'
import {
  Table,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { TableCell, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) => {
  const rgbBackground =
    theme.palette.mode === 'dark' ? '19, 19, 21' : '245, 248, 255'
  return createStyles({
    fixed: {
      display: 'flex',
      position: 'absolute',
      zIndex: 130,
      right: 0,
      width: 0,
    },
    hidden: {
      display: 'none',
    },
    actionWrapper: {
      whiteSpace: 'nowrap',
      position: 'absolute',
      display: 'flex',
      alignContent: 'flex-end',
      alignItems: 'center',
      paddingLeft: '60px',
      background: `linear-gradient(90deg, rgba(${rgbBackground},0) 0%, rgba(${rgbBackground},1) 20%, rgba(${rgbBackground},1) 100%)`,
      height: '100%',
    },
  })
})

const ACTION_COLUMN_TYPE = Symbol('ACTION_COLUMN')

function makeDictionary<T>(values: T[], getKey: (value: T) => string) {
  return values.reduce((acc, v) => {
    acc[getKey(v)] = v
    return acc
  }, {} as { [key: string]: T })
}

const pluginDependencies = [{ name: 'Table' }]
export interface IActionColumn {
  columnName: string
  render: (row: any) => React.ReactNode
}
interface Props {
  actionColumns: IActionColumn[]
  hovered?: any
}
const computeColumns = (
  actionColumns: { [key: string]: IActionColumn },
  getters: Getters,
) => {
  const tableColumns = getters.tableColumns as TableColumn[]
  const columns = tableColumns.map(tableColumn => {
    if (!tableColumn.column || !actionColumns[tableColumn.column.name]) {
      return tableColumn
    }
    tableColumn.type = ACTION_COLUMN_TYPE
    return tableColumn
  })
  return columns
}

const isActionTableCell = (params: any) =>
  (params.tableRow.type === Table.ROW_TYPE ||
    params.tableRow.type === VirtualTable.ROW_TYPE) &&
  params.tableColumn.type === ACTION_COLUMN_TYPE

const isActionTableHeader = (params: any) =>
  params.tableRow.type === TableHeaderRow.ROW_TYPE &&
  params.tableColumn.type === ACTION_COLUMN_TYPE

export const ActionColumns: FunctionComponent<Props> = ({ actionColumns }) => {
  const classes = useStyles({})
  const columnDictionary = makeDictionary(actionColumns, i => i.columnName)
  return (
    <Plugin name="ActionColumn" dependencies={pluginDependencies}>
      <Getter
        name="tableColumns"
        computed={computeColumns.bind(null, columnDictionary)}
      />
      <Template name="tableCell" predicate={isActionTableHeader}>
        {() => (
          <TemplateConnector>
            {() => {
              return (
                <TableCell
                  className={classes.fixed}
                  data-testid="app-action-column-header"
                  align="right"
                />
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        data-testid="app-action-column-body"
        name="tableCell"
        predicate={isActionTableCell}
      >
        {(params: any) => (
          <TemplateConnector>
            {() => {
              const actionColumn =
                columnDictionary[params.tableColumn.column.name]
              return (
                <TableCell
                  className={clsx(classes.fixed, {
                    'action-column': true,
                    [classes.hidden]: !params.tableRow.hovered,
                  })}
                  align="right"
                >
                  <div className={classes.actionWrapper}>
                    {actionColumn.render(params.tableRow.row)}
                  </div>
                </TableCell>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  )
}
