import { select as d3Select, Selection } from "d3-selection";
import { transition as d3Transition } from 'd3-transition';
import { useState, useEffect, useRef, MutableRefObject } from "react";
import { NovaColours } from '@novafuturltd/core'
import { Margins, SIZE } from "../types";
import { useChartColors } from "./useChartColors";
import { UseDivSize } from "./useDivSize";
d3Select.prototype.transition = d3Transition;

interface Props {
    divSize: UseDivSize
    height: number
    hideXLabelSmallScreen?: boolean
    marginsLg: Margins
    marginsMd: Margins
    marginsSm: Margins
    xLabel: string
    yLabel: string
}

interface UseSvgGraph {
    contentGroup: Selection<SVGGElement, unknown, HTMLElement | null, any> | undefined
    chartHeight: number
    chartWidth: number
    margins: Margins
    svgHeight: number
    svgRef: MutableRefObject<SVGSVGElement>
    xAxisGroup: Selection<SVGGElement, unknown, HTMLElement | null, any> | undefined
    yAxisGroup: Selection<SVGGElement, unknown, HTMLElement | null, any> | undefined
}

//React.RefObject<TElement>

export const useSvgGraph = ({
    divSize,
    height,
    hideXLabelSmallScreen,
    marginsLg,
    marginsSm,
    marginsMd,
    xLabel,
    yLabel,
}: Props): UseSvgGraph => {
    const svgRef = useRef() as MutableRefObject<SVGSVGElement>;
    const { labelColor, themeMode } = useChartColors()
    const [margins, setMargins] = useState<Margins>(marginsSm)

    useEffect(() => {
        switch (divSize.size) {
            case SIZE.large:
                setMargins(marginsLg)
                break
            case SIZE.medium:
                setMargins(marginsMd)
                break
            case SIZE.small:
                setMargins(marginsSm)
                break
            default:
                setMargins(marginsMd)
        }
    }, [divSize.size])


    const [chartWidth, setChartWidth] = useState<number>(divSize.width || 0)
    const [chartHeight, setChartHeight] = useState<number>(height)
    useEffect(() => {
        const { top, bottom, left, right } = margins
        const { width } = divSize
        if (!width) {
            return
        }
        setChartWidth(width - left - right)
        setChartHeight(height - top - bottom)
    }, [divSize.width, height, margins])

    const [xAxisGroup, setXAxisGroup] = useState<Selection<SVGGElement, unknown, HTMLElement | null, any> | undefined>()
    const [yAxisGroup, setYAxisGroup] = useState<Selection<SVGGElement, unknown, HTMLElement | null, any> | undefined>()
    const [contentGroup, setContentGroup] = useState<Selection<SVGGElement, unknown, HTMLElement | null, any> | undefined>()

    const appendYLabel = () => {
        const { width, size } = divSize
        if (!svgRef.current || !width) {
            return
        }
        const svg = d3Select(svgRef.current)
        svg.call(g => g.append("text")
            .attr("font-size", () => {
                switch (size) {
                    case SIZE.large:
                        return 16
                    case SIZE.medium:
                        return 14
                    case SIZE.small:
                        return 12
                    default:
                        return 12
                }
            })
            .attr('transform', `translate(${16},${((height - margins.bottom) / 2) + margins.top})rotate(-90)`)
            .attr("fill", labelColor)
            .text(yLabel));
    }

    const appendXLabel = () => {
        const { width, size } = divSize
        if (!svgRef.current || !width) {
            return
        }
        const svg = d3Select(svgRef.current)
        const text = svg.append("text")
            .attr("font-size", () => {
                switch (size) {
                    case SIZE.large:
                        return 16
                    case SIZE.medium:
                        return 14
                    case SIZE.small:
                        return 12
                    default:
                        return 12
                }
            })
            .attr("fill", labelColor)
            .attr("text-anchor", "left")
            .text(xLabel);
        const textWidth = text.node()?.getBBox?.().width || 0
        let y = 0
        let x = 0
        let rotation = 0
        switch (size) {
            case SIZE.large:
                y = height - margins.bottom
                x = width - margins.right + 16
                rotation = 0
                break
            case SIZE.medium:
                y = height - margins.bottom
                x = width - margins.right + 16
                rotation = -90
                break
            case SIZE.small:
                y = height - 16
                x = (chartWidth / 2) - ((textWidth || 0) / 2)
                rotation = 0
                break
            default:
                y = height - 16
                x = (chartWidth / 2) - ((textWidth || 0) / 2)
                break
        }
        text.attr('transform', `translate(${x},${y})rotate(${rotation})`)
    }

    const genGraph = () => {
        const { width, size } = divSize
        if (!svgRef.current || !width) {
            return
        }
        const {
            top: marginTop,
            bottom: marginBottom,
            left: marginLeft,
        } = margins
        const svg = d3Select(svgRef.current)
        svg.selectAll("*").remove()
        svg.attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

        const newXAxisGroup = svg.append("g")
            .attr("transform", `translate(${marginLeft}, ${height - marginBottom})`)
            .style("font-size", "16px")

        setXAxisGroup(newXAxisGroup)

        const newYAxisGroup = svg.append("g")
            .attr("transform", `translate(${marginLeft}, ${marginTop})`)
            .style("font-size", "16px")
        setYAxisGroup(newYAxisGroup)

        if (size !== SIZE.small) {
            appendYLabel()
        }

        if (size !== SIZE.small || !hideXLabelSmallScreen) {
            appendXLabel()
        }

        const newContentGroup = d3Select(svgRef.current).append("g")
            .attr("transform", `translate(${marginLeft},${marginTop})`);

        const filter = newContentGroup.append("filter")
            .attr("id", "shadow")
        filter.append("feDropShadow")
            .attr("dx", 0)
            .attr("dy", 2)
            .attr("stdDeviation", 2)
            .attr("flood-color", themeMode === 'light' ? NovaColours.S000['dark'] : NovaColours.S000['light'])
            .attr("flood-opacity", "0.2")
        setContentGroup(newContentGroup)

    }

    useEffect(() => {
        genGraph()
    }, [svgRef, divSize.width, chartWidth])

    return {
        contentGroup,
        chartHeight,
        chartWidth,
        margins,
        svgHeight: height,
        svgRef,
        xAxisGroup,
        yAxisGroup,
    }
}
