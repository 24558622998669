import React from 'react'
import { BoxTitleComponent } from '../BoxTitle'
import { BoxWrapper } from './style'

export const Box = (props: any) => (
  <BoxWrapper
    // className={`${props.className ? props.className : ''} isoBoxWrapper`}
    // style={props.style}
  >
    <BoxTitleComponent title={props.title} subtitle={props.subtitle} />
    {props.children}
  </BoxWrapper>
)
