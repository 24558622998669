import { Context } from '../types'
import { Strategy } from './abstract.strategy'

export default class DefaultStrategy extends Strategy {
  constructor() {
    super('default')
  }

  isEnabled(_parameters: any, _context: Context): boolean {
    return true
  }
}
