import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded'
import { SortChip, SortChipProps } from '../..'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Heading } from '../../typography/heading'
import { BaseTablePanel } from '../base-panel'
import useStyles from '../styles'

interface SortingPanelProps {
  chips: SortChipProps[]
  clearAllSorting?: () => void
}

// add default chips, remove later
export const SortingPanel: FC<SortingPanelProps> = ({
  chips,
  clearAllSorting,
}) => {
  const classes = useStyles({})
  const intl = useIntl()
  const chipComponents: JSX.Element[] = chips.map(chip => (
    <SortChip
      key={chip.label}
      label={chip.label}
      handleDelete={chip.handleDelete}
      onDirectionChange={chip.onDirectionChange}
      isAsc={chip.isAsc}
    />
  ))

  return (
    <BaseTablePanel>
      <Box className={classes.headingContainer}>
        <SortByAlphaRoundedIcon className={classes.sortIcon} />
        <Heading variant="h3" className={classes.header}>
          {intl.formatMessage({
            id: 'general.sorting.panel.header',
          })}
        </Heading>
      </Box>
      <Box className={classes.chipContainer}>
        {chipComponents}
        <Typography className={classes.sortText} variant="body1">
          {intl.formatMessage({
            id: 'general.sorting.panel.text',
          })}
        </Typography>
      </Box>
      <Box className={classes.removeIconContainer}>
        {clearAllSorting && (
          <Tooltip
            title={intl.formatMessage({
              id: 'general.sorting.panel.clear.all.tooltip',
            })}
          >
            <IconButton onClick={clearAllSorting} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </BaseTablePanel>
  );
}
