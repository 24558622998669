import React from 'react'

interface Props {
  customClass?: string
}

export const FourBarChart = ({ customClass }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`MuiSvgIcon-root ${customClass}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17H6V10H8V17ZM13 17H15V11H13V17ZM11 17H9V7H11V17ZM16 17H18V13H16V17Z"
      />
    </svg>
  )
}

export const LighteningChart = ({ customClass }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`MuiSvgIcon-root ${customClass}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM13.1176 15.011L19 9.19046L17.2567 7L13.0941 11.1188L10.8824 8.98897L5 14.8095L6.74328 17L10.9059 12.8812L13.1176 15.011Z"
      />
    </svg>
  )
}
