import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export const useActionStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      actionButton: {
        padding: theme.spacing(0, 1),
        marginRight: theme.spacing(1),
      },
      menuItem: {
        fontSize: '12px',
        fontColor: NovaColours.N020[theme.palette.mode],
        fontWeight: 400,
        alignItems: 'center',
        borderBottom: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
        '&:first-child': {
          borderTop: 'none',
          '&:hover': {
            borderRadius: theme.spacing(0.625, 0.625, 0, 0),
          },
        },
        '&:last-child': {
          borderBottom: 'none',
          '&:hover': {
            borderRadius: theme.spacing(0, 0, 0.625, 0.625),
          },
        },
      },
      menuItemContainer: {
        '& .MuiList-root': {
          alignItems: 'center',
          paddingBottom: '0px !important',
          paddingTop: '0px !important',
          boxShadow: theme.shadows[5],
          border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
          borderRadius: '6px',
        },
      },
      menuItemIcon: {
        fontSize: '12px',
        marginRight: '12.64px',
        width: '18px',
        height: '18px',
        top: '0.75px',
        left: '1.5px',
      },
    }),
  { index: 1 },
)
