/**
 * Extract Value for given Key in string of format 'key=value;...
 * @param  {string} rawString The string within which to search for the give key. Assumes format is key=value;key2=value2
 * @param  {string} key The key for which we search for and extract the corresponding value of.
 * @return {string}       The value corresponding to the give key. Empty string if key not found.
 */
export const extractValueFromString = (
  rawString: string,
  key: string,
): string => {
  const keyValues: string[] = rawString.split(';')
  for (const cookie of keyValues) {
    const splitParts = cookie.split('=')
    const cookieKey = splitParts[0]
    if (cookieKey === key) {
      if (splitParts.length > 0) {
        const value = splitParts[1]
        return value
      }
    }
  }
  return ''
}
