import { IconButton, Tooltip } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Notify } from '../hooks/useNotifications'
import { CopyIcon } from '../icons/CopyIcon'
import { enCopyToClipboardTranslations } from './language'

interface Props {
  notify?: Notify
  value: string
}
const CopyToClipboardButton: FC<Props> = ({ notify, value }) => {
  const { formatMessage } = useIntl()

  useEffect(() => {
    const Clipboard = require('clipboard')
    // tslint:disable-next-line: no-unused-expression
    new Clipboard('#copy-link')
  }, [])

  return (
    <Tooltip
      title={formatMessage({
        id: 'copy.to.clipboard',
      })}
    >
      <IconButton
        style={{ padding: '0px' }}
        data-clipboard-text={value}
        onClick={() => {
          notify?.success(formatMessage({ id: 'copied.to.clipboard' }))
        }}
        id="copy-link"
        size="large"
      >
        <CopyIcon />
      </IconButton>
    </Tooltip>
  )
}

export { CopyToClipboardButton, enCopyToClipboardTranslations }
