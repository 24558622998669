import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close'
import { DefaultTable, Heading } from '../../..'
import React, { FC, useEffect, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
)

interface Props {
  loading: boolean
  onSelect: (template: any) => void
  heading: string
  columns: any
  rows: any
  actions?: React.ReactNode
  filterTabs?: React.ReactNode
  visible: boolean
  onCancel: () => void
  sorting: any
  setSorting: any
  defaultHiddenColumnNames?: string[]
  pagination?: { total: number; page: number; per_page: number }
  setCurrentPage?: (query: any) => void
  changePageSize?: (query: any) => void
}

export const SelectableModalTable: FC<Props> = ({
  onSelect,
  visible,
  onCancel,
  loading,
  heading,
  columns,
  rows,
  actions,
  filterTabs,
  defaultHiddenColumnNames,
  sorting,
  setSorting,
  children,
  pagination,
  setCurrentPage,
  changePageSize,
}) => {
  const classes = useStyles({})
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (visible === true) {
      setSelected(false)
    }
  }, [visible])

  const TableRow = ({ row, ...restProps }: any) => (
    // @ts-ignore
    <Table.Row
      data-test="selectable-row"
      {...restProps}
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        if (!selected) {
          // tslint:disable-next-line: no-non-null-assertion
          onSelect!({
            ...row,
          })
          setSelected(true)
        }
      }}
    />
  )

  return (
    <Dialog onClose={onCancel} open={visible} fullWidth={true} maxWidth="md">
      <DialogTitle>
        <Heading variant="h2">{heading}</Heading>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCancel}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {filterTabs && <DialogContent>{filterTabs}</DialogContent>}
      <DialogContent>
        <DefaultTable
          rows={rows}
          columns={columns}
          rowComponent={TableRow}
          customTestId="selectable-modal-table"
          loading={loading}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          sorting={sorting}
          setSorting={setSorting}
          disableFiltering={true}
          pagination={{
            total: pagination?.total,
            per_page: pagination?.per_page,
            page: pagination?.page,
          }}
          setCurrentPage={setCurrentPage}
          allowedPageSizes={[5, 10, 25, 50, 100]}
          changePageSize={changePageSize}
        >
          {children}
        </DefaultTable>
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}

export default SelectableModalTable
