import { createContext, useContext, useEffect, useState } from 'react'

interface IAppContext {
  openDrawer: boolean
  hoverDrawer: boolean
  collapsed: boolean
  height: number
  width: number
  view: 'MobileView' | 'DesktopView' | 'TabView'
  isMobile: boolean
  mode: 'light' | 'dark'
  isFooterVisible: boolean
  isScrollable: boolean
  toggleOpenDrawer: (type: boolean) => void
  toggleHoverDrawer: (type: boolean) => void
  toggleAll: (width: number, height: number) => void
  toggleDarkMode: () => void
}

export const AppContext = createContext<IAppContext>({
  openDrawer: true,
  hoverDrawer: false,
  collapsed: false,
  height: 0,
  width: 0,
  view: 'MobileView',
  isMobile: true,
  mode: 'light',
  isFooterVisible: true,
  isScrollable: true,
  toggleOpenDrawer: () => {},
  toggleHoverDrawer: () => {},
  toggleAll: () => {},
  toggleDarkMode: () => {},
})

export const useAppContext = () => useContext(AppContext)

export const THEME_PREFERENCE_LOCAL_STORAGE_KEY = 'userTheme'

export const AppProvider = (props: { children: React.ReactNode }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(true)
  const [hoverDrawer, setHoverDrawer] = useState<boolean>(false)
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [view, setView] = useState<'MobileView' | 'DesktopView' | 'TabView'>(
    'MobileView',
  )
  const isMobile = view === 'MobileView'
  const [isFooterVisible] = useState<boolean>(true)
  const [isScrollable] = useState<boolean>(true)
  const [mode, setMode] = useState<'dark' | 'light'>('light')

  const toggleDarkMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark'
    setMode(newMode)
    if (typeof window !== 'undefined') {
      localStorage.setItem(THEME_PREFERENCE_LOCAL_STORAGE_KEY, newMode)
    }
    document.documentElement.style.removeProperty('background')
    document.body.style.removeProperty('background')
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      localStorage.getItem(THEME_PREFERENCE_LOCAL_STORAGE_KEY)
    ) {
      // @ts-ignore
      const newMode = localStorage.getItem(THEME_PREFERENCE_LOCAL_STORAGE_KEY)
      if (newMode === 'light' || newMode === 'dark') {
        setMode(newMode)
      }
    }
  }, [])

  const toggleOpenDrawer = (type: boolean) => setOpenDrawer(type)

  const toggleHoverDrawer = (type: boolean) => setHoverDrawer(type)

  const getView = (
    viewWidth: number,
  ): 'MobileView' | 'DesktopView' | 'TabView' => {
    let newView: 'MobileView' | 'DesktopView' | 'TabView' = 'MobileView'
    if (viewWidth > 1220) {
      newView = 'DesktopView'
    } else if (viewWidth > 767) {
      newView = 'TabView'
    }
    return newView
  }

  const toggleAll = (viewWidth: number, viewHeight: number) => {
    const newView = getView(viewWidth)
    const isCollapsed = view !== 'DesktopView'
    setCollapsed(isCollapsed)
    setView(newView)
    setHeight(viewHeight)
    setWidth(viewWidth)
  }

  return (
    <AppContext.Provider
      value={{
        openDrawer,
        hoverDrawer,
        collapsed,
        height,
        width,
        view,
        isMobile,
        mode,
        isFooterVisible,
        isScrollable,
        toggleOpenDrawer,
        toggleHoverDrawer,
        toggleAll,
        toggleDarkMode,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
