import { Check } from '@mui/icons-material'
import { Box, Drawer, Theme, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import { ActionButton, CancelButton } from '@novafuturltd/shared'
import { useIntl } from 'react-intl'
import { Heading } from '..'
interface NovaDrawerProps {
  open: boolean
  onClose: () => void
  anchor: 'bottom' | 'left' | 'right' | 'top'
  headerButtons?: React.ReactNode
  headerText: string
  action?: {
    onClick: () => void | any
    disabled?: boolean
    text?: string
    DataTest?: string
    icon?: React.ReactNode
    display?: boolean
  }
  footerButtons?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
    },
    drawer: {
      padding: theme.spacing(3),
      maxWidth: '640px',
      width: () =>
        typeof window !== 'undefined' && window.innerWidth > 640 ? 640 : 'auto',
    },
    drawerBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dialogActions: {
      position: () =>
        typeof window !== 'undefined' && window.innerWidth > 640
          ? 'fixed'
          : 'sticky',
      display: 'flex',
      width: () =>
        typeof window !== 'undefined' && window.innerWidth > 640 ? 640 : 'auto',
      right: theme.spacing(0),
      bottom: theme.spacing(0),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
      justifyContent: 'flex-end',
      zIndex: 1000,
      background: () =>
        theme.palette.mode === 'light'
          ? '#FFFFFF'
          : NovaColours.S000[theme.palette.mode],
      borderTop: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    footerButtons: {
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      display: 'inline-block',
    },
    children: {
      marginBottom: 100,
    },
  }),
)

export const NovaDrawer: React.FC<NovaDrawerProps> = ({
  children,
  open,
  onClose,
  action,
  anchor,
  headerText,
  headerButtons,
  footerButtons,
  ...props
}) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const classes = useStyles({})

  return (
    <Drawer open={open} onClose={onClose} anchor={anchor} {...props}>
      <Box className={classes.drawer}>
        <Box className={classes.drawerBox}>
          <Box className={classes.drawerBox}>
            <Box display="flex" className={classes.header}>
              <Heading variant="h2">{headerText}</Heading>
            </Box>
          </Box>
          <Box>{headerButtons}</Box>
        </Box>
        <Box className={classes.children}>{children}</Box>
      </Box>
      <Box className={classes.dialogActions}>
        <CancelButton onClick={onClose} dataTestId="drawer-close-icon">
          {formatMessage({
            id: 'general.filter.drawer.button.cancel',
          })}
        </CancelButton>
        {action && (
          <ActionButton
            startIcon={
              action.icon || (
                <Check sx={{ fill: NovaColours.AP010[theme.palette.mode] }} />
              )
            }
            onClick={action.onClick}
            disabled={action.disabled}
            data-test={action.DataTest || 'action-button'}
            text={action.text}
            style={{ color: NovaColours.AP010[theme.palette.mode] }}
          >
            {action.text ||
              formatMessage({
                id: 'general.filter.drawer.button.apply',
              })}
          </ActionButton>
        )}
        {footerButtons && (
          <Box className={classes.footerButtons}>{footerButtons}</Box>
        )}
      </Box>
    </Drawer>
  )
}

export default NovaDrawer
