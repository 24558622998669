import { SvgIcon, SvgIconProps } from '@mui/material'
import { NovaColours, useAppContext } from '@novafuturltd/core'

export const BetReceiptIcon = (props: SvgIconProps) => {
  const { mode } = useAppContext()
  const defaultFill = NovaColours.N040[mode]

  return (
    <SvgIcon {...props}>
      <path
        d="M18 16H9V14H18V16ZM18 12H9V10H18V12ZM18 8H9V6H18V8ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM3 20L4.5 18.5L6 20L7.5 18.5L9 20L10.5 18.5L12 20L13.5 18.5L15 20L16.5 18.5L18 20L19.5 18.5L21 20V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V20Z"
        fill={defaultFill}
      />
    </SvgIcon>
  )
}

export default BetReceiptIcon
