import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

const InjectMassage = (props: any) => <FormattedMessage {...props} />
export const IntlMessages = injectIntl(InjectMassage, {
  // @ts-ignore
  withRef: false,
})

const InjectMessageToPlaceHolder = (props: any) => {
  const formatInputPlaceHolder = (
    <FormattedMessage {...props} id={props.textID}>
      {msg => React.cloneElement(props.children, { placeholder: [msg] })}
    </FormattedMessage>
  )
  const element = formatInputPlaceHolder
  return <>{element}</>
}

export const IntlMessagePlaceHolder = injectIntl(InjectMessageToPlaceHolder, {
  // @ts-ignore
  withRef: false,
})
