import { FC } from 'react'
import { useOAuth } from '@novafuturltd/core'
import permissionValidation from './helpers/permissionValidation'

type Props = {
  perform: any
  yes?: any
  no?: any
}

export const Can: FC<Props> = ({ perform, yes = () => null, no = () => null }) => {
  const { permissions } = useOAuth()
  return permissionValidation(permissions, perform) ? yes() : no()
}
