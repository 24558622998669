import * as Jsondiffpatch from 'jsondiffpatch'
import React from 'react'

const formatters = Jsondiffpatch.formatters

export const JsonDiff = (props: any) => {
  const { left, delta } = props

  try {
    const html = formatters.html.format(delta, left)

    Jsondiffpatch.formatters.html.hideUnchanged()

    return html ? (
      <div dangerouslySetInnerHTML={{ __html: html }} />
    ) : (
      <p>{'Both objects are identical.'}</p>
    )
  } catch (e) {
    return <p>{'Both objects are identical.'}</p>
  }
}
