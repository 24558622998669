import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded'
import React, { FC, useEffect, useState } from 'react'
import { TDynamicColumn } from '../types/columns'
import { groupColumns } from '../filter-drawer/helper'
import { BaseTablePanel } from '../panels/base-panel'
import { MultiColumnSelect } from './multiselect/MultiSelect'
import useStyles from './styles'

interface CustomiseTablePanelProps {
  hidden: boolean
  availableColumns: TDynamicColumn[]
  defaultColumns: TDynamicColumn[] | null
  changeColumns: (e: any, v: any) => void
  numColumnsLimit: number
  showChips?: boolean
}

// add default chips, remove later
export const CustomiseTablePanel: FC<CustomiseTablePanelProps> = ({
  hidden,
  availableColumns,
  defaultColumns,
  changeColumns,
  // numColumnsLimit,
  // showChips = false,
}) => {
  const classes = useStyles({})
  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  // const checkedIcon = <CheckBoxIcon fontSize="small" />
  // const [selectedOptions, setSelectedOptions] = React.useState<
  //   TDynamicColumn[]
  // >([])
  const [groupedSelections, setGroupedSelections] = useState<{
    [key: string]: any
  }>({})
  useEffect(() => {
    // tslint:disable-next-line: no-unused-expression
    if (defaultColumns) {
      // setSelectedOptions(defaultColumns)
      setGroupedSelections(groupColumns(defaultColumns))
    }
  }, [defaultColumns])

  // const disableOptions = (option: TDynamicColumn, limit: number): boolean => {
  //   return (
  //     selectedOptions.length === limit &&
  //     !selectedOptions.some(
  //       (o: TDynamicColumn) => o.details.label === option.details.label,
  //     )
  //   )
  // }

  const groupedColumns: { [key: string]: string[] } = groupColumns(
    availableColumns,
  )

  const groupedDefaults = groupColumns(defaultColumns || [])

  useEffect(() => {
    if (groupedSelections) {
      changeColumns(
        null,
        Object.keys(groupedSelections).reduce((acc: {}[], curr) => {
          acc = [...acc, ...groupedSelections[curr]]
          return acc
        }, []),
      )
    }
  }, [groupedSelections])

  return !hidden ? (
    <BaseTablePanel style={{ overflowX: 'auto' }}>
      <ViewWeekRoundedIcon className={classes.customisationIcon} />
      {Object.keys(groupedColumns).map((key: string) => {
        return (
          <MultiColumnSelect
            key={key}
            value={
              groupedDefaults && groupedDefaults[key]
                ? groupedDefaults[key]
                : []
            }
            onChange={(values: string[]) => {
              setGroupedSelections({ ...groupedSelections, [key]: values })
            }} // From useState
            options={groupedColumns[key]}
            label={key}
            labelPlural={key}
          />
        )
      })}
    </BaseTablePanel>
  ) : (
    <></>
  )
}
