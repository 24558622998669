import { Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'
import React, { FC } from 'react'

const useTablePanelStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: NovaColours.N090[theme.palette.mode],
  },
}))

export const BaseTablePanel: FC<{ style?: object }> = ({ children, style }) => {
  const classes = useTablePanelStyles()
  return (
    <Box
      id="nova-table-panel"
      display="flex"
      alignItems="center"
      className={classes.root}
      style={style ? style : {}}
    >
      {children}
    </Box>
  )
}

const usePanelGroupStyles = makeStyles((theme: Theme) => ({
  root: {
    borderLeft: 'none',
    borderRight: 'none',
    '& > #nova-table-panel': {
      borderTop: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    '& > #nova-table-panel:last-of-type': {
      borderBottom: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    // borderBottom: '1px solid #E9E9E9',
  },
}))

export const PanelGroup: FC<any> = ({ children }) => {
  const classes = usePanelGroupStyles()
  return <div className={classes.root}>{children}</div>
}
