import * as Sentry from '@sentry/browser'

import { currentEnv } from '../../sentry.common.config'

const useRealSentry = !!currentEnv

export const setUser = (
  user: {
    email: string
    id: string
    username: string
  } | null,
) => {
  if (useRealSentry) {
    if (!user) {
      Sentry.setUser(null)
    } else {
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: user.username,
      })
    }
  }
}

/**
 * 
 * @param message - The message to send to Sentry
 * @param level - The severity level of the message
 * 
 * Severity is an enum and can be imported from the same file as this function.
 */
export const captureMessage = (
  message: string,
  level?: Parameters<typeof Sentry.captureMessage>[1]
) => {
  if (useRealSentry) {
    Sentry.captureMessage(message, level)
  } else {
    console.trace(level, message)
  }
}

export type { Severity } from '@sentry/browser'
