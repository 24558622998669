import { IconButton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'
import React, { FunctionComponent } from 'react'

interface Props {
  action?: () => void
  icon?: React.ReactNode
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined
  fontWeight?: string
  className?: any
  style?: { [key: string]: any }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: (props: any) => (props.fontWeight ? props.fontWeight : ''),
      color: NovaColours.N030[theme.palette.mode],
      display: 'flex',
      alignItems: 'center',
    },
    IconButton: {
      marginRight: theme.spacing(1.5),
    },
  }),
)

export const Heading: FunctionComponent<Props> = ({
  action,
  icon,
  children,
  variant,
  fontWeight,
  className,
  ...props
}) => {
  const classes = useStyles({ fontWeight })
  return (
    <Typography
      className={`${classes.root} ${className}`}
      data-testid="app-heading"
      variant={variant || 'h2'}
      {...props}
    >
      {icon && (
        <IconButton
          onClick={action}
          className={classes.IconButton}
          edge="end"
          aria-label="Heading"
          aria-haspopup="true"
          color="inherit"
          size="large">
          {icon}
        </IconButton>
      )}
      {children}
    </Typography>
  );
}
