import { createTheme, Theme, ThemeOptions } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export const materialTheme = (theme: Theme) =>
  createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          textPrimary: {
            color: NovaColours.AP060[theme.palette.mode],
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            borderRadius: '3px',
            color: NovaColours.AP030[theme.palette.mode],
            fontSize: '.75rem !important',
            marginRight: `${theme.spacing(3)} `,
            '&$disabled': {
              color: NovaColours.N050[theme.palette.mode],
            },
          },
          shrink: {
            background: `transparent !important`,
            color: NovaColours.AP030[theme.palette.mode],
            padding: '6px 6px',
            '&$disabled': {
              color: NovaColours.N050[theme.palette.mode],
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: NovaColours.N100[theme.palette.mode],
            '&$disabled': {
              backgroundColor: NovaColours.N090[theme.palette.mode],
            },
            height: '52px',
          },
          input: {
            '&::placeholder': {
              borderRadius: '3px',
              fontColor: `${NovaColours.AP060[theme.palette.mode]} !important`,
              opacity: '1 !important',
              fontSize: '.75rem !important',
            },
            color: NovaColours.AP030[theme.palette.mode],
            '&$disabled': {
              color: NovaColours.N050[theme.palette.mode],
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: NovaColours.N040[theme.palette.mode],
            '&$disabled': {
              color: NovaColours.N060[theme.palette.mode],
            },
          },
        },
      },
    },
  } as ThemeOptions)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: (props: any) => (props.width ? props.width : '100%'),
      marginTop: '24px',
      '&:hover': {
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 8px 10px rgba(0, 0, 0, 0.18)'
            : 'none',
      },
      display: 'flex',
    },
    containerBlueShadow: {
      maxWidth: (props: any) => (props.width ? props.width : '100%'),
      marginTop: '24px',
      boxShadow:
        theme.palette.mode === 'light'
          ? '0px 8px 10px rgba(42, 110, 251, 0.18)'
          : 'none',
      display: 'flex',
    },
    dateWidth: {
      width: '50%',
    },
    notchedOutlineStartNumber: {
      borderRight: 'none',
      borderRadius: '4px 0px 0px 4px',
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    notchedOutlineStartNumberFocus: {
      borderRadius: '4px 0px 0px 4px',
      border: `2px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderRight: 'none !important',
    },
    notchedOutlineEndNumber: {
      borderLeft: 'none',
      borderRadius: '0px 4px 4px 0px',
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    notchedOutlineEndNumberFocus: {
      borderRadius: '0px 4px 4px 0px',
      border: `2px solid ${NovaColours.N070[theme.palette.mode]} !important`,
      borderLeft: 'none !important',
    },
    outlineHover: {
      '& fieldset': {
        borderColor: `${NovaColours.N070[theme.palette.mode]} !important`,
      },
    },
    outlineHoverError: {
      '& fieldset': {
        borderColor: ` #F5222D !important`,
      },
    },
    focus: {
      '& fieldset': {
        borderColor: `${NovaColours.N070[theme.palette.mode]} !important`,
      },
    },
    dash: {
      padding: '13px 0 12px 0',
      height: '52px',
      borderTop: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderBottom: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      backgroundColor: NovaColours.N100[theme.palette.mode],
    },
    dashError: {
      padding: '13px 0 12px 0',
      height: '52px',
      borderTop: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderBottom: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      backgroundColor: NovaColours.N100[theme.palette.mode],
      borderColor: '#F5222D !important',
    },

    dashFocus: {
      padding: '12px 0 11px 0',
      height: '52px',
      borderTop: `2px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderBottom: `2px solid ${NovaColours.N070[theme.palette.mode]}`,
      backgroundColor: NovaColours.N100[theme.palette.mode],
    },
    dashIcon: {
      fill: NovaColours.N040[theme.palette.mode],
    },
    boxError: {
      backgroundColor: theme.palette.error.light,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1, 2, 1),
      borderRadius: theme.spacing(0.75),
      '& svg': {
        fill: theme.palette.error.main,
        marginRight: theme.spacing(1),
      },
      '& span': {
        color: theme.palette.error.main,
      },
    },
  }),
)
