import React, { FC, PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { AppLocale } from './languageProvider'

export const NovaLanguageProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <IntlProvider locale="en" messages={AppLocale.en.messages}>
      {children}
    </IntlProvider>
  )
}
