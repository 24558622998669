// @ts-ignore
import React, { useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { TransitionProps } from '@mui/material/transitions'

import AddBoxIcon from '@mui/icons-material/AddBoxSharp'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { AddItemProps } from './props'
import {
  ActionButton,
  CancelButton,
} from '../..'

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => {
    return <Grow ref={ref} {...props} />
  },
)

const useStyles = makeStyles(theme =>
  createStyles({
    root: { margin: theme.spacing(0.75) },

    startIcon: {
      marginLeft: -1,
      marginRight: theme.spacing(2),
    },
    iconSizeMedium: {
      '& > *:first-of-type': { fontSize: 24 },
    },
  }),
)

export default function AddItem({
  multiple,
  value,
  onChange,
  AddButtonProps,
  AddDialogProps,
  disabled = false,
}: AddItemProps) {
  const classes = useStyles({})

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const [toAdd, setToAdd] = useState('')
  const handleAdd = () => {
    if (multiple) {
      onChange(
        {} as any,
        [
          ...((Array.isArray(value) ? value : []) as any),
          { value: toAdd, label: toAdd },
        ] as any,
        'selectOption',
      )
    } else {
      onChange({} as any, { value: toAdd, label: toAdd } as any, 'selectOption')
    }

    setToAdd('')
    setTimeout(() => setOpen(false))
  }

  return (
    <>
      <Button
        startIcon={multiple ? <AddBoxIcon /> : <AddCircleIcon />}
        classes={classes}
        onClick={() => setOpen(true)}
        disabled={disabled}
        {...AddButtonProps}
      >
        {'\u200b'}
        {AddButtonProps?.children || 'Add New'}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">
          {AddDialogProps?.title || 'Add Item to List'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            label={AddDialogProps?.textFieldLabel || 'New Item'}
            variant="filled"
            fullWidth={true}
            value={toAdd}
            onChange={e => setToAdd(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleAdd()
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}>
            {AddDialogProps?.cancelButtonLabel || 'Cancel'}
          </CancelButton>
          <ActionButton onClick={handleAdd}>
            {AddDialogProps?.addButtonLabel || 'Add'}
          </ActionButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
