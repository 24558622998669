import { FC } from 'react'

const envMap = {
  dev: 'Development',
  uat: 'Staging',
  local: 'Local',
}

interface Props {
  novaEnv: 'dev' | 'local' | 'uat'
}

export const NovaEnv: FC<Props> = ({ novaEnv }) => {
  return novaEnv in envMap ? (
    <div
      style={{
        background: '#e43 !important',
        width: '175px',
        top: '30px',
        position: 'absolute',
        left: '-40px',
        textAlign: 'center',
        lineHeight: '30px',
        letterSpacing: '1px',
        color: '#f0f0f0',
        transform: 'rotate(-45deg)',
        WebkitTransform: 'rotate(-45deg)',
        zIndex: 9999,
        fontSize: '16px',
      }}
    >{`${envMap[novaEnv]}`}</div>
  ) : null
}
