import React, { useEffect } from 'react'
import { max } from 'd3-array'
import { axisBottom, axisLeft } from 'd3-axis'
import { format } from 'd3-format'
import { ScaleBand, scaleBand, ScaleLinear, scaleLinear } from 'd3-scale'
import {
  BaseType,
  select as d3Select,
  selectAll,
  Selection,
} from 'd3-selection'
import { Transition } from 'd3-transition'
import { useChartData } from '../hooks/useChartData'
import { useSvgGraph } from '../hooks/useSvgGraph'
import { useChartColors } from '../hooks/useChartColors'
import { Margins, SIZE } from '../types'
import { UseDivSize } from '../hooks/useDivSize'
import { capitalize } from 'lodash'

// TODO @goughjo03 possibly pass these as props? might not be neccxessary now
const tooltipHeight = 50
const tooltipFontSizeTitle = 16
const tooltipFontSizeSubtitle = 12
const tooltipMarginX = 6
const tooltipMarginY = 14

export interface BarchartProps<DataPoint> {
  data: DataPoint[]
  divSize: UseDivSize
  height: number
  xAccessor: (dataPoint: DataPoint) => string
  yAccessor: (dataPoint: DataPoint) => number
  tooltipLabelAccessor: (datapoint: DataPoint) => string
  uniqueId: string
  // bounding box
  marginsLg?: Margins
  marginsMd?: Margins
  marginsSm?: Margins
  // Adornments
  xLabel?: string
  yLabel?: string
}

const BarChart = <DataPoint,>({
  data,
  divSize,
  height,
  uniqueId,
  xAccessor,
  yAccessor,
  tooltipLabelAccessor,
  marginsLg = {
    top: 20,
    bottom: 73,
    left: 80,
    right: 180,
  },
  marginsMd = {
    top: 20,
    bottom: 100,
    left: 80,
    right: 40,
  },
  marginsSm = {
    top: 20,
    bottom: 120,
    left: -1,
    right: 0,
  },
  xLabel = '',
  yLabel = '',
}: BarchartProps<DataPoint>) => {
  const barClassName = `datapoint-bar-${uniqueId}`
  const {
    axesColor,
    getColor,
    labelColor,
    lineColor,
    themeMode,
  } = useChartColors()
  const { data: dataForDisplay } = useChartData<DataPoint[]>({ data })

  const {
    contentGroup,
    chartHeight,
    chartWidth,
    svgRef,
    xAxisGroup,
    yAxisGroup,
  } = useSvgGraph({
    divSize,
    height,
    marginsLg,
    marginsMd,
    marginsSm,
    xLabel,
    yLabel,
  })

  const getLabelClassnameForDatapoint = ({
    dataPoint,
  }: {
    dataPoint: DataPoint
  }) => {
    const className = `rect-bar-for-x-label-${xAccessor(dataPoint)}-${uniqueId}`
    return className
  }
  useEffect(() => {
    handleDataUpdate()
  }, [dataForDisplay, xAxisGroup, yAxisGroup, contentGroup])

  const getXScale = () => {
    // This bars have a max width in the designs. we limit the size of the x-axis
    // Later we can extend a line across the xaxis to give same appearance as design
    return scaleBand()
      .range([0, Math.min(chartWidth, data.length * 140)])
      .padding(0.25)
      .domain(data.map(xAccessor))
  }

  const getYScasle = () => {
    return scaleLinear()
      .range([chartHeight, 0])
      .domain([0, Math.max(max(data, yAccessor) as number, 1)])
  }

  const appendXAxis = (
    transitionDuration: number,
    xScale: ScaleBand<string>,
  ) => {
    if (!xAxisGroup) {
      return
    }
    const { size } = divSize
    xAxisGroup
      .transition()
      .duration(transitionDuration)
      .call(
        axisBottom(xScale)
          .tickPadding(16)
          .ticks(chartWidth / 80)
          .tickSize(0),
      )
      .selectAll('text')
      .attr('text-anchor', divSize.size === SIZE.large ? 'middle' : 'start')
      .attr('fill', labelColor)

    xAxisGroup.selectAll('line').style('stroke', axesColor)

    xAxisGroup
      .selectAll('text')
      .attr('font-size', () => {
        switch (size) {
          case SIZE.large:
            return 16
          case SIZE.medium:
            return 14
          case SIZE.small:
            return 12
        }
      })
      .attr(
        'transform',
        divSize.size === SIZE.large ? '' : 'translate(20,10) rotate(90)',
      )
    // This bars have a max width in the designs. we limit the size of the x-axis
    // so we can extend a line across the xaxis to give same appearance as design
    const extendXAxisLineClass = 'extend-x-axis-line-class'
    xAxisGroup.select('.' + extendXAxisLineClass).remove()
    xAxisGroup
      .append('line')
      .attr('class', extendXAxisLineClass)
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', 0.5)
      .attr('y2', 0.5)
      .style('stroke', axesColor)
      .style('stroke-width', 1)
  }

  const appendYAxis = (
    transitionDuration: number,
    yScale: ScaleLinear<number, number, never>,
  ) => {
    if (!yAxisGroup) {
      return
    }
    yAxisGroup
      .transition()
      .duration(transitionDuration)
      .call(
        axisLeft(yScale).tickFormat(val => format(val < 1 ? '' : '.2s')(val)),
      )
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('x2', chartWidth)
          .attr('stroke-opacity', 0.1),
      )

    yAxisGroup.selectAll('text').attr('fill', labelColor)

    yAxisGroup.selectAll('.domain').style('stroke', axesColor)
  }

  const updateBarsWithData = (
    existingElements: Selection<
      SVGRectElement,
      DataPoint,
      SVGGElement,
      unknown
    >,
    transitionDuration: number,
    xScale: ScaleBand<string>,
    yScale: ScaleLinear<number, number, never>,
  ) => {
    existingElements
      .enter()
      .append('rect')
      .attr('class', barClassName)
      .attr('y', chartHeight)
      .attr('x', d => xScale(xAccessor(d)) as number)
      .attr('width', 0)
      .attr('height', 0)
      .merge(existingElements)
      .transition()
      .duration(transitionDuration)
      .attr('fill', (_d, i) => getColor(i))
      .attr('x', d => xScale(xAccessor(d)) as number)
      .attr('y', d => yScale(yAccessor(d)))
      .attr('width', xScale.bandwidth())
      .attr('height', d => chartHeight - yScale(yAccessor(d)))
      .call(selection => appendTooltipsToBars(selection, xScale, yScale))
    existingElements
      .exit()
      .transition()
      .duration(transitionDuration)
      .attr('y', chartHeight)
      .attr('height', 0)
      .attr('width', 0)
      .remove()
  }

  const appendTooltipsToBars = (
    selection: Transition<SVGRectElement, DataPoint, SVGGElement, unknown>,
    xScale: ScaleBand<string>,
    yScale: ScaleLinear<number, number, never>,
  ) => {
    if (!contentGroup) {
      return
    }
    selection.each((dataPoint: DataPoint) => {
      const className = getLabelClassnameForDatapoint({ dataPoint })
      d3Select<SVGElement, unknown>(svgRef.current)
        .selectAll(`.${className}`)
        .remove()
      const barHeight = chartHeight - yScale(yAccessor(dataPoint))
      const totalTooltipHoriztonalSpace = tooltipHeight + tooltipMarginY * 2
      const noRoom: boolean = totalTooltipHoriztonalSpace > barHeight
      const yBaseline = noRoom
        ? yScale(yAccessor(dataPoint)) +
          tooltipMarginY -
          totalTooltipHoriztonalSpace
        : yScale(yAccessor(dataPoint)) + tooltipMarginY
      contentGroup
        .append('rect')
        .attr('width', xScale.bandwidth() - tooltipMarginX * 2)
        .attr('height', tooltipHeight)
        .attr('fill', themeMode === 'light' ? '#ffffff' : '#000000')
        .attr('class', className)
        .style('opacity', 0)
        .attr('pointer-events', 'none')
        .attr('rx', 8)
        .attr('x', (xScale(xAccessor(dataPoint)) as number) + tooltipMarginX)
        .attr('y', yBaseline)
        .attr('filter', 'url(#shadow)')
      contentGroup
        .append('text')
        .text(yAccessor(dataPoint))
        .attr('pointer-events', 'none')
        .attr(
          'x',
          (xScale(xAccessor(dataPoint)) as number) + xScale.bandwidth() / 2,
        )
        .attr('y', yBaseline + tooltipFontSizeTitle)
        .attr('class', className)
        .attr('fill', lineColor)
        .style('opacity', 0)
        .style('font-size', tooltipFontSizeTitle)
        .style('font-weight', 'bolder')
        .attr('text-anchor', 'middle')
      contentGroup
        .append('text')
        .text(capitalize(tooltipLabelAccessor(dataPoint)))
        .attr('pointer-events', 'none')
        .attr(
          'x',
          (xScale(xAccessor(dataPoint)) as number) + xScale.bandwidth() / 2,
        )
        .attr(
          'y',
          yBaseline +
            tooltipFontSizeTitle +
            tooltipFontSizeSubtitle +
            8 /* 8 px padding between texts */,
        )
        .attr('class', className)
        .attr('fill', labelColor)
        .style('font-size', '12px')
        .style('opacity', 0)
        .attr('text-anchor', 'middle')
    })
  }

  const appendMouseEventListeners = () => {
    selectAll<SVGRectElement, DataPoint>(`.${barClassName}`)
      .on('mouseover', (_event: MouseEvent, dataPoint: DataPoint) => {
        const className = getLabelClassnameForDatapoint({ dataPoint })
        d3Select(svgRef.current)
          .selectAll(`.${className}`)
          .each((_datum, _index, groups) => {
            ;(groups as BaseType[]).forEach?.(el =>
              d3Select(el).style('opacity', 0.9),
            )
          })
      })
      .on('mousemove', (_event: MouseEvent, dataPoint: DataPoint) => {
        const className = getLabelClassnameForDatapoint({ dataPoint })
        d3Select(svgRef.current)
          .selectAll(`.${className}`)
          .each((_datum, _index, groups) => {
            ;(groups as BaseType[]).forEach?.(el =>
              d3Select(el).style('opacity', 0.9),
            )
          })
      })
      .on('mouseout', (_event: MouseEvent, dataPoint: DataPoint) => {
        const className = getLabelClassnameForDatapoint({ dataPoint })
        d3Select(svgRef.current)
          .selectAll(`.${className}`)
          .each((_datum, _index, groups) => {
            ;(groups as BaseType[]).forEach?.(el =>
              d3Select(el).style('opacity', 0),
            )
          })
      })
  }

  const handleDataUpdate = () => {
    if (!xAxisGroup || !yAxisGroup || !contentGroup) {
      return
    }

    dataForDisplay.sort((prev, curr) => {
      if (xAccessor(prev) < xAccessor(curr)) {
        return -1
      }
      if (xAccessor(prev) > xAccessor(curr)) {
        return 1
      }
      return 0
    })

    var u = contentGroup
      .selectAll<SVGRectElement, DataPoint>(`.${barClassName}`)
      .data<DataPoint>(dataForDisplay, xAccessor)

    const isFirstRender = u.size() < 1
    const transitionDuration = isFirstRender ? 0 : 1000

    const xScale = getXScale()
    const yScale = getYScasle()

    updateBarsWithData(u, transitionDuration, xScale, yScale)

    appendXAxis(transitionDuration, xScale)

    appendYAxis(transitionDuration, yScale)

    appendMouseEventListeners()
  }

  return (
    <div data-testid="data-viz-bar-chart">
      <svg ref={svgRef}></svg>
    </div>
  )
}

export default BarChart
