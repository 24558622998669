// this ensures various parts of next.js are imported and "used" somewhere so that they wont be tree shaken out
import '@module-federation/nextjs-mf/src/include-defaults'

import { AppProvider, OAuthProvider } from '@novafuturltd/core'
import App, { AppContext, AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from 'react-query'
import client from '../api/client'
import SDK from '../components/Integrations/sdk'
import { NovaEnv } from '../components/NovaEnv/NovaEnv'
import '../components/Roles/Permissions/permissionStyles.css'
import { elevioAccountId, novaEnv } from '../config/index'

import '../components/Dashboard/dragAndDropStyles.css'
import '../shared/v2/jsondiff/css/jsondiff.css'

import { CacheProvider, EmotionCache } from '@emotion/react'
import { createEmotionCache, NovaThemeProvider } from '@novafuturltd/core'
import { exchangeAuthCodeForToken, logout } from '../api/auth/oauth'
import getUserProfile from '../api/auth/user'
import { Elevio } from '../components/Elevio'
import { OrgSettingsProvider } from '../containers/OrganisationSettings/context'
import { UserSettingsProvider } from '../containers/UserSettings/context'
import CurrencyPreferenceContextProvider from '../contexts/CurrencyPreferenceContext'
import { NovaLanguageProvider } from '../contexts/i18n'
import { MixpanelCtxProvider } from '../contexts/mixpanel'
import { FeaturesProvider, FeatureToggle } from '../contexts/toggle-feature'
import { setUser } from '../modules/sentry-integration'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const queryClient = new QueryClient()

export interface MyAppProps extends AppProps {
  serverCookie?: string
  emotionCache?: EmotionCache
}

class CustomApp extends App<MyAppProps> {
  static async getInitialProps({ Component, ctx }: AppContext) {
    const serverCookie = ctx.req?.headers.cookie
    return {
      pageProps: {
        ...(Component.getInitialProps
          ? await Component.getInitialProps(ctx)
          : {}),
      },
      serverCookie,
    }
  }

  render() {
    const {
      Component,
      emotionCache = clientSideEmotionCache,
      pageProps,
      serverCookie,
    } = this.props
    return (
      <CacheProvider value={emotionCache}>
        <AppProvider>
          <QueryClientProvider client={queryClient}>
            <OAuthProvider
              serverCookie={serverCookie}
              client={client}
              exchangeAuthCodeForToken={exchangeAuthCodeForToken}
              getUserProfile={getUserProfile}
              logout={logout}
              setSentryUser={setUser}
            >
              <FeaturesProvider>
                <OrgSettingsProvider>
                  <UserSettingsProvider>
                    <FeatureToggle.On name="elevio">
                      <Elevio accountId={elevioAccountId || ''} />
                    </FeatureToggle.On>
                    <FeatureToggle.Off name="env-banner">
                      <NovaEnv novaEnv={novaEnv} />
                    </FeatureToggle.Off>
                    <MixpanelCtxProvider>
                      <NovaThemeProvider>
                        <NovaLanguageProvider>
                          <CurrencyPreferenceContextProvider>
                            <Component {...pageProps} />
                          </CurrencyPreferenceContextProvider>
                        </NovaLanguageProvider>
                      </NovaThemeProvider>
                    </MixpanelCtxProvider>
                    <SDK />
                  </UserSettingsProvider>
                </OrgSettingsProvider>
              </FeaturesProvider>
            </OAuthProvider>
          </QueryClientProvider>
        </AppProvider>
      </CacheProvider>
    )
  }
}

export default CustomApp
