import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const RecommendedIcon: FC = () => {
  return (
    <SvgIcon>
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2ZM12 12.5L15.58 14.8L14.49 10.69L17.78 7.99999L13.54 7.74999L12 3.79999L10.45 7.73999L6.20996 7.98999L9.49996 10.68L8.41996 14.8L12 12.5Z"
        fill="#FFFFFF"
      />
    </SvgIcon>
  )
}
