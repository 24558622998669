import { AxiosResponse } from 'axios'
import { createContext, FC, useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOAuth } from '@novafuturltd/core'
import * as api from '../api'
import Repository, { RepositoryInterface } from '../repository/feature'
import { defaultStrategies } from '../strategies'
import { Strategy } from '../strategies/abstract.strategy'
import { Context, IFeature, IProviderProps, TFeaturesResponse } from '../types'

interface IFeaturesContext {
  data: RepositoryInterface
  context: Context
  loading: boolean
  error?: any
  params?: any
}

export const FeaturesContext = createContext<IFeaturesContext>({
  data: new Repository({
    data: [],
  }),
  loading: false,
  context: {
    userId: '',
    remoteAddress: '',
  },
})

const someRepo = new Repository({
  data: [],
})

export const FeaturesProvider: FC<IProviderProps> = ({
  children,
  intervalMs = 5000,
}) => {
  const { profile: { idUserAccount: userId } = { id: undefined } } = useOAuth()
  const [changeTrigger, setChageTrigger] = useState<boolean>(false)
  const triggerChange = () => setChageTrigger(prevState => !prevState)
  const context: Context = { userId }
  const {
    isLoading: featuresLoading,
    // @ts-ignore
  } = useQuery('ClientsFeatures', () => api.getAll(), {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['data', 'error'],
    retry: 3,
    refetchInterval: intervalMs,
    onError: () => {
      // console.error(e)
    },
    onSuccess: (response: AxiosResponse<TFeaturesResponse<IFeature[]>>) => {
      const wasUpdated = someRepo.updateData(response.data.features)
      if (wasUpdated) {
        triggerChange()
      }
    },
  })

  return useMemo(() => {
    return (
      <FeaturesContext.Provider
        value={{
          data: someRepo,
          loading: featuresLoading,
          context,
        }}
      >
        {children}
      </FeaturesContext.Provider>
    )
  }, [children, changeTrigger, context.userId, intervalMs])
}

export const isEnabled = (name: string, context?: Context): boolean => {
  const { context: providerContext, data: repository } = useContext(
    FeaturesContext,
  )
  const enhancedContext = { ...providerContext, ...context }
  // find feature by name
  // get all strategies

  const feature = repository.getToggle(name)

  if (!feature || !feature.enabled) {
    return false
  }
  return isFeatureEnabled(feature, enhancedContext)
}

const getStrategy = (name: string): Strategy | undefined =>
  defaultStrategies.find(
    (strategy: Strategy): boolean => strategy.name === name,
  )

const isFeatureEnabled = (feature: IFeature, context: Context): boolean => {
  if (!Array.isArray(feature.strategies)) {
    throw new Error(
      `Malformed feature, strategies not an array, is a ${typeof feature.strategies}`,
    )
  }

  if (feature.strategies.length === 0) {
    return feature.enabled
  }

  return (
    feature.strategies.length > 0 &&
    feature.strategies.some((strategySelector): boolean => {
      const strategy = getStrategy(strategySelector.name)
      if (!strategy) {
        // console.log(strategySelector.name, feature.name, feature.strategies)
        return false
      }
      return strategy.isEnabled(strategySelector.parameters, context)
    })
  )
}

export const findFeatureStrategy = (name: string, strategyName: string) => {
  const { data: repository } = useContext(FeaturesContext)

  const feature = repository.getToggle(name)

  const strategies = feature?.strategies

  if (strategies?.length === 0 && strategyName === 'default') {
    return true
  }
  if (strategies?.find(strategy => strategy.name === strategyName)) {
    return true
  }
  return false
}
