import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'
// @ts-ignore

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    BoxWrapper: {
      width: '100%',
      height: '100%',
      padding: '20px',
      backgroundColor: '#ffffff',
      border: `1px solid ${theme.palette.info.main}`,
      margin: '0 0 30px',
      '&:last-child': {
        marginBottom: '0',
      },
      '@media only screen and (max-width: 767px)': {
        padding: '20px',
        margin: '0 10px 30px',
      },
      '&.half': {
        width: 'calc(50% - 34px)',
        '@media (max-width: 767px)': {
          width: '100%',
        },
      },
    },
  }),
)

export const BoxWrapper: FC = ({children }) => {
  const classes = useStyles({})
  return <div className={classes.BoxWrapper}>{children}</div>
}

// export { BoxWrapper }
