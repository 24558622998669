import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: NovaColours.S000[theme.palette.mode],
      display: 'flex',
      minHeight: 'calc(100vh - 65px)',
    },
  }),
)

export const AppContainer: FunctionComponent = ({ children }) => {
  const classes = useStyles({})
  return (
    <div data-testid="app-container" className={classes.root}>
      {children}
    </div>
  )
}
