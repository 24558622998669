import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      //   width: '100%',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'stretch',
      overflow: 'hidden',
      height: '110px',
      backgroundColor: (props: {
        bgColor: keyof typeof NovaColours.WidgetColours
      }) =>
        props.bgColor
          ? NovaColours.WidgetColours[props.bgColor][theme.palette.mode]
          : NovaColours.WidgetColours.SB050[theme.palette.mode],
      color: NovaColours.ST100[theme.palette.mode],
      cursor: (props: any) =>
        props.isClickable === false ? 'default' : 'pointer',
    },
    iconWrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      flexShrink: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    contentWrapper: {
      position: 'relative',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      padding: '20px 15px 20px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    mainText: {
      fontWeight: 500,
      lineHeight: '1.1',
      margin: '0 0 5px',
      fontSize: '24px',
    },
    subText: {
      fontWeight: 400,
      margin: 0,
      lineHeight: 1.2,
    },
    icon: {
      fill: NovaColours.N010.light,
    },
    deleteButton: {
      position: 'fixed',
      top: 0,
      right: -2,
    },
  }),
)
