import { SvgIcon, SvgIconProps } from '@mui/material'

export const EUFlagIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_816_35"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="24"
        height="18"
      >
        <path
          d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_816_35)">
        <path
          d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
          fill="#41479B"
        />
        {/* <path
          d="M2.28571 3.5H21.7143C22.6778 3.5 23.5 4.32739 23.5 5.4V18.6C23.5 19.6726 22.6778 20.5 21.7143 20.5H2.28571C1.3222 20.5 0.5 19.6726 0.5 18.6V5.4C0.5 4.32739 1.3222 3.5 2.28571 3.5Z"
          stroke="black"
          stroke-opacity="0.1"
        /> */}
        <path
          d="M11.9552 6.09085C11.9735 6.05369 12.0265 6.05369 12.0448 6.09085L12.341 6.69102C12.3483 6.70577 12.3624 6.716 12.3787 6.71837L13.041 6.81461C13.082 6.82057 13.0984 6.87097 13.0687 6.8999L12.5895 7.36706C12.5777 7.37855 12.5723 7.3951 12.5751 7.41132L12.6882 8.07097C12.6952 8.11181 12.6524 8.14296 12.6157 8.12368L12.0233 7.81223C12.0087 7.80457 11.9913 7.80457 11.9767 7.81223L11.3843 8.12368C11.3477 8.14296 11.3048 8.11181 11.3118 8.07097L11.4249 7.41132C11.4277 7.3951 11.4223 7.37855 11.4105 7.36706L10.9313 6.8999C10.9016 6.87097 10.918 6.82057 10.959 6.81461L11.6213 6.71837C11.6376 6.716 11.6517 6.70577 11.659 6.69102L11.9552 6.09085Z"
          fill="#FFD018"
        />
        <path
          d="M11.9552 15.6909C11.9735 15.6538 12.0265 15.6538 12.0448 15.6909L12.341 16.2911C12.3483 16.3059 12.3624 16.3161 12.3787 16.3185L13.041 16.4147C13.082 16.4207 13.0984 16.4711 13.0687 16.5L12.5895 16.9672C12.5777 16.9786 12.5723 16.9952 12.5751 17.0114L12.6882 17.6711C12.6952 17.7119 12.6524 17.7431 12.6157 17.7238L12.0233 17.4123C12.0087 17.4047 11.9913 17.4047 11.9767 17.4123L11.3843 17.7238C11.3477 17.7431 11.3048 17.7119 11.3118 17.6711L11.4249 17.0114C11.4277 16.9952 11.4223 16.9786 11.4105 16.9672L10.9313 16.5C10.9016 16.4711 10.918 16.4207 10.959 16.4147L11.6213 16.3185C11.6376 16.3161 11.6517 16.3059 11.659 16.2911L11.9552 15.6909Z"
          fill="#FFD018"
        />
        <path
          d="M7.15516 10.8909C7.1735 10.8537 7.2265 10.8537 7.24484 10.8909L7.54104 11.4911C7.54832 11.5058 7.5624 11.5161 7.57868 11.5184L8.24101 11.6147C8.28202 11.6206 8.2984 11.671 8.26872 11.6999L7.78946 12.1671C7.77767 12.1786 7.7723 12.1951 7.77508 12.2114L7.88822 12.871C7.89522 12.9119 7.85235 12.943 7.81567 12.9237L7.22327 12.6123C7.2087 12.6046 7.1913 12.6046 7.17673 12.6123L6.58433 12.9237C6.54765 12.943 6.50478 12.9119 6.51178 12.871L6.62492 12.2114C6.6277 12.1951 6.62233 12.1786 6.61054 12.1671L6.13128 11.6999C6.1016 11.671 6.11798 11.6206 6.15899 11.6147L6.82132 11.5184C6.8376 11.5161 6.85168 11.5058 6.85896 11.4911L7.15516 10.8909Z"
          fill="#FFD018"
        />
        <path
          d="M16.7552 10.8909C16.7735 10.8537 16.8265 10.8537 16.8448 10.8909L17.141 11.4911C17.1483 11.5058 17.1624 11.5161 17.1787 11.5184L17.841 11.6147C17.882 11.6206 17.8984 11.671 17.8687 11.6999L17.3895 12.1671C17.3777 12.1786 17.3723 12.1951 17.3751 12.2114L17.4882 12.871C17.4952 12.9119 17.4524 12.943 17.4157 12.9237L16.8233 12.6123C16.8087 12.6046 16.7913 12.6046 16.7767 12.6123L16.1843 12.9237C16.1477 12.943 16.1048 12.9119 16.1118 12.871L16.2249 12.2114C16.2277 12.1951 16.2223 12.1786 16.2105 12.1671L15.7313 11.6999C15.7016 11.671 15.718 11.6206 15.759 11.6147L16.4213 11.5184C16.4376 11.5161 16.4517 11.5058 16.459 11.4911L16.7552 10.8909Z"
          fill="#FFD018"
        />
        <path
          d="M14.3552 6.73392C14.3735 6.69675 14.4265 6.69675 14.4448 6.73392L14.741 7.33408C14.7483 7.34884 14.7624 7.35907 14.7787 7.36144L15.441 7.45768C15.482 7.46364 15.4984 7.51404 15.4687 7.54296L14.9895 8.01013C14.9777 8.02162 14.9723 8.03817 14.9751 8.05438L15.0882 8.71403C15.0952 8.75488 15.0523 8.78603 15.0157 8.76674L14.4233 8.4553C14.4087 8.44764 14.3913 8.44764 14.3767 8.4553L13.7843 8.76674C13.7476 8.78603 13.7048 8.75488 13.7118 8.71403L13.8249 8.05438C13.8277 8.03817 13.8223 8.02162 13.8105 8.01013L13.3313 7.54296C13.3016 7.51404 13.318 7.46364 13.359 7.45768L14.0213 7.36144C14.0376 7.35907 14.0517 7.34884 14.059 7.33408L14.3552 6.73392Z"
          fill="#FFD018"
        />
        <path
          d="M9.55516 15.0479C9.5735 15.0107 9.62649 15.0107 9.64483 15.0479L9.94103 15.648C9.94831 15.6628 9.96239 15.673 9.97868 15.6754L10.641 15.7716C10.682 15.7776 10.6984 15.828 10.6687 15.8569L10.1895 16.3241C10.1777 16.3356 10.1723 16.3521 10.1751 16.3683L10.2882 17.028C10.2952 17.0688 10.2523 17.1 10.2157 17.0807L9.62326 16.7693C9.60869 16.7616 9.59129 16.7616 9.57673 16.7693L8.98433 17.0807C8.94764 17.1 8.90477 17.0688 8.91178 17.028L9.02492 16.3683C9.0277 16.3521 9.02232 16.3356 9.01054 16.3241L8.53127 15.8569C8.5016 15.828 8.51797 15.7776 8.55898 15.7716L9.22131 15.6754C9.23759 15.673 9.25167 15.6628 9.25896 15.648L9.55516 15.0479Z"
          fill="#FFD018"
        />
        <path
          d="M7.79824 8.49075C7.81659 8.45359 7.86958 8.45359 7.88792 8.49075L8.18412 9.09092C8.1914 9.10568 8.20548 9.11591 8.22177 9.11827L8.88409 9.21451C8.9251 9.22047 8.94148 9.27087 8.9118 9.2998L8.43254 9.76696C8.42075 9.77845 8.41538 9.795 8.41816 9.81122L8.5313 10.4709C8.5383 10.5117 8.49543 10.5429 8.45875 10.5236L7.86635 10.2121C7.85178 10.2045 7.83438 10.2045 7.81981 10.2121L7.22741 10.5236C7.19073 10.5429 7.14786 10.5117 7.15487 10.4709L7.268 9.81122C7.27079 9.795 7.26541 9.77845 7.25362 9.76696L6.77436 9.2998C6.74469 9.27087 6.76106 9.22047 6.80207 9.21451L7.4644 9.11827C7.48068 9.11591 7.49476 9.10568 7.50204 9.09092L7.79824 8.49075Z"
          fill="#FFD018"
        />
        <path
          d="M16.1121 13.2908C16.1304 13.2536 16.1834 13.2536 16.2018 13.2908L16.498 13.891C16.5053 13.9057 16.5193 13.916 16.5356 13.9183L17.1979 14.0146C17.239 14.0205 17.2553 14.0709 17.2257 14.0998L16.7464 14.567C16.7346 14.5785 16.7292 14.5951 16.732 14.6113L16.8452 15.2709C16.8522 15.3118 16.8093 15.3429 16.7726 15.3236L16.1802 15.0122C16.1656 15.0045 16.1482 15.0045 16.1337 15.0122L15.5413 15.3236C15.5046 15.3429 15.4617 15.3118 15.4687 15.2709L15.5819 14.6113C15.5846 14.5951 15.5793 14.5785 15.5675 14.567L15.0882 14.0998C15.0585 14.0709 15.0749 14.0205 15.1159 14.0146L15.7783 13.9183C15.7945 13.916 15.8086 13.9057 15.8159 13.891L16.1121 13.2908Z"
          fill="#FFD018"
        />
        <path
          d="M16.1121 8.49075C16.1304 8.45359 16.1834 8.45359 16.2018 8.49075L16.498 9.09092C16.5053 9.10568 16.5193 9.11591 16.5356 9.11827L17.1979 9.21451C17.239 9.22047 17.2553 9.27087 17.2257 9.2998L16.7464 9.76696C16.7346 9.77845 16.7292 9.795 16.732 9.81122L16.8452 10.4709C16.8522 10.5117 16.8093 10.5429 16.7726 10.5236L16.1802 10.2121C16.1656 10.2045 16.1482 10.2045 16.1337 10.2121L15.5413 10.5236C15.5046 10.5429 15.4617 10.5117 15.4687 10.4709L15.5819 9.81122C15.5846 9.795 15.5793 9.77845 15.5675 9.76696L15.0882 9.2998C15.0585 9.27087 15.0749 9.22047 15.1159 9.21451L15.7783 9.11827C15.7945 9.11591 15.8086 9.10568 15.8159 9.09092L16.1121 8.49075Z"
          fill="#FFD018"
        />
        <path
          d="M7.79824 13.2908C7.81659 13.2536 7.86958 13.2536 7.88792 13.2908L8.18412 13.891C8.1914 13.9057 8.20548 13.916 8.22177 13.9183L8.88409 14.0146C8.9251 14.0205 8.94148 14.0709 8.9118 14.0998L8.43254 14.567C8.42075 14.5785 8.41538 14.5951 8.41816 14.6113L8.5313 15.2709C8.5383 15.3118 8.49543 15.3429 8.45875 15.3236L7.86635 15.0122C7.85178 15.0045 7.83438 15.0045 7.81981 15.0122L7.22741 15.3236C7.19073 15.3429 7.14786 15.3118 7.15487 15.2709L7.268 14.6113C7.27079 14.5951 7.26541 14.5785 7.25362 14.567L6.77436 14.0998C6.74469 14.0709 6.76106 14.0205 6.80207 14.0146L7.4644 13.9183C7.48068 13.916 7.49476 13.9057 7.50204 13.891L7.79824 13.2908Z"
          fill="#FFD018"
        />
        <path
          d="M9.55516 6.73392C9.5735 6.69675 9.62649 6.69675 9.64483 6.73392L9.94103 7.33408C9.94831 7.34884 9.96239 7.35907 9.97868 7.36144L10.641 7.45768C10.682 7.46364 10.6984 7.51404 10.6687 7.54296L10.1895 8.01013C10.1777 8.02162 10.1723 8.03817 10.1751 8.05438L10.2882 8.71403C10.2952 8.75488 10.2523 8.78603 10.2157 8.76674L9.62326 8.4553C9.60869 8.44764 9.59129 8.44764 9.57673 8.4553L8.98433 8.76674C8.94764 8.78603 8.90477 8.75488 8.91178 8.71403L9.02492 8.05438C9.0277 8.03817 9.02232 8.02162 9.01054 8.01013L8.53127 7.54296C8.5016 7.51404 8.51797 7.46364 8.55898 7.45768L9.22131 7.36144C9.23759 7.35907 9.25167 7.34884 9.25896 7.33408L9.55516 6.73392Z"
          fill="#FFD018"
        />
        <path
          d="M14.3552 15.0479C14.3735 15.0107 14.4265 15.0107 14.4448 15.0479L14.741 15.648C14.7483 15.6628 14.7624 15.673 14.7787 15.6754L15.441 15.7716C15.482 15.7776 15.4984 15.828 15.4687 15.8569L14.9895 16.3241C14.9777 16.3356 14.9723 16.3521 14.9751 16.3683L15.0882 17.028C15.0952 17.0688 15.0523 17.1 15.0157 17.0807L14.4233 16.7693C14.4087 16.7616 14.3913 16.7616 14.3767 16.7693L13.7843 17.0807C13.7476 17.1 13.7048 17.0688 13.7118 17.028L13.8249 16.3683C13.8277 16.3521 13.8223 16.3356 13.8105 16.3241L13.3313 15.8569C13.3016 15.828 13.318 15.7776 13.359 15.7716L14.0213 15.6754C14.0376 15.673 14.0517 15.6628 14.059 15.648L14.3552 15.0479Z"
          fill="#FFD018"
        />
      </g>
    </SvgIcon>
  )
}
