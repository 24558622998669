import React, { useState } from 'react'
import { bisectCenter, extent, map, max, min } from 'd3-array'
import { axisBottom, axisLeft } from 'd3-axis'
import { ScaleLinear, scaleLinear, ScaleTime, scaleUtc } from 'd3-scale'
import { pointer, select, Selection } from 'd3-selection'
import { curveLinear, line as d3Line } from 'd3-shape'
import { useEffectIgnoreFirstRender } from '../../hooks/useEffectIgnoreFirstRender'
import { useSvgGraph } from '../hooks/useSvgGraph'
import { useChartData } from '../hooks/useChartData'
import { useChartColors } from '../hooks/useChartColors'
import { Margins, SIZE } from '../types'
import { format } from 'd3-format'
import { UseDivSize } from '../hooks/useDivSize'
import { capitalize } from 'lodash'

export type DataForLine<DataPoint> = {
  label: string
  data: DataPoint[]
  color: string
}
export type LineData = {
  label: string
  d: string
  color: string
}
export type DataForPoint<DataPoint> = {
  label: string
  point: DataPoint
  color: string
}
export type SeperatedData<DataPoint> = Array<DataForLine<DataPoint>>

const lengthOfRowsInLegendByScreenSize = {
  [SIZE.small]: 3,
  [SIZE.medium]: 4,
  // [SIZE.large]: 0, // N/A for now becuase in large screen the legend is vertically stacked to side of graph
} as const

export const seperateDataByLabel = <DataPoint,>({
  data,
  getColor,
  labelAccessor,
}: {
  getColor: (index: number) => string
  data: DataPoint[]
  labelAccessor: (dataPoint: DataPoint) => string
}): SeperatedData<DataPoint> => {
  const grouped: Array<Omit<DataForLine<DataPoint>, 'color'>> = []
  data.forEach((e: DataPoint, _i: number) => {
    const label = labelAccessor(e)
    // is there an existing entry for this label
    const existingEntry = grouped.find(
      ({ label: existingLabel }) => existingLabel === label,
    )
    if (!!existingEntry) {
      // get the existing array and update
      existingEntry.data.push(e)
    } else {
      grouped.push({
        data: [e],
        label,
      })
    }
  })
  grouped.sort((prev, curr) => {
    if (prev.label < curr.label) {
      return -1
    }
    if (prev.label > curr.label) {
      return 1
    }
    return 0
  })
  return grouped.map((e, i) => ({
    color: getColor(i),
    ...e,
  }))
}

export interface TimeSeriesChartProps<DataPoint> {
  data: DataPoint[]
  xAccessor: (point: DataPoint) => Date
  yAccessor: (point: DataPoint) => number
  height: number
  labelAccessor: (point: DataPoint) => string
  divSize: UseDivSize
  // bounding box
  marginsLg?: Margins
  marginsMd?: Margins
  marginsSm?: Margins
  // space between the lines and the edges of the chart area
  percentOfWithSpaceTop?: number
  percentOfWithSpaceBottom?: number
  percentOfWithSpaceLeft?: number
  percentOfWithSpaceRight?: number
  // adornments
  yLabel?: string // a label for the y-axis
  xLabel?: string // a label for the x-axis
  color?: string // stroke color of line
  strokeLinecap?: string // stroke line cap of the line
  strokeLinejoin?: string // stroke line join of the line
  strokeWidth?: number // stroke width of line, in pixels
  strokeOpacity?: number // stroke opacity of line
}

const TimeSeriesChart = <DataPoint,>({
  data,
  divSize,
  labelAccessor,
  xAccessor,
  yAccessor,
  height,
  marginsLg = {
    top: 30,
    bottom: 73,
    left: 80,
    right: 180,
  },
  marginsMd = {
    top: 80,
    bottom: 100,
    left: 80,
    right: 40,
  },
  marginsSm = {
    top: 80,
    bottom: 100,
    left: -1,
    right: 0,
  },
  percentOfWithSpaceTop = 0.12,
  percentOfWithSpaceBottom = 0,
  percentOfWithSpaceLeft = 0.02,
  percentOfWithSpaceRight = 0.005,
  // adornments
  yLabel = '', // a label for the y-axis
  xLabel = '', // a label for the x-axis
  strokeLinecap = 'round', // stroke line cap of the line
  strokeLinejoin = 'round', // stroke line join of the line
  strokeWidth = 2, // stroke width of line, in pixels
  strokeOpacity = 1, // stroke opacity of line
}: TimeSeriesChartProps<DataPoint>) => {
  const tooltipCircleClass = 'tooltip-circle'
  const tooltipLineClassName = 'the-vert-line'
  const tooltipRectClassName = 'the-tooltip-rect'
  const tooltipFadedWindowClassName = 'tooltip-faded-window'
  const tooltipTextClass = 'tooltip-text'
  const tooltipLabelClass = 'tooltip-label'
  const tooltipDatetimeTextClass = 'tooltip-datetime-text'
  const tooltipWidth = 120 // TODO @goughjo03 consider making this dynamic
  const tooltipMarginX = 8
  const tooltipMarginY = 8
  const tooltipTextSize = 16 // TODO @goughjo03 consider making this dynamic
  const tooltipLabelTextSize = 12
  const tooltipDatetimeTextSize = 12
  const tooltipLabelLineHeight = 16
  const tooltipPointRadius = 10
  const {
    axesColor,
    backgroundColor,
    getColor,
    labelColor,
    lineColor,
    outlineColor,
    themeMode,
  } = useChartColors()
  const { data: dataForDisplay } = useChartData<DataPoint[]>({ data })
  const [availableDatetimes, setAvailableDatetimes] = useState<Date[]>([])
  const [separatedData, setSeparatedData] = useState<SeperatedData<DataPoint>>(
    [],
  )
  const [scales, setScales] = useState<{
    xScale: ScaleTime<number, number, never> | undefined
    yScale: ScaleLinear<number, number, never> | undefined
    xDomain: [undefined, undefined] | [Date, Date]
  }>({
    xScale: undefined,
    yScale: undefined,
    xDomain: [undefined, undefined],
  })

  // const numberOfRows = divSize.size === SIZE.large ? 0 : Math.ceil(separatedData.length / lengthOfRowsInLegendByScreenSize[divSize.size])

  const {
    contentGroup,
    chartHeight,
    chartWidth,
    margins,
    svgRef,
    xAxisGroup,
    yAxisGroup,
  } = useSvgGraph({
    divSize,
    height,
    hideXLabelSmallScreen: true,
    marginsLg,
    marginsMd,
    marginsSm,
    xLabel,
    yLabel,
  })
  useEffectIgnoreFirstRender(() => {
    handleDataForDisplayUpdate()
  }, [backgroundColor, dataForDisplay, xAxisGroup, yAxisGroup])

  useEffectIgnoreFirstRender(() => {
    handleNewSeparatedData()
  }, [availableDatetimes, separatedData])

  useEffectIgnoreFirstRender(() => {
    updateXAxis()
    updateYAxis()
    updatePaths()
    drawLegend()
    appendTooltip()
  }, [scales])

  const drawLegend = () => {
    if (!svgRef) {
      return
    }
    const classNameCircle = 'data-legend-circle'
    const classNameText = 'data-legend-text'
    const svg = select(svgRef.current)

    const circles: Selection<
      SVGCircleElement,
      DataForLine<DataPoint>,
      SVGElement,
      undefined
    > = svg
      .selectAll<SVGCircleElement, DataForLine<DataPoint>>(
        '.' + classNameCircle,
      )
      .data<DataForLine<DataPoint>>(separatedData)
    circles.exit().remove()
    circles
      .enter()
      .append('circle')
      .attr('class', classNameCircle)
      .merge(circles)
      .attr('cx', (_d: DataForLine<DataPoint>, i: number) => {
        switch (divSize.size) {
          case SIZE.large:
            return (divSize.width || 0) - margins.right + 70
          case SIZE.medium:
            return (
              16 + (i % lengthOfRowsInLegendByScreenSize[SIZE.medium]) * 120
            )
          case SIZE.small:
            return 16 + (i % lengthOfRowsInLegendByScreenSize[SIZE.small]) * 100
        }
      })
      .attr('cy', (_d: DataForLine<DataPoint>, i: number) => {
        switch (divSize.size) {
          case SIZE.large:
            return 33 * (i + 0.85)
          case SIZE.medium:
            return (
              19 +
              Math.floor(i / lengthOfRowsInLegendByScreenSize[SIZE.medium]) * 32
            )
          case SIZE.small:
            return (
              20 +
              Math.floor(i / lengthOfRowsInLegendByScreenSize[SIZE.small]) * 32
            )
        }
      })
      .attr('r', (_d: DataForLine<DataPoint>, _i: number) => {
        switch (divSize.size) {
          case SIZE.large:
            return 10
          case SIZE.medium:
            return 8
          case SIZE.small:
            return 8
        }
      })
      .style('fill', ({ color }) => color)

    const texts: Selection<
      SVGTextElement,
      DataForLine<DataPoint>,
      SVGElement,
      undefined
    > = svg
      .selectAll<SVGTextElement, DataForLine<DataPoint>>('.' + classNameText)
      .data<DataForLine<DataPoint>>(separatedData)
    texts.exit().remove()
    texts
      .enter()
      .append('text')
      .attr('class', classNameText)
      .merge(texts)
      .text(({ label }) => capitalize(label))
      .style('font-weight', 'bolder')
      .style('font-size', (_d: DataForLine<DataPoint>, _i: number) => {
        switch (divSize.size) {
          case SIZE.large:
            return '16px'
          case SIZE.medium:
            return '16px'
          case SIZE.small:
            return '12px'
        }
      })
      .attr('alignment-baseline', 'middle')
      .attr('x', (_d: DataForLine<DataPoint>, i: number) => {
        switch (divSize.size) {
          case SIZE.large:
            return (divSize.width || 0) - margins.right + 90
          case SIZE.medium:
            return (
              32 + (i % lengthOfRowsInLegendByScreenSize[SIZE.medium]) * 120
            )
          case SIZE.small:
            return 32 + (i % lengthOfRowsInLegendByScreenSize[SIZE.small]) * 100
        }
      })
      .attr('y', (_d: DataForLine<DataPoint>, i: number) => {
        switch (divSize.size) {
          case SIZE.large:
            return 33 * (i + 1)
          case SIZE.medium:
            return (
              24 +
              Math.floor(i / lengthOfRowsInLegendByScreenSize[SIZE.medium]) * 32
            )
          case SIZE.small:
            return (
              24 +
              Math.floor(i / lengthOfRowsInLegendByScreenSize[SIZE.small]) * 32
            )
        }
      })
      .style('fill', ({ color }) => color)
  }

  const removeTooltip = (
    tooltipRect: Selection<
      SVGRectElement,
      unknown,
      HTMLElement | null,
      undefined
    >,
    tooltipLabel: Selection<SVGTextElement, unknown, HTMLElement | null, any>,
    tooltipSlidingWindow: Selection<
      SVGRectElement,
      unknown,
      HTMLElement | null,
      any
    >,
    tooltipLine: Selection<SVGLineElement, unknown, HTMLElement | null, any>,
    tooltipDate: Selection<SVGTextElement, unknown, HTMLElement | null, any>,
    tooltipTime: Selection<SVGTextElement, unknown, HTMLElement | null, any>,
  ) => {
    tooltipLine.style('opacity', 0)
    tooltipLabel.style('opacity', 0)
    tooltipRect.style('opacity', 0)
    tooltipSlidingWindow.style('opacity', 0).style('width', 0)
    tooltipDate.style('opacity', 0).text('')
    tooltipTime.style('opacity', 0).text('')
    if (!contentGroup) {
      return
    }
    contentGroup.selectAll(`.${tooltipTextClass}`).remove()
    contentGroup.selectAll(`.${tooltipCircleClass}`).remove()
  }

  const drawTooltip = ({
    event,
    tooltipRect,
    tooltipLabel,
    tooltipLine,
    tooltipSlidingWindow,
    tooltipDate,
    tooltipTime,
  }: {
    event: PointerEvent
    tooltipRect: Selection<
      SVGRectElement,
      unknown,
      HTMLElement | null,
      undefined
    >
    tooltipLabel: Selection<SVGTextElement, unknown, HTMLElement | null, any>
    tooltipLine: Selection<SVGLineElement, unknown, HTMLElement | null, any>
    tooltipSlidingWindow: Selection<
      SVGRectElement,
      unknown,
      HTMLElement | null,
      any
    >
    tooltipDate: Selection<SVGTextElement, unknown, HTMLElement | null, any>
    tooltipTime: Selection<SVGTextElement, unknown, HTMLElement | null, any>
  }) => {
    // + (tooltipLabelsMarginsY * 3) + tooltipLabelTextSize + tooltipDatetimeTextSize + tooltipDatetimeTextSize
    const { xDomain, xScale, yScale } = scales
    if (!contentGroup || !xScale || !yScale || !xDomain[0]) {
      return
    }
    const [x] = pointer(event)
    const mouseDatetime = xScale.invert(x - margins.left)
    const closestDatetime =
      availableDatetimes[bisectCenter(availableDatetimes, mouseDatetime)]
    const labelAtDatetime = separatedData.filter(
      ({ data }: DataForLine<DataPoint>) => {
        return data.some((d: DataPoint) => {
          return xAccessor(d).getTime() === closestDatetime.getTime()
        })
      },
    )
    const incidentData = labelAtDatetime.map(
      ({ color, data, label }: DataForLine<DataPoint>) => {
        const index = data.findIndex(
          e => xAccessor(e).getTime() === closestDatetime.getTime(),
        )
        return {
          color,
          point: data[index],
          label,
        }
      },
    )

    const numberOfLabels = incidentData.length
    const tooltipHeight =
      numberOfLabels * tooltipTextSize +
      tooltipLabelLineHeight * 3 +
      tooltipMarginY * 2

    incidentData.sort(
      ({ point: pointA }, { point: pointB }) =>
        yAccessor(pointB) - yAccessor(pointA),
    )

    const xPos = xScale(closestDatetime)
    const tooltipWidth =
      (tooltipRect.node()?.getBBox().width || 0) + tooltipMarginX * 2
    const noSpaceRight = chartWidth - tooltipWidth < xPos
    const xPosTooltip = noSpaceRight
      ? xPos - tooltipWidth - tooltipPointRadius / 2 - tooltipMarginX
      : xPos + tooltipPointRadius / 2 + tooltipMarginX
    const closestYValues = incidentData.map(({ point }) => yAccessor(point))
    const [minYValue, maxYValue] = extent(closestYValues)
    const medianYValue = ((maxYValue || 0) + (minYValue || 0)) / 2
    const computedTooltipYPos = yScale(medianYValue) + tooltipHeight / 2
    const yPos = Math.min(
      chartHeight - tooltipMarginY,
      Math.max(tooltipHeight, computedTooltipYPos),
    )

    if (
      mouseDatetime.getTime() < xDomain[0].getTime() ||
      mouseDatetime.getTime() > xDomain[1].getTime()
    ) {
      removeTooltip(
        tooltipRect,
        tooltipLabel,
        tooltipSlidingWindow,
        tooltipLine,
        tooltipDate,
        tooltipTime,
      )
    } else {
      tooltipLine
        .style('opacity', 0.2)
        .attr('x1', xPos)
        .attr('x2', xPos)
        .attr('y1', 0)
        .attr('y2', chartHeight)
      tooltipRect
        .style('opacity', 1)
        .html(closestDatetime.toDateString())
        .attr(
          'transform',
          `translate(${xPosTooltip + tooltipMarginX},${yPos - tooltipHeight})`,
        )
      const tooltipPoints = contentGroup
        .selectAll<SVGCircleElement, DataForPoint<DataPoint>>(
          `.${tooltipCircleClass}`,
        )
        .data<DataForPoint<DataPoint>>(incidentData)
      tooltipPoints
        .enter()
        .append('circle')
        .attr('class', tooltipCircleClass)
        .attr('pointer-events', 'none')
        .attr('fill', backgroundColor)
        .merge(tooltipPoints)
        .attr('fill', backgroundColor)
        .attr('stroke', ({ color }) => color)
        .attr('stroke-width', strokeWidth)
        .attr('r', tooltipPointRadius)
        .attr('cx', ({ point }) => xScale(xAccessor(point).getTime()))
        .attr('cy', ({ point }) => yScale(yAccessor(point)))
      const tooltipTexts = contentGroup
        .selectAll<SVGTextElement, DataForPoint<DataPoint>>(
          `.${tooltipTextClass}`,
        )
        .data<DataForPoint<DataPoint>>(incidentData)
      tooltipTexts
        .enter()
        .append('text')
        .attr('class', tooltipTextClass)
        .attr('pointer-events', 'none')
        .attr('font-size', tooltipTextSize)
        .attr('font-weight', 'bolder')
        .merge(tooltipTexts)
        .style('fill', ({ color }) => color)
        .text(({ point }) => `${format('.2s')(yAccessor(point))}`)
        .each((_val, index, nodes) => {
          select(nodes[index])
            .attr('x', xPosTooltip + tooltipWidth / 2)
            .attr('y', yPos + tooltipTextSize - tooltipHeight + index * 16)
            .attr('text-anchor', 'middle')
        })
      tooltipLabel
        .style('opacity', 0.7)
        .attr('x', xPosTooltip + tooltipWidth / 2)
        .attr(
          'y',
          yPos -
            tooltipHeight +
            tooltipMarginY / 2 +
            (incidentData.length + 1) * tooltipLabelLineHeight,
        )
      tooltipDate
        .style('opacity', 0.7)
        .text(
          closestDatetime.getDate() +
            '/' +
            (closestDatetime.getMonth() + 1) +
            '/' +
            closestDatetime.getFullYear(),
        )
        .attr('x', xPosTooltip + tooltipWidth / 2)
        .attr(
          'y',
          yPos -
            tooltipHeight +
            tooltipMarginY / 2 +
            tooltipLabelLineHeight +
            (incidentData.length + 1) * tooltipTextSize,
        )
      tooltipTime
        .style('opacity', 0.7)
        .text(
          closestDatetime
            .getHours()
            .toString()
            .padStart(2, '0') +
            ':' +
            closestDatetime
              .getMinutes()
              .toString()
              .padStart(2, '0'),
        )
        .attr('x', xPosTooltip + tooltipWidth / 2)
        .attr(
          'y',
          yPos -
            tooltipHeight +
            tooltipMarginY / 2 +
            tooltipLabelLineHeight * 2 +
            (incidentData.length + 1) * tooltipTextSize,
        )
      tooltipSlidingWindow
        .style('opacity', 0.7)
        .attr('x', xPos)
        .attr('y', 0)
        .style('width', `${chartWidth - xPos}px`)
      const maxWidthContent = max([
        tooltipLabel.node()?.getBBox().width || 0,
        tooltipDate.node()?.getBBox().width || 0,
        tooltipTime.node()?.getBBox().width || 0,
        ...tooltipTexts.nodes().map(e => e.getBBox().width || 0),
      ])
      tooltipRect
        .style('width', `${(maxWidthContent || 0) + 16}px`)
        .style('height', tooltipHeight)
    }
  }

  const appendTooltip = () => {
    if (
      !divSize.ref?.current ||
      !contentGroup ||
      !svgRef.current ||
      !scales.xDomain[0]
    ) {
      return
    }
    const svg = select(svgRef.current)
    contentGroup.select('.' + tooltipLineClassName).remove()
    contentGroup.select('.' + tooltipRectClassName).remove()
    contentGroup.select('.' + tooltipFadedWindowClassName).remove()
    contentGroup.select('.' + tooltipCircleClass).remove()
    contentGroup.selectAll('.' + tooltipDatetimeTextClass).remove()
    contentGroup.select('.' + tooltipLabelClass).remove()
    const tooltipSlidingWindow = contentGroup
      .append('rect')
      .style('opacity', 0)
      .attr('fill', backgroundColor)
      .style('height', chartHeight)
      .attr('class', tooltipFadedWindowClassName)
    const tooltipLine = contentGroup
      .append('line')
      .attr('stroke', lineColor)
      .attr('stroke-width', 4)
      .attr('stroke-dasharray', '10 5')
      .attr('class', tooltipLineClassName)
    const tooltipRect = contentGroup
      .append('rect')
      .style('opacity', 0)
      .attr('fill', themeMode === 'light' ? '#ffffff' : '#000000')
      .attr('rx', '8px')
      .attr('ry', '8px')
      .attr('stroke', outlineColor)
      .attr('stroke-width', 1)
      .style('width', tooltipWidth)
      .attr('class', tooltipRectClassName)
      .attr('filter', 'url(#shadow)') // TODO @goughjo03 move this into seperate function because it is used in barchart as well.
    const tooltipLabel = contentGroup
      .append('text')
      .attr('class', tooltipLabelClass)
      .attr('pointer-events', 'none')
      .attr('font-size', tooltipLabelTextSize)
      .style('font-weight', 'normal')
      .style('opacity', 0)
      .style('fill', lineColor)
      .text(yLabel)
      .attr('text-anchor', 'middle')
    const tooltipDate = contentGroup
      .append('text')
      .attr('class', tooltipDatetimeTextClass)
      .attr('pointer-events', 'none')
      .attr('font-size', tooltipDatetimeTextSize)
      .style('font-weight', 'normal')
      .style('opacity', 0)
      .style('fill', lineColor)
      .attr('text-anchor', 'middle')
    const tooltipTime = contentGroup
      .append('text')
      .attr('class', tooltipDatetimeTextClass)
      .attr('pointer-events', 'none')
      .attr('font-size', tooltipDatetimeTextSize)
      .style('font-weight', 'normal')
      .style('opacity', 0)
      .style('fill', lineColor)
      .attr('text-anchor', 'middle')

    svg
      .on('pointermove', (event: PointerEvent) => {
        drawTooltip({
          event,
          tooltipRect,
          tooltipLabel,
          tooltipLine,
          tooltipSlidingWindow,
          tooltipDate,
          tooltipTime,
        })
      })
      .on('pointerup', () => {
        removeTooltip(
          tooltipRect,
          tooltipLabel,
          tooltipSlidingWindow,
          tooltipLine,
          tooltipDate,
          tooltipTime,
        )
      })
      .on('mouseout', () => {
        removeTooltip(
          tooltipRect,
          tooltipLabel,
          tooltipSlidingWindow,
          tooltipLine,
          tooltipDate,
          tooltipTime,
        )
      })
  }

  const getXDomainAndScale = () => {
    const xValues = availableDatetimes
    const xRange: [number, number] = [0, chartWidth] // [left, right]
    const xDomain = extent(xValues)
    const xDist = (xDomain[1]?.getTime() || 0) - (xDomain[0]?.getTime() || 0)
    // we could write const xScale = scaleUtc(xDomain, xRange); we do manipulation to create padding on either side of lines
    const computedpadding = {
      left: xDist * percentOfWithSpaceLeft,
      right: xDist * percentOfWithSpaceRight,
    }
    const xDomainAdjustedForPadding = [
      (xDomain[0]?.getTime() || 0) - computedpadding.left,
      (xDomain[1]?.getTime() || 0) + computedpadding.right,
    ]
    const xScale = scaleUtc(xDomainAdjustedForPadding, xRange)
    return {
      xDomain,
      xScale,
    }
  }

  const updateYAxis = () => {
    const { yScale } = scales
    if (!yAxisGroup || !yScale) {
      return
    }
    const yAxis = axisLeft(yScale)
      .ticks(height / 40, 's')
      .tickFormat(val => format(val <= 1 ? ' ' : '.2s')(val))
    yAxisGroup
      .transition()
      .duration(1000)
      .call(yAxis)
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('x2', chartWidth)
          .attr('stroke-opacity', 0.1),
      )

    yAxisGroup.selectAll('text').attr('fill', labelColor)

    yAxisGroup.selectAll('.domain').style('stroke', axesColor)
  }

  const updateXAxis = () => {
    const { xScale } = scales
    if (!xAxisGroup || !xScale) {
      return
    }
    let fontSize = 16
    const { size } = divSize
    switch (size) {
      case SIZE.large:
        fontSize = 16
        break
      case SIZE.medium:
        fontSize = 14
        break
      case SIZE.small:
        fontSize = 12
        break
    }
    const xAxis = axisBottom(xScale)
      .ticks(Math.max(chartWidth / 80, 5), '%d-%m-%y')
      .tickSizeOuter(0)
    xAxisGroup
      .transition()
      .duration(1000)
      .call(xAxis)
      .selectAll('text')
      .attr('font-size', fontSize)
      .attr('fill', labelColor)
      .attr('transform', () => {
        switch (size) {
          case SIZE.large:
            return 'translate(15,40) rotate(90)'
          case SIZE.medium:
            return 'translate(15,40) rotate(90)'
          case SIZE.small:
            return 'translate(15,32) rotate(90)'
        }
      })
    xAxisGroup.selectAll('text').attr('font-size', fontSize)
    xAxisGroup.selectAll('line').style('stroke', axesColor)
  }

  const getYScale = () => {
    const yRange: [number, number] = [chartHeight, 0] // [bottom, top]
    const yValues = map(dataForDisplay, yAccessor)
    const yDomain = [Math.min(0, min(yValues) || 0), max(yValues)]
    const yDist = (yDomain[1] || 0) - (yDomain[0] || 0)
    // we could write const yScale = scaleUtc(yDomain, yRange); we do this multiplation to create padding on either side of lines
    const paddingBottom = yDist * percentOfWithSpaceBottom
    const paddingTop = yDist * percentOfWithSpaceTop
    const yScale = scaleLinear(
      [(yDomain[0] || 0) - paddingBottom, (yDomain[1] || 0) + paddingTop] as [
        number,
        number,
      ],
      yRange,
    )
    return {
      yScale,
    }
  }

  const updatePaths = () => {
    const { xScale, yScale } = scales
    if (!contentGroup || !xScale || !yScale) {
      return
    }
    const line = d3Line<DataPoint>()
      .curve(curveLinear)
      .x(i => xScale(xAccessor(i)))
      .y(i => yScale(yAccessor(i)))
    const className = `timeseries-line`

    const lineData: LineData[] = separatedData.map(
      ({ color, data, label }: DataForLine<DataPoint>) => ({
        color,
        label,
        d: line(data) || '',
      }),
    )

    let existingPaths: Selection<
      SVGPathElement,
      LineData,
      SVGElement,
      undefined
    > = contentGroup
      .selectAll<SVGPathElement, LineData>('.' + className)
      .data<LineData>(lineData)

    existingPaths.exit().remove()

    existingPaths
      .enter()
      .append('path')
      .merge(existingPaths)
      .transition()
      .duration(1000)
      .attr('d', line(data))
      .attr('fill', 'none')
      .attr('stroke', (d: LineData) => d.color)
      .attr('class', className)
      .attr('stroke-width', strokeWidth)
      .attr('stroke-linecap', strokeLinecap)
      .attr('stroke-linejoin', strokeLinejoin)
      .attr('stroke-opacity', strokeOpacity)
      .attr('d', ({ d }) => d)
  }

  const handleNewSeparatedData = () => {
    // update x axis
    const { xDomain: newXDomain, xScale: newXScale } = getXDomainAndScale()
    const { yScale: newYScale } = getYScale()
    setScales({
      xDomain: newXDomain,
      xScale: newXScale,
      yScale: newYScale,
    })
  }

  const handleDataForDisplayUpdate = () => {
    if (!xAxisGroup || !yAxisGroup || !contentGroup) {
      return
    }
    const sortedData = dataForDisplay.sort(
      (a, b) => xAccessor(a).getTime() - xAccessor(b).getTime(),
    )
    const newSeparatedData: SeperatedData<DataPoint> = seperateDataByLabel({
      data,
      getColor,
      labelAccessor,
    })
    setAvailableDatetimes(sortedData.map(xAccessor))
    setSeparatedData(newSeparatedData)
  }

  return (
    <div data-testid="data-viz-time-series-chart">
      <svg ref={svgRef}></svg>
    </div>
  )
}

export default TimeSeriesChart
