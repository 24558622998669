import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser'
import {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { mixpanelToken, novaEnv } from '../../config'
import { isEmptyObject, useAppContext, useOAuth } from '@novafuturltd/core'

interface IMXP {
  init: (token: string) => void
  people: { [key: string]: any }
  track(
    // tslint:disable-next-line: variable-name
    event_name: string,
    properties?: Dict | undefined,
    optionsOrCallback?: RequestOptions | Callback | undefined,
    callback?: Callback | undefined,
  ): void
  // tslint:disable-next-line: variable-name
  identify(unique_id?: string | undefined): any
}

const dummyMxp: IMXP = {
  init: () => {},
  track: () => {},
  identify: () => {},
  people: {
    set() {},
  },
}

type MixpanelContextType = IMXP & {
  trackPageView: (path: string, properties?: Dict | undefined) => void
}

const mxp: IMXP = novaEnv === 'prod' ? mixpanel : dummyMxp

export const MixpanelContext: Context<MixpanelContextType> = createContext({
  ...mxp,
  trackPageView: mxp.track,
})

export const useMixPanel = () => useContext(MixpanelContext)

export const MixpanelCtxProvider: FC<PropsWithChildren<{}>> = props => {
  const { profile } = useOAuth()
  const { view } = useAppContext()

  const handleInit = () => {
    if (mixpanelToken) {
      mxp.init(mixpanelToken)
      if (profile && !isEmptyObject(profile)) {
        const { created, firstName, lastName, idUserAccount: id } = profile
        mxp.people.set({
          // some properties that are prefixed with a dollar sign ($) are reserved for special use cases
          // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties
          $created: created,
          $distinct_id: id,
          $first_name: firstName,
          $last_name: lastName,
          $name: `${firstName} ${lastName}`,
          firstName,
          lastName,
          id,
        })
        mxp.identify(id)
      }
    }
  }

  useEffect(() => {
    handleInit()
  }, [mixpanelToken, profile])

  const trackPageView = (pageView: string, options: Dict | undefined) => {
    const screensize = typeof window !== 'undefined' ? view : 'unknown'
    try {
      mxp.track(pageView, { screensize, ...options })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <MixpanelContext.Provider
      value={{
        ...mxp,
        trackPageView,
        track: (event_name, properties) => {
          try {
            mxp.track(event_name, properties)
          } catch (e) {
            console.error('Mix Panel Error: ', e)
            // TODO @goughjo03 - log error
          }
        },
      }}
    >
      {props.children}
    </MixpanelContext.Provider>
  )
}
