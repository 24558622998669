import { useState } from 'react'

export const useUserOptionsState = (defaults: {
  multiSorting: boolean
  filtering: boolean
}) => {
  const [userOptionsState, setUserOptionsState] = useState<{
    [key: string]: boolean
  }>(defaults)

  const toggleMultiSorting = () =>
    setUserOptionsState((current: any) => ({
      ...current,
      multiSorting: !current.multiSorting,
    }))

  const toggleFiltering = () =>
    setUserOptionsState((current: any) => ({
      ...current,
      filtering: !current.filtering,
    }))

  return { userOptionsState, toggleMultiSorting, toggleFiltering }
}
