import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Skeleton } from '@mui/material'
import { forwardRef, useEffect } from 'react'
import { Can } from '../withCan'
import { useStyles } from './styles'

interface Props {
  width?: string
  mobileWidth?: string
  icon: React.ReactNode
  // this colour MUST correspond to a colour from the NovaColours.WidgetColours object
  // defaults to SB050 if one isn't selected
  bgColor?:
    | 'ST050'
    | 'SLG050'
    | 'SAG050'
    | 'SPP050'
    | 'SB050'
    | 'SSB050'
    | 'SP050'
    | 'SCR050'
    | 'SY050'
  name: string
  description?: string
  onClick: any
  deletable?: boolean
  onDeleteWidget?: any
  callDataFunction?: () => void
  isClickable?: boolean
  dataTest?: string
  isDraggable?: boolean
  loading?: boolean
  permissions?: {
    required: boolean
    allPermissions: any
    requiredPermissions: string
  }
}

export const Widget: React.FC<Props & any> = forwardRef(
  (
    {
      width,
      mobileWidth,
      icon,
      bgColor,
      name,
      description,
      onClick,
      deletable,
      onDeleteWidget,
      callDataFunction,
      isClickable,
      dataTest,
      isDraggable,
      permissions,
      loading,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ bgColor })

    const handleDelete = (event: any) => {
      event.stopPropagation()
      onDeleteWidget()
    }

    useEffect(() => {
      // tslint:disable-next-line: no-unused-expression
      callDataFunction && callDataFunction()
    }, [])

    if (permissions?.required) {
      return (
        <Can
          perform={permissions.requiredPermissions}
          yes={() => (
            <div ref={ref} {...props}>
              <div
                className={classes.wrapper}
                data-test={dataTest}
                onClick={isDraggable ? () => {} : onClick}
              >
                <div className={classes.iconWrapper}>{icon}</div>
                <div className={classes.contentWrapper}>
                  {deletable && (
                    <IconButton
                      onClick={handleDelete}
                      className={classes.deleteButton}
                      size="large"
                    >
                      <DeleteIcon className={classes.icon} />
                    </IconButton>
                  )}
                  {loading ? (
                    <Skeleton className={classes.mainText}></Skeleton>
                  ) : (
                    <div className={classes.mainText}>{name}</div>
                  )}
                  {loading && description ? (
                    <Skeleton className={classes.subText}></Skeleton>
                  ) : (
                    <div className={classes.subText}>{description}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      )
    }

    return (
      <div ref={ref} {...props}>
        <div
          className={classes.wrapper}
          data-test={dataTest}
          onClick={isDraggable ? () => {} : onClick}
        >
          <div className={classes.iconWrapper}>{icon}</div>
          <div className={classes.contentWrapper}>
            {deletable && (
              <IconButton
                onClick={handleDelete}
                className={classes.deleteButton}
                size="large"
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            )}

            <div className={classes.mainText}>{name}</div>
            {description && (
              <div className={classes.subText}>{description}</div>
            )}
          </div>
        </div>
      </div>
    )
  },
)

export default Widget
