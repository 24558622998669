import {
  Button,
  Grow,
  IconButton,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popper,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useEffect, useRef, useState } from 'react'
import { useActionStyles } from './actions.styles'

interface ActionButtonProps {
  disabled?: boolean
  startIcon?: React.ReactNode
  onClick: () => void
  title: string
  conditionToRender?: boolean
  dataTest?: string
}

interface ActionKebabProps {
  startIcon: (props?: any) => JSX.Element
  onClick: () => void
  title: string
  conditionToRender?: boolean
  dataTest?: string
}

interface ActionColumnProps {
  row: { [key: string]: any }
  actionButtons?: ActionButtonProps[]
  actionKebab?: ActionKebabProps[]
}

export const ActionColumn: React.FC<ActionColumnProps> = ({
  actionButtons,
  actionKebab,
}) => {
  const classes = useActionStyles({})

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }
    prevOpen.current = open
  }, [open])

  const hiddenKebabMenuItems:
    | ActionKebabProps[]
    | undefined = actionKebab?.filter(
    (item: ActionKebabProps) => item.conditionToRender === false,
  )
  return (
    <>
      {actionButtons?.map((button: ActionButtonProps, b: number) => {
        if (button.conditionToRender === false) {
          return <></>
        }
        return (
          <Button
            key={b}
            color="primary"
            disabled={button.disabled}
            variant="outlined"
            size="small"
            startIcon={button?.startIcon ? button?.startIcon : <></>}
            onClick={button.onClick}
            className={classes.actionButton}
            data-test={button?.dataTest}
          >
            {button.title}
          </Button>
        )
      })}
      {actionKebab?.length !== 0 &&
        hiddenKebabMenuItems?.length !== actionKebab?.length && (
          <IconButton
            aria-controls="action-menu"
            aria-haspopup="true"
            onClick={handleToggle}
            data-testid="kebab-menu"
            size="large"
            ref={anchorRef}
          >
            <MoreVertIcon />
          </IconButton>
        )}

      {hiddenKebabMenuItems?.length !== actionKebab?.length && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          className={classes.menuItemContainer}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    className={classes.menuItemContainer}
                    onMouseLeave={handleClose}
                  >
                    {actionKebab?.map((item: ActionKebabProps, i: number) => {
                      const Icon = (props: any) => item?.startIcon(props)
                      if (item?.conditionToRender === false) {
                        return []
                      }
                      return (
                        <MenuItem
                          className={classes.menuItem}
                          key={i}
                          onClick={() => {
                            handleClose
                            item.onClick()
                          }}
                          data-test={item?.dataTest}
                        >
                          <Icon className={classes.menuItemIcon} />
                          {item.title}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  )
}

export default ActionColumn
