import React, { ComponentType } from 'react'
import { IntlProvider } from 'react-intl'
import { AppLocale } from './languageProvider'
import config, {
  getCurrentLanguage,
} from './config'

const currentAppLocale: typeof AppLocale.en =
  AppLocale[getCurrentLanguage(config.defaultLanguage || 'en').locale as 'en']
export const withLang = (ComposedComponent: ComponentType) => (props: any) => (
  <IntlProvider
    locale={currentAppLocale.locale}
    messages={currentAppLocale.messages}
  >
    <ComposedComponent {...props} />
  </IntlProvider>
)
