import { SvgIcon, SvgIconProps } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '180px',
      height: '32px',
    },
  }),
)

export const NovaTextLogoIcon: FC<SvgIconProps> = props => {
  const classes = useStyles({})
  return (
    <SvgIcon className={classes.root} viewBox="0 0 250 32" {...props}>
      <path
        d="M82.7524 0.945557V17.9038H89.631V22.6906H77.0171V0.945557H82.7524Z"
        fill="#407EFF"
      />
      <path
        d="M89.6284 11.8167C89.6284 10.2015 89.9318 8.69848 90.536 7.3022C91.1401 5.90859 91.98 4.69119 93.0528 3.65533C94.1257 2.6168 95.4098 1.8052 96.9026 1.21785C98.3953 0.63051 100.04 0.339508 101.833 0.339508C103.627 0.339508 105.247 0.63318 106.75 1.21785C108.251 1.8052 109.546 2.6168 110.627 3.65533C111.711 4.69386 112.553 5.90859 113.158 7.3022C113.762 8.69581 114.065 10.2015 114.065 11.8167C114.065 13.4319 113.762 14.9377 113.158 16.3313C112.553 17.7249 111.708 18.9423 110.627 19.9808C109.544 21.0193 108.251 21.8309 106.75 22.4183C105.247 23.0056 103.608 23.2966 101.833 23.2966C100.059 23.2966 98.3953 23.003 96.9026 22.4183C95.4098 21.8309 94.1257 21.0193 93.0528 19.9808C91.98 18.9423 91.1401 17.7276 90.536 16.3313C89.9318 14.9377 89.6284 13.4319 89.6284 11.8167ZM95.6293 11.8167C95.6293 12.6817 95.7945 13.48 96.1277 14.2115C96.4583 14.943 96.908 15.5784 97.4742 16.115C98.0404 16.6543 98.6988 17.0708 99.4492 17.3698C100.2 17.6688 100.996 17.8157 101.833 17.8157C102.67 17.8157 103.467 17.6661 104.217 17.3698C104.968 17.0708 105.632 16.6543 106.209 16.115C106.783 15.5784 107.238 14.943 107.569 14.2115C107.899 13.48 108.067 12.6844 108.067 11.8167C108.067 10.9491 107.902 10.1535 107.569 9.42198C107.235 8.69047 106.783 8.05774 106.209 7.51845C105.632 6.98183 104.971 6.56268 104.217 6.26367C103.467 5.96466 102.67 5.81782 101.833 5.81782C100.996 5.81782 100.2 5.96733 99.4492 6.26367C98.6988 6.56268 98.0404 6.97916 97.4742 7.51845C96.908 8.05774 96.4583 8.69047 96.1277 9.42198C95.7945 10.1535 95.6293 10.9517 95.6293 11.8167Z"
        fill="#407EFF"
      />
      <path
        d="M124.135 10.5192H135.519C135.519 11.6726 135.47 12.7218 135.373 13.6615C135.275 14.6039 135.08 15.4796 134.788 16.2859C134.379 17.4205 133.823 18.4243 133.119 19.3C132.417 20.1757 131.591 20.9045 130.645 21.4919C129.7 22.0792 128.659 22.5251 127.53 22.8321C126.397 23.1391 125.208 23.294 123.959 23.294C122.241 23.294 120.675 23.0163 119.261 22.4583C117.847 21.9003 116.631 21.1181 115.617 20.1063C114.601 19.0971 113.813 17.885 113.247 16.4728C112.681 15.0605 112.399 13.4987 112.399 11.7874C112.399 10.076 112.678 8.54362 113.233 7.12865C113.789 5.71636 114.58 4.50963 115.604 3.50848C116.628 2.50999 117.863 1.73043 119.307 1.17245C120.751 0.614476 122.361 0.336823 124.135 0.336823C126.438 0.336823 128.456 0.828055 130.193 1.80785C131.929 2.78765 133.306 4.30673 134.319 6.3651L128.876 8.58633C128.37 7.39563 127.711 6.53864 126.901 6.02071C126.091 5.50278 125.17 5.24114 124.135 5.24114C123.276 5.24114 122.496 5.39866 121.794 5.71636C121.093 6.03406 120.491 6.48524 119.995 7.07259C119.497 7.65993 119.107 8.36474 118.825 9.19236C118.543 10.02 118.4 10.941 118.4 11.9609C118.4 12.8846 118.522 13.7389 118.765 14.5265C119.009 15.3141 119.375 15.9975 119.863 16.5742C120.35 17.1509 120.955 17.5967 121.678 17.9144C122.399 18.2321 123.238 18.3896 124.195 18.3896C124.761 18.3896 125.308 18.3282 125.834 18.2028C126.359 18.0773 126.833 17.877 127.253 17.5967C127.673 17.3191 128.02 16.9586 128.291 16.5155C128.565 16.0723 128.749 15.5357 128.846 14.9003H124.135V10.5166V10.5192Z"
        fill="#407EFF"
      />
      <path
        d="M142.758 0.945557V22.6906H137.023V0.945557H142.758Z"
        fill="#407EFF"
      />
      <path
        d="M157.84 5.73241H150.935V9.36593H157.228V14.1528H150.935V22.6906H145.199V0.945557H157.843V5.73241H157.84Z"
        fill="#407EFF"
      />
      <path
        d="M165.09 0.945557V12.7699C165.09 13.4053 165.114 14.054 165.163 14.7161C165.212 15.3782 165.358 15.9789 165.602 16.5182C165.846 17.0575 166.23 17.4953 166.759 17.8317C167.284 18.1681 168.027 18.3363 168.983 18.3363C169.939 18.3363 170.676 18.1681 171.194 17.8317C171.711 17.4953 172.096 17.0575 172.35 16.5182C172.605 15.9789 172.754 15.3782 172.803 14.7161C172.852 14.0513 172.876 13.4026 172.876 12.7699V0.945557H178.584V13.5494C178.584 16.9347 177.799 19.4042 176.227 20.9606C174.656 22.5171 172.242 23.2967 168.983 23.2967C165.724 23.2967 163.304 22.5171 161.725 20.9606C160.146 19.4042 159.354 16.932 159.354 13.5494V0.945557H165.09Z"
        fill="#407EFF"
      />
      <path
        d="M189.031 5.73241V22.6906H183.296V5.73241H178.584V0.945557H193.745V5.73241H189.034H189.031Z"
        fill="#407EFF"
      />
      <path
        d="M199.48 0.945557V12.7699C199.48 13.4053 199.505 14.054 199.553 14.7161C199.602 15.3782 199.748 15.9789 199.992 16.5182C200.236 17.0575 200.621 17.4953 201.149 17.8317C201.675 18.1681 202.417 18.3363 203.373 18.3363C204.33 18.3363 205.067 18.1681 205.584 17.8317C206.101 17.4953 206.486 17.0575 206.741 16.5182C206.995 15.9789 207.144 15.3782 207.193 14.7161C207.242 14.0513 207.266 13.4026 207.266 12.7699V0.945557H212.975V13.5494C212.975 16.9347 212.189 19.4042 210.618 20.9606C209.046 22.5171 206.632 23.2967 203.373 23.2967C200.114 23.2967 197.695 22.5171 196.115 20.9606C194.536 19.4042 193.745 16.932 193.745 13.5494V0.945557H199.48Z"
        fill="#407EFF"
      />
      <path
        d="M233.047 22.6906H225.905L220.433 14.3263V22.6906H214.698V0.945557H223.624C224.854 0.945557 225.927 1.12443 226.843 1.47951C227.758 1.83458 228.514 2.32047 229.11 2.93719C229.706 3.5539 230.153 4.26405 230.457 5.07031C230.76 5.87924 230.909 6.74424 230.909 7.6653C230.909 9.31787 230.503 10.6608 229.696 11.6886C228.885 12.7165 227.691 13.4133 226.111 13.779L233.047 22.6906ZM220.433 10.6634H221.517C222.649 10.6634 223.516 10.4338 224.12 9.97196C224.724 9.5101 225.028 8.848 225.028 7.983C225.028 7.118 224.724 6.45591 224.12 5.99404C223.516 5.53218 222.646 5.30258 221.517 5.30258H220.433V10.6661V10.6634Z"
        fill="#407EFF"
      />
      <path
        d="M246.611 5.73241H239.792V9.36593H246.232V14.1528H239.792V17.9011H246.611V22.688H234.057V0.945557H246.611V5.73241Z"
        fill="#407EFF"
      />
      <path
        d="M66.623 18.6554V21.3043L68.9511 22.6292L71.2791 21.3043V18.6554L68.9511 17.3312L66.623 18.6554Z"
        fill="#00D874"
      />
      <path
        d="M8.35971 1.10025V9.2144H16.5065V1.10025H22.1766V22.8358H16.5065V14.0373H8.35971V22.8358H2.72217V1.10025H8.35971Z"
        fill="#00D874"
      />
      <path
        d="M24.1417 1.10025H29.7793V13.9721C29.7793 15.4928 30.1486 16.6116 30.8872 17.3286C31.6259 18.0237 32.5166 18.3713 33.5594 18.3713C34.6021 18.3713 35.4711 18.0563 36.1663 17.4263C36.5574 17.0787 36.8506 16.6442 37.0462 16.1228C37.2634 15.5797 37.3829 15.1561 37.4046 14.8519C37.4263 14.5261 37.4372 14.0699 37.4372 13.4833V1.10025H43.0747V13.5811C43.0747 14.5369 43.0422 15.3299 42.977 15.9599C42.9118 16.5682 42.6837 17.3177 42.2927 18.2084C41.9233 19.0774 41.3694 19.8812 40.6307 20.6198C38.8493 22.423 36.4596 23.3246 33.4616 23.3246C30.2898 23.3246 27.8892 22.4556 26.2599 20.7176C24.8478 19.1751 24.1417 16.7963 24.1417 13.5811V1.10025Z"
        fill="#00D874"
      />
      <path
        d="M54.0795 22.8358H45.0203V1.10025H53.4277C55.5785 1.10025 57.1644 1.66509 58.1854 2.79477C59.0761 3.77238 59.5215 5.13017 59.5215 6.86814C59.5215 7.82403 59.3151 8.71474 58.9023 9.54027C58.4678 10.3441 57.8813 10.9089 57.1426 11.2348C58.3592 11.3651 59.3477 11.7996 60.108 12.5383C61.0857 13.4724 61.5745 14.7976 61.5745 16.5139C61.5745 18.2953 61.0422 19.7183 59.9777 20.7828C58.6525 22.1514 56.6864 22.8358 54.0795 22.8358ZM50.6578 5.43432V9.67062H51.7006C52.6999 9.67062 53.4169 9.44251 53.8514 8.98629C54.1989 8.6387 54.3727 8.16076 54.3727 7.55247C54.3727 6.9659 54.1989 6.4771 53.8514 6.08606C53.4169 5.65156 52.6999 5.43432 51.7006 5.43432H50.6578ZM50.6578 13.8743V18.4365H51.9287C53.7536 18.4365 54.9159 18.1432 55.4155 17.5567C55.7197 17.2091 55.8717 16.742 55.8717 16.1554C55.8717 15.482 55.6871 14.9606 55.3178 14.5912C54.8398 14.1133 53.6776 13.8743 51.831 13.8743H50.6578Z"
        fill="#00D874"
      />
      <rect
        x="1.60596"
        y="25.3069"
        width="70.9871"
        height="3.72127"
        fill="#00D874"
      />
    </SvgIcon>
  )
}
