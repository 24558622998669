import { SvgIcon, SvgIconProps } from '@mui/material'

export const USAFlagIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clip-path="url(#clip0_816_33)">
        <mask
          id="mask0_816_33"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="3"
          width="24"
          height="18"
        >
          <path
            d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_816_33)">
          <path
            d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 3H10.2857V11.4H0V3Z"
            fill="#444379"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.14285 4.19995V5.39995H2.28571V4.19995H1.14285ZM3.42857 4.19995V5.39995H4.57142V4.19995H3.42857ZM5.71428 4.19995V5.39995H6.85714V4.19995H5.71428ZM8 4.19995V5.39995H9.14285V4.19995H8ZM6.85714 5.39995V6.59995H8V5.39995H6.85714ZM4.57142 5.39995V6.59995H5.71428V5.39995H4.57142ZM2.28571 5.39995V6.59995H3.42857V5.39995H2.28571ZM1.14285 6.59995V7.79995H2.28571V6.59995H1.14285ZM3.42857 6.59995V7.79995H4.57142V6.59995H3.42857ZM5.71428 6.59995V7.79995H6.85714V6.59995H5.71428ZM8 6.59995V7.79995H9.14285V6.59995H8ZM1.14285 8.99995V10.2H2.28571V8.99995H1.14285ZM3.42857 8.99995V10.2H4.57142V8.99995H3.42857ZM5.71428 8.99995V10.2H6.85714V8.99995H5.71428ZM8 8.99995V10.2H9.14285V8.99995H8ZM6.85714 7.79995V8.99995H8V7.79995H6.85714ZM4.57142 7.79995V8.99995H5.71428V7.79995H4.57142ZM2.28571 7.79995V8.99995H3.42857V7.79995H2.28571Z"
            fill="#A7B6E7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2857 3V4.2H24V3H10.2857ZM10.2857 5.4V6.6H24V5.4H10.2857ZM10.2857 7.8V9H24V7.8H10.2857ZM10.2857 10.2V11.4H24V10.2H10.2857ZM0 12.6V13.8H24V12.6H0ZM0 15V16.2H24V15H0ZM0 17.4V18.6H24V17.4H0ZM0 19.8V21H24V19.8H0Z"
            fill="#ED4C49"
          />
          {/* <path
            d="M2.28571 3.5H21.7143C22.6778 3.5 23.5 4.32739 23.5 5.4V18.6C23.5 19.6726 22.6778 20.5 21.7143 20.5H2.28571C1.3222 20.5 0.5 19.6726 0.5 18.6V5.4C0.5 4.32739 1.3222 3.5 2.28571 3.5Z"
            stroke="black"
            stroke-opacity="0.1"
          /> */}
        </g>
      </g>
      <defs>
        <clipPath id="clip0_816_33">
          <rect
            width="24"
            height="18"
            fill="white"
            transform="translate(0 3)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
