import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useState } from 'react'

dayjs.extend(relativeTime)

interface Props {
  timeSince?: number
}

interface UseElapsedTime {
  whenOccured?: string
}

export const useElapsedTime = ({ timeSince }: Props): UseElapsedTime => {
  // @ts-ignore
  const [_time, setTime] = useState(Date.now())
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now()
      setTime(now)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [timeSince])
  return { whenOccured: !timeSince ? '' : `${dayjs(timeSince).fromNow()}` }
}

export default useElapsedTime
