import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  TextField,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { TDynamicColumn } from './types/columns'
import { BorderedContainer } from './bordered-container'
import { groupColumns } from './filter-drawer/helper'
import { useEffectIgnoreFirstRender } from './hooks/useEffectIgnoreFirstRender'
import { Heading } from './typography/heading'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    header: {
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    accordionGroup: {
      margin: theme.spacing(1),
      flex: 1,
      position: 'relative',
      width: '424px',
    },
    scrollableList: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: 'scroll',
    },
    warningButton: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(2),
    },
    marginOne: {
      margin: theme.spacing(1),
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
  }),
)

interface Props {
  availableColumns: TDynamicColumn[]
  changeColumns: (columns: string[]) => void
  onDone: () => void
  resetSelection: () => void
  selectionState: [Selection, Dispatch<SetStateAction<Selection>>]
}

interface Selection {
  [key: string]: string[]
}

type UseTableColumnSelect = (args: {
  defaultColumns: TDynamicColumn[] | null
}) => {
  resetSelection: () => void
  selectionState: [Selection, Dispatch<SetStateAction<Selection>>]
}

export const useTableColumnSelect: UseTableColumnSelect = ({
  defaultColumns,
}) => {
  const [selection, setSelection] = useState<Selection>(
    !!defaultColumns ? groupColumns(defaultColumns) : {},
  )
  useEffectIgnoreFirstRender(() => {
    if (defaultColumns) {
      setSelection(groupColumns(defaultColumns))
    }
  }, [defaultColumns])
  const resetSelection = () => {
    setSelection(!!defaultColumns ? groupColumns(defaultColumns) : {})
  }
  return {
    resetSelection,
    selectionState: [selection, setSelection],
  }
}

const transformNameForDisplay = (name: string) =>
  name.replace(new RegExp('_', 'g'), ' ')

export const TableColumnsSelect: FC<Props> = ({
  availableColumns,
  changeColumns,
  onDone,
  resetSelection,
  selectionState,
}) => {
  const classes = useStyles({})
  const { formatMessage } = useIntl()
  const [searchValue, setSearchValue] = useState<string>('')
  const columnsGroups = groupColumns(availableColumns)
  const [selection, setSelection] = selectionState
  useEffectIgnoreFirstRender(() => {
    if (selection) {
      changeColumns(
        Object.keys(selection).reduce((acc: string[], curr: string) => {
          return [...acc, ...selection[curr]]
        }, []),
      )
    }
  }, [selection])
  const handleReset = () => {
    setSearchValue('')
    resetSelection()
  }
  const handleCheckboxChange = (
    checked: boolean,
    groupName: string,
    option: string,
  ) => {
    if (checked) {
      setSelection((prevState: Selection) => ({
        ...prevState,
        [groupName]: [...(prevState[groupName] || []), option],
      }))
    } else {
      setSelection((prevState: Selection) => ({
        ...prevState,
        [groupName]: [
          ...(prevState[groupName]?.filter(e => e !== option) || []),
        ],
      }))
    }
  }

  const [expanded, setExpanded] = useState<string[]>([])

  const expandAll = () => {
    setExpanded(Object.keys(columnsGroups).map(group => `accordion-${group}`))
  }
  const collapseAll = () => {
    setExpanded([])
  }

  const handleChange = (id: string) => (_: any, isExpanded: boolean) => {
    setExpanded(
      isExpanded ? [id, ...expanded] : expanded.filter(key => key !== id),
    )
  }

  const filterColumnGroups = ([_groupName, columnOptions]: [
    string,
    string[],
  ]) =>
    columnOptions.some((option: string) =>
      transformNameForDisplay(option).includes(searchValue),
    )
  const filterOptions = (option: string) =>
    transformNameForDisplay(option).includes(searchValue)
  return (
    <BorderedContainer className={`${classes.flexColumn}`}>
      <div className={`${classes.flexRow} ${classes.header}`}>
        <Heading variant="h2">
          {formatMessage({
            defaultMessage: 'Columns',
            id: 'customisetable.columns.selectTitle',
          })}
        </Heading>
        <div className={`${classes.flexRow} ${classes.alignItemsCenter}`}>
          <Button
            className={classes.warningButton}
            onClick={() => handleReset()}
          >
            {formatMessage({
              id: 'customisetable.columns.Reset',
            })}
          </Button>
          <Button onClick={() => onDone()} variant="contained" color="primary">
            {formatMessage({
              id: 'customisetable.columns.Done',
            })}
          </Button>
        </div>
      </div>
      <TextField
        id="search-columns"
        className={`${classes.marginOne}`}
        label={formatMessage({ id: 'customisetable.columns.searchColumns' })}
        autoFocus={true}
        value={searchValue}
        variant="outlined"
        onChange={(
          event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          setSearchValue(event.target.value)
          if (event.target.value === '') {
            collapseAll()
          } else {
            expandAll()
          }
        }}
        type="search"
      />
      <div className={`${classes.accordionGroup}`}>
        <div className={`${classes.flexColumn} ${classes.scrollableList}`}>
          {Object.entries(columnsGroups)
            .filter(filterColumnGroups)
            .map(([groupName, columnOptions]) => {
              return (
                <div key={`accordion-${groupName}`}>
                  <Accordion
                    elevation={0}
                    key={`accordion-${groupName}`}
                    data-testid={`accordion-${groupName}`}
                    onChange={handleChange(`accordion-${groupName}`)}
                    expanded={
                      expanded?.includes(`accordion-${groupName}`)
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {groupName}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={`${classes.flexColumn}`}>
                        {columnOptions
                          .filter(filterOptions)
                          .map((option: string) => {
                            const checked =
                              selection[groupName]?.indexOf(option) > -1
                            return (
                              <div
                                key={`column-option-${option}`}
                                className={`${classes.flexRow}`}
                              >
                                <Checkbox
                                  checked={checked}
                                  color="primary"
                                  data-testid={`column-option-checkbox-${option}`}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleCheckboxChange(
                                      event.target.checked,
                                      groupName,
                                      option,
                                    )
                                  }
                                />
                                <p>{transformNameForDisplay(option)}</p>
                              </div>
                            )
                          })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )
            })}
        </div>
      </div>
    </BorderedContainer>
  )
}

export default TableColumnsSelect
