import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - calc(${theme.spacing(7.5)} + 1px))`,
        height: '100%',
      },
    },
    rootOpenDrawer: {
      width: `calc(100% - calc(${theme.spacing(7.5)} + 180px))`,
      [theme.breakpoints.up('sm')]: {
        height: '100%',
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 0, 2, 0.5),
      background: theme.palette.secondary[theme.palette.mode],
      // necessary for content to be below app bar
    },
    hideFooter: {
      height: '100%!important',
    },
    content: {
      height: `calc(100% - ${theme.spacing(1.5)})`,
      // border: '1px solid #E9E9E9',
      paddingTop: theme.spacing(2),
      borderRadius: '12px',
      background: 'transparent',
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(1.5),
      }
    },
  }),
)

interface Props {
  isFooterVisible: boolean
  openDrawer: boolean
  children: any
}

export const Main: FunctionComponent<Props> = ({
  openDrawer,
  children,
  isFooterVisible,
}) => {
  // @ts-ignore
  const classes = useStyles({})

  return (
    <div
      data-testid="app-main"
      className={clsx(openDrawer ? classes.rootOpenDrawer : classes.root, {
        [classes.hideFooter]: !isFooterVisible,
      })}
    >
      <div className={classes.toolbar} />
      <main
        className={clsx(classes.content, {
          [classes.hideFooter]: !isFooterVisible,
        })}
      >
        {children}
      </main>
    </div>
  )
}
