import { SvgIcon } from '@mui/material'
import React, { FC } from 'react'

export const NovaSmallIcon: FC = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7149 0L11.7389 8.32L7.04293 0H0.231934L6.21293 8.32L10.8789 16H16.3089L17.1209 8.31L17.9999 0H12.7149Z"
        fill="#F6A22D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.831 8.32001L0 16H5.43L6.212 8.32001H0.831Z"
        fill="#F6A22D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.831055 8.32001L5.43005 16L6.21205 8.32001H0.831055Z"
        fill="#F6A22C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16H5.43L0.831 8.32001L0 16Z"
        fill="#EE9F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.715 0L11.739 8.32H17.127L12.715 0Z"
        fill="#F9BB35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.739 8.32001L16.309 16L17.127 8.32001H11.739Z"
        fill="#F7B62D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0001 0H12.7151L17.1271 8.32L18.0001 0Z"
        fill="#F8AE2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21191 8.32001L10.8789 16L11.7389 8.32001H6.21191Z"
        fill="#F6AC2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04291 0L6.21191 8.32H11.7389L7.04291 0Z"
        fill="#F9B435"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8789 16H16.3089L11.7389 8.32001L10.8789 16Z"
        fill="#F6A22D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.229004 0L6.212 8.32L7.043 0H0.229004Z"
        fill="#F7A82B"
      />
    </SvgIcon>
  )
}
