import { SvgIcon, SvgIconProps } from '@mui/material'
import { forwardRef } from 'react'

export const OperaMiniIcon = forwardRef((props: SvgIconProps, ref) => {
  return (
    <SvgIcon {...props} ref={ref as any}>
      <g clipPath="url(#clip0_3977_494)">
        <path
          d="M20.9799 13.04C21.1199 13.04 20.9399 10.8 20.9199 10.66C20.7699 9.62 20.4299 8.61 19.9299 7.69C18.9399 5.88 17.3299 4.44 15.4199 3.66C14.3399 3.22 13.1799 3 12.0099 3C9.91991 3 7.85991 3.74 6.24991 5.09C4.63991 6.44 3.54991 8.29 3.16991 10.32C2.73991 12.59 3.11991 15.03 4.33991 17C5.41991 18.76 7.09991 20.12 9.04991 20.8C9.99991 21.13 11.0099 21.3 12.0099 21.3C15.0199 21.3 17.6799 19.81 19.3199 17.54C20.1999 17.39 20.8799 16.97 21.3699 16.22C22.0099 15.23 21.9699 14.17 21.9699 13.04H20.9799ZM12.0199 19.91C11.2499 19.91 10.4699 19.58 9.90991 18.98C9.04991 18.06 8.64991 16.75 8.44991 15.53C8.40991 15.32 8.37991 15.1 8.35991 14.88C8.21991 13.57 8.24991 12.21 8.27991 10.89C8.30991 9.37 8.47991 7.79 9.19991 6.43C9.42991 6 9.71991 5.6 10.0499 5.24C10.5699 4.7 11.2499 4.33 12.0099 4.33C14.5799 4.33 15.5999 7.85 15.7099 9.91C15.7599 10.95 15.7299 11.99 15.7299 13.03H14.4299C13.6399 13.03 12.8599 13.37 12.3699 13.99C11.8799 14.61 11.6699 15.57 11.6699 16.37V17.62H14.9399C14.9399 17.62 14.7399 18.03 14.7099 18.08C14.6199 18.24 14.5199 18.39 14.4199 18.54C14.2199 18.82 13.9899 19.08 13.7299 19.29C13.2199 19.7 12.6199 19.9 12.0199 19.9V19.91ZM16.1499 16.47H15.7299V15.08C15.7099 15.14 15.6199 15.43 15.6199 15.43L15.1799 16.47H14.7699L14.3199 15.43C14.3199 15.43 14.2299 15.14 14.2099 15.08V16.47H13.7999V14.2H14.2499L14.9699 16L15.6999 14.2H16.1499V16.47ZM16.8899 16.47H16.4599V14.2H16.8899V16.47ZM19.0899 16.47H18.6599L17.7099 15.02C17.7099 15.02 17.6299 14.89 17.6099 14.84V16.47H17.1999V14.2H17.6299L18.5799 15.64C18.5799 15.64 18.6599 15.77 18.6799 15.82V14.2H19.0899V16.47ZM19.8299 16.47H19.4099V14.2H19.8299V16.47Z"
          // fill={fill ? fill : defaultFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3977_494">
          <rect
            width="18.95"
            height="18.31"
            // fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
})
