import { AxiosPromise, AxiosResponse, AxiosStatic } from 'axios'
import jwt from 'jsonwebtoken'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { UseMutateFunction, useMutation, useQuery } from 'react-query'
import { OrgIdTypeEnum } from '../../containers/Sidebar/trading/types'
import parseOrgId from '../../helpers/parseOrgId/parseOrgId'
import { extractValueFromString, isEmptyObject, removeCookie } from './helpers'
import { TOAuthTokenResponse, TTokenUserData, TUser } from './types'

export type {
  TOAuthTokenResponse, TTokenUserData, TUser,
}
export { extractValueFromString, isEmptyObject, removeCookie }

const decodeToken = (token: string): TTokenUserData => {
  const decoded = jwt.decode(token)
  if (!decoded || typeof decoded === 'string') {
    return { organisationId: '', permissions: [], userId: '' }
  }
  return {
    organisationId: decoded.ido,
    permissions: decoded.permissions,
    userId: decoded.sub as string,
  }
}

export const AUTHORIZATION_HEADER_KEY = 'Authorization'

interface OAuthContext {
  exchangeCodeForToken: UseMutateFunction<
    AxiosResponse<TOAuthTokenResponse>,
    unknown,
    string,
    unknown
  >
  handleLogout: () => void
  organizationId: string | undefined
  permissions: string[]
  profile: TUser | undefined
  tokenData: TOAuthTokenResponse | undefined
  userId: string | undefined
  orgName: OrgIdTypeEnum | undefined

}

const initialContext: OAuthContext = {
  exchangeCodeForToken(_code: string): AxiosPromise<TOAuthTokenResponse> {
    return null as unknown as AxiosPromise<TOAuthTokenResponse>
  },
  handleLogout(): void {},
  organizationId: undefined,
  permissions: [],
  profile: undefined,
  tokenData: undefined,
  userId: undefined,
  orgName: undefined,
}

export const OAuthContext = createContext<OAuthContext>(initialContext)

export const useOAuth = () => useContext(OAuthContext)

interface Props {
  client: AxiosStatic
  exchangeAuthCodeForToken: (code: string) => AxiosPromise<TOAuthTokenResponse>
  getUserProfile: (id: string) => AxiosPromise<TUser>
  logout: () => AxiosPromise<any>
  serverCookie?: string
  setSentryUser: (
    user: { email: string; id: string; username: string } | null,
  ) => void
}

export const getTokenDataCookie = (
  cookie?: string,
): { token: TOAuthTokenResponse; userData: TTokenUserData } | undefined => {
  if (!cookie) {
    return undefined
  }
  const rawToken = extractValueFromString(cookie, 'authData')
  if (!rawToken) {
    return undefined
  } else {
    return {
      token: {
        access_token: rawToken,
        token_type: 'server-token',
        expires_in: 0,
        refresh_token: '',
        scope: 'nova_auth',
      },
      userData: decodeToken(rawToken),
    }
  }
}

export const OAuthProvider: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  client,
  exchangeAuthCodeForToken,
  getUserProfile,
  logout,
  serverCookie,
  setSentryUser,
}) => {
  // Server side cookie used for Cypress testing
  const serverToken = getTokenDataCookie(serverCookie)
  const initialTokenData = serverToken?.token
  const initialOrganizationId = serverToken?.userData.organisationId
  const initialPermissions = serverToken?.userData.permissions || []
  const initialUserId = serverToken?.userData.userId

  const [organizationId, setOrganizationId] = useState<string | undefined>(
    initialOrganizationId,
  )
  const [permissions, setPermissions] = useState<string[]>(initialPermissions)
  const [tokenData, setTokenData] = useState<TOAuthTokenResponse | undefined>(
    initialTokenData,
  )
  const [userId, setUserId] = useState<string | undefined>(initialUserId)
  const {
    remove: removeProfile,
    data: { data: profile } = { data: undefined },
  } = useQuery(
    ['profileQuery', userId],
    () => getUserProfile(userId as string),
    {
      enabled: !!userId,
      onSuccess: (data: AxiosResponse<TUser>) => {
        const userData = {
          email: data?.data?.email || 'undefined',
          id: userId || 'undefined',
          username: `${data?.data?.firstName} ${data?.data?.lastName}`,
        }
        setSentryUser(userData)
      },
    },
  )
  const { mutate: exchangeCodeForToken } = useMutation(
    (code: string) => {
      return exchangeAuthCodeForToken(code)
    },
    {
      onSuccess: res => setTokenData(res.data),
    },
  )
  const appendBearerTokenToHeader = (token: string) => {
    client.defaults.headers.common[AUTHORIZATION_HEADER_KEY] = `Bearer ${token}`
  }
  const removeBearerTokenFromHeader = () => {
    delete client.defaults.headers.common[AUTHORIZATION_HEADER_KEY]
  }
  const handleLogout = (): AxiosPromise<{}> => {
    return logout().then((response: AxiosResponse<{}>) => {
      removeCookie(AUTHORIZATION_HEADER_KEY)
      setTokenData(undefined)
      setSentryUser(null)
      return response
    })
  }
  useEffect(() => {
    if (tokenData && !isEmptyObject(tokenData)) {
      const { access_token: accessToken } = tokenData
      appendBearerTokenToHeader(accessToken)
      const {
        organisationId: newOrganizationId,
        permissions: newPermissions,
        userId: newUserId,
      } = decodeToken(accessToken)
      setOrganizationId(newOrganizationId)
      setPermissions(newPermissions)
      setUserId(newUserId)
    } else {
      removeBearerTokenFromHeader()
      setOrganizationId(undefined)
      setPermissions([])
      setUserId(undefined)
    }
  }, [tokenData])
  useEffect(() => {
    if (!userId) {
      removeProfile()
    }
  }, [userId])

  const orgName = useMemo(() => {
    return parseOrgId(organizationId)
  }, [organizationId])

  return (
    <OAuthContext.Provider
      value={{
        orgName,
        exchangeCodeForToken,
        handleLogout,
        organizationId,
        permissions,
        profile,
        tokenData,
        userId,
      }}
    >
      {children}
    </OAuthContext.Provider>
  )
}
