import qs from 'qs'
import { k8BaseUrl, services } from '../../config/index'
import client from './index'

export default {
  get: (url: string, params = {}, headers = {}) => {
    return client({
      method: 'GET',
      url,
      baseURL: `${k8BaseUrl}${services.userPreferences}`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    })
  },
  post: (url: string, data = {}, params = {}, headers = {}) => {
    return client({
      method: 'POST',
      url,
      baseURL: `${k8BaseUrl}${services.userPreferences}`,
      data,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    })
  },
  put: (url: string, data = {}, params = {}, headers = {}) => {
    return client({
      method: 'PUT',
      url,
      baseURL: `${k8BaseUrl}${services.userPreferences}`,
      data,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    })
  },
  patch: (url: string, data = {}, params = {}, headers = {}) => {
    return client({
      method: 'PATCH',
      url,
      baseURL: `${k8BaseUrl}${services.userPreferences}`,
      data,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    })
  },
  delete: (url: string, data = {}, params = {}, headers = {}) => {
    return client({
      method: 'DELETE',
      url,
      baseURL: `${k8BaseUrl}${services.userPreferences}`,
      data,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    })
  },
}
