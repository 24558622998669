import { Button, ButtonProps, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      fontWeight: 500,
      marginLeft: `${theme.spacing(2)}!important`,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      color: NovaColours.SC000[theme.palette.mode]
    },
    cancel: {
      color: NovaColours.N030[theme.palette.mode],
      fontWeight: 600,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
  }),
)

interface ActionButtonProps extends ButtonProps {
  text?: string
  dataTestId?: string
  className?: string
  // dataTest?: string
}

export const ActionButton: FC<ActionButtonProps> = ({
         text,
         className,
         id,
         variant,
         dataTestId,
         // dataTest,
         color,
         startIcon,
         autoFocus,
         disabled,
         style,
         sx,
         size,
         type,
         ...props
       }) => {
         const classes = useStyles({})
         return (
           <Button
             id={id}
             className={className || classes.action}
             variant={variant || 'contained'}
             data-testid={dataTestId}
             // data-test={dataTest}
             color={color || 'primary'}
             startIcon={startIcon}
             onClick={props.onClick}
             autoFocus={autoFocus}
             disabled={disabled}
             style={style}
             sx={sx}
             size={size}
             type={type}
           >
             {text || props.children}
           </Button>
         )
       }

export const CancelButton: FC<ActionButtonProps> = ({
  text,
  id,
  variant,
  dataTestId,
  // dataTest,
  color,
  startIcon,
  autoFocus,
  disabled,
  style,
  sx,
  size,
  type,
  ...props
}) => {
  const classes = useStyles({})
  return (
    <Button
      id={id}
      className={classes.cancel}
      variant={variant || 'text'}
      data-testid={dataTestId}
      // data-test={dataTest}
      color={color}
      startIcon={startIcon}
      onClick={props.onClick}
      autoFocus={autoFocus}
      disabled={disabled}
      style={style}
      sx={sx}
      size={size}
      type={type}
    >
      {text || props.children || 'Cancel'}
    </Button>
  )
}
