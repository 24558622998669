import { sdk } from '../../../config/index'
import { TUser, useOAuth } from '@novafuturltd/core'
import Init from './events/Init'

export default () => {
  const { profile } = useOAuth()
  return (
    <>
      <script id="nova-sdk" src={sdk.sdkSrc} />
      <Init config={sdk} profile={profile as TUser} />
    </>
  )
}
