import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
// @ts-ignore

const useStyles = makeStyles((theme: Theme) => createStyles({
  BoxTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    margin: '0',
    marginBottom: '5px',
  },
  BoxSubTitle: {
    fontSize: '13px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    lineHeight: '24px',
  },
}))

const BoxTitle: React.FC = ({children}) => {
  const classes = useStyles({})
  return <h3 className={classes.BoxTitle}>{children}</h3>
}
const BoxSubTitle: React.FC = ({children}) => {
  const classes = useStyles({})
  return <h3 className={classes.BoxSubTitle}>{children}</h3>
}

export { BoxTitle, BoxSubTitle }
