import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { numberWithCommas } from '../..'

const numberFormatter = ({ value, column }: any) => (
  <div
    style={{
      fontFeatureSettings: `'tnum' on, 'lnum' on`,
      letterSpacing: '-0.01em',
    }}
  >
    {numberWithCommas(value, column.decimal_places)}
  </div>
)

export const NumberFormatterProvider: React.FC<any> = props => (
  <DataTypeProvider formatterComponent={numberFormatter} {...props} />
)
