import { TextField, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove'
import {
  materialTheme,
  useStyles,
} from './styles'
import React, { useState } from 'react'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface INumberRangeFilterProps {
  startValue: number | null
  endValue: number | null
  setStartInteger: (
    value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  setEndInteger: (
    value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  startInputName: string
  endInputName: string
  isFutureAllowed?: boolean
  error?: boolean
}

export const InputRangePicker: React.FC<INumberRangeFilterProps> = ({
  startInputName,
  endInputName,
  startValue,
  endValue,
  setStartInteger,
  setEndInteger,
  error,
}) => {
  const style = useStyles({})

  const [isFocus, setIsFocus] = useState({
    start: false,
    end: false,
  })

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={materialTheme}>
        <div style={{ marginTop: '0px', display: 'flex' }}>
          <TextField
            error={error}
            type="number"
            label={startInputName}
            variant="outlined"
            fullWidth={true}
            value={startValue}
            onChange={value => setStartInteger(value)}
            InputProps={{
              classes: {
                root:
                  error && !isFocus.start && !isFocus.end
                    ? style.outlineHoverError
                    : style.outlineHover,
                focused: style.focus,
                notchedOutline: isFocus.end
                  ? style.notchedOutlineStartNumberFocus
                  : style.notchedOutlineStartNumber,
              },
            }}
            onFocus={() => setIsFocus({ ...isFocus, start: true })}
            onBlur={() => setIsFocus({ ...isFocus, start: false })}
            // tslint:disable-next-line: jsx-no-lambda
          />
          <div
            className={
              isFocus.start || isFocus.end
                ? style.dashFocus
                : error
                ? style.dashError
                : style.dash
            }
          >
            <RemoveIcon className={style.dashIcon} />
          </div>
          <TextField
            type="number"
            error={error}
            label={endInputName}
            variant="outlined"
            fullWidth={true}
            value={endValue}
            onChange={value => setEndInteger(value)}
            InputProps={{
              classes: {
                root:
                  error && !isFocus.end && !isFocus.start
                    ? style.outlineHoverError
                    : style.outlineHover,
                focused: style.focus,
                notchedOutline: isFocus.start
                  ? style.notchedOutlineEndNumberFocus
                  : style.notchedOutlineEndNumber,
              },
            }}
            // tslint:disable-next-line: jsx-no-lambda
            onFocus={() => setIsFocus({ ...isFocus, end: true })}
            onBlur={() => setIsFocus({ ...isFocus, end: false })}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default InputRangePicker
