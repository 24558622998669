import { CHART_OPTION } from "./SelectChartType";

export type TChartOptions = [CHART_OPTION, ...CHART_OPTION[]]; // The minimum length is 1.
export type TDataArr<DataPoint> = [TData<DataPoint>, ...TData<DataPoint>[]]; // The minimum length is 1.

export interface TData<DataPoint> {
  dataPoints: DataPoint[]
  title: string
}

export interface Margins {
    top: number
    bottom: number
    left: number
    right: number
}

export enum SIZE {
  small,
  medium,
  large
}

export interface SizeConfig<T> {
  [SIZE.small]: T
  [SIZE.medium]: T
  [SIZE.large]: T
}
