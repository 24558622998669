import { NovaColours } from '@novafuturltd/core'
import { FC } from 'react'
import { useAppContext } from '@novafuturltd/core'

interface IBroadCastIconProps {
  className?: string
  fill?: string
}

export const BroadcastIcon: FC<IBroadCastIconProps> = ({ className, fill }) => {
  const { mode } = useAppContext()
  const defaultFill = NovaColours.N040[mode]
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="54" width="54">
      <path
        className={className}
        fill={fill ? fill : defaultFill}
        d="M22.5 44V27.7q-1.1-.45-1.8-1.45T20 24q0-1.65 1.175-2.825Q22.35 20 24 20q1.65 0 2.825 1.175Q28 22.35 28 24q0 1.25-.7 2.25t-1.8 1.45V44Zm-12.3-5.5q-2.85-2.75-4.525-6.475Q4 28.3 4 24q0-4.15 1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.3-1.675 8.025Q40.65 35.75 37.8 38.5l-2.15-2.15q2.5-2.35 3.925-5.5Q41 27.7 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 3.7 1.425 6.85Q9.85 34 12.35 36.35Zm5.65-5.65q-1.75-1.65-2.8-3.925Q12 26.65 12 24q0-5 3.5-8.5T24 12q5 0 8.5 3.5T36 24q0 2.65-1.05 4.925t-2.8 3.925L30 30.7q1.4-1.25 2.2-2.95.8-1.7.8-3.75 0-3.75-2.625-6.375T24 15q-3.75 0-6.375 2.625T15 24q0 2.05.8 3.75.8 1.7 2.2 2.95Z"
      />
    </svg>
  )
}
