import React, { useState } from 'react'
import {
  Notification,
  TActionButtonProps,
} from '..'

interface TNotification {
  message: string
  type: 'error' | 'info' | 'warning' | 'success'
  actionButtonProps?: TActionButtonProps
}

type NotifyMethod = (
  message: string,
  actionButtonProps?: TActionButtonProps,
) => void

export interface Notify {
  success: NotifyMethod
  info: NotifyMethod
  warning: NotifyMethod
  error: NotifyMethod
  infoWithAction: NotifyMethod
}

export const useNotifications: () => {
  Notification: () => JSX.Element
  notify: Notify
} = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [notification, _setNotification] = useState<TNotification>({
    message: '',
    type: 'info',
  })

  // tslint:disable-next-line: no-shadowed-variable
  const setNotification = (notification: TNotification) => {
    setIsOpen(true)
    _setNotification(notification)
  }

  const notify: Notify = {
    success: (message: string) => setNotification({ message, type: 'success' }),
    info: (message: string) => setNotification({ message, type: 'info' }),
    warning: (message: string) => setNotification({ message, type: 'warning' }),
    error: (message: string) => setNotification({ message, type: 'error' }),
    infoWithAction: (
      message: string,
      actionButtonProps: TActionButtonProps | undefined,
    ) => {
      setNotification({ message, type: 'info', actionButtonProps })
    },
  }

  const onClose = () => setIsOpen(!isOpen)

  return {
    Notification: () => (
      <Notification
        open={isOpen}
        onClose={onClose}
        type={notification.type}
        message={notification.message}
        actionButtonProps={notification.actionButtonProps}
      />
    ),
    notify,
  }
}
