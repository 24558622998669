import { FC } from 'react'
import { isEnabled } from '../contexts/toggle.feature.context'
import { Context } from '../types'

interface IComposition {
  On: FC<OnOffProps>
  Off: FC<OnOffProps>
}

interface Props {
  name: string
  not?: boolean
  context?: Context
}

interface OnOffProps {
  name: string
  context?: Context
}

export const FeatureToggle: FC<Props> & IComposition = ({
  name,
  not,
  context,
  children,
}) => {
  if (not && !isEnabled(name, context)) {
    return <>{children}</>
  }
  return isEnabled(name, context) ? <>{children}</> : null
}

const On: FC<OnOffProps> = ({ name, context, children }) =>
  isEnabled(name, context) ? <>{children}</> : null
const Off: FC<OnOffProps> = ({ name, context, children }) =>
  !isEnabled(name, context) ? <>{children}</> : null

FeatureToggle.On = On
FeatureToggle.Off = Off
