import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export function NovaIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M15.7149 4L14.7389 12.32L10.0429 4H3.23193L9.21293 12.32L13.8789 20H19.3089L20.1209 12.31L20.9999 4H15.7149Z"
        fill="#F6A22D"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M3.831 12.32L3 20H8.43L9.212 12.32H3.831Z"
        fill="#F6A22D"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M3.83105 12.32L8.43005 20L9.21205 12.32H3.83105Z"
        fill="#F6A22C"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M3 20H8.43L3.831 12.32L3 20Z"
        fill="#EE9F2D"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M15.715 4L14.739 12.32H20.127L15.715 4Z"
        fill="#F9BB35"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M14.739 12.32L19.309 20L20.127 12.32H14.739Z"
        fill="#F7B62D"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M21.0001 4H15.7151L20.1271 12.32L21.0001 4Z"
        fill="#F8AE2C"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M9.21191 12.32L13.8789 20L14.7389 12.32H9.21191Z"
        fill="#F6AC2D"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M10.0429 4L9.21191 12.32H14.7389L10.0429 4Z"
        fill="#F9B435"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M13.8789 20H19.3089L14.7389 12.32L13.8789 20Z"
        fill="#F6A22D"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M3.229 4L9.212 12.32L10.043 4H3.229Z"
        fill="#F7A82B"
      />
    </SvgIcon>
  )
}
