import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export const useConfirmDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: NovaColours.N030[theme.palette.mode],
    },
    content: {
      padding: theme.spacing(0, 3, 0.5, 3),
    },
    actions: {
      paddingRight: theme.spacing(3),
      marginBottom: (props: any) => props.usePrimaryStylesForConfirm,
      paddingBottom: 0,
    },
    mainActionButton: {
      color: (props: any) =>
        props.usePrimaryStylesForConfirm
          ? NovaColours.N100[theme.palette.mode]
          : NovaColours.RN080[theme.palette.mode],
      fontWeight: 'bold',
    },
    emphasisSpan: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  }),
)
