import { Drawer } from '@mui/material'
import { useAppContext } from '@novafuturltd/core'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import useStyles from './styles'

interface Props {
  isFooterVisible: boolean
  open: boolean
  hover: boolean
  toggleHoverDrawer: (open: boolean) => void
}

export const SideBar: FunctionComponent<Props> = ({
  isFooterVisible,
  hover,
  toggleHoverDrawer,
  children,
  open,
}) => {
  const { isMobile } = useAppContext()
  const classes = useStyles({ open, hover, isMobile })

  const handleMouseEnter = () => {
    if (!open) {
      toggleHoverDrawer(true)
    }
  }
  const handleMouseLeave = () => {
    if (!open && window.innerWidth > 670) {
      toggleHoverDrawer(false)
    }
  }

  return (
    <Drawer
      data-testid="app-sidebar"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.hideFooter]: !isFooterVisible,
        [classes.drawerOpen]: open || hover,
        [classes.drawerClose]: !open && !hover,
        [classes.hide]: isMobile && !open && !hover,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      classes={{
        paper: clsx({
          [classes.hideFooter]: !isFooterVisible,
          [classes.drawerOpen]: open || hover,
          [classes.drawerClose]: !open,
          [classes.drawerHover]: hover,
          [classes.hide]: isMobile && !open && !hover,
        }),
      }}
    >
      <div className={classes.toolbar} />
      {children}
    </Drawer>
  )
}
