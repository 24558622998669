import { useRouter } from 'next/router'
import qs from 'qs'
import { useState } from 'react'

interface TFilter {
  [key: string]: any
}

export type SortDir = 'ASC' | 'DESC'
export type BrowserQuerySorting = [string, SortDir][]

export interface IQuery {
  page?: number
  page_size?: number
  filter: TFilter
  sorting: BrowserQuerySorting
  [key: string]: any
  type?: number | string
}

export interface IQueryCustomerOverview {
  page?: number
  page_size?: number
  filter: TFilter
  sorting: { [key: string]: any }[]
  [key: string]: any
}

const protectQuery: (query: any) => IQuery = (query: any) => {
  if (!query.sorting) {
    query.sorting = []
  }
  if (!query.filter) {
    query.filter = {}
  }
  return query
}

export const useBrowserQuery: (
  dynamicPath?: string,
) => [IQuery, (query: any, asPath?: string) => void, string?] = (
  dynamicPath?: string,
) => {
  const router = useRouter()
  const path = router.asPath.split('?')
  const query = protectQuery(qs.parse(path[1]))

  const createAsPathObject = (updatedQuery: any, asPath?: string) =>
    asPath
      ? {
          pathname: asPath,
          query: qs.stringify(updatedQuery, { encodeValuesOnly: true }),
        }
      : undefined

  const setQuery = (updatedQuery: any, asPath?: string) => {
    router.push(
      {
        pathname: dynamicPath ? dynamicPath : router.pathname,
        query: qs.stringify(updatedQuery, { encodeValuesOnly: true }),
      },
      createAsPathObject(updatedQuery, asPath),
      { shallow: true },
    )
  }

  return [query, setQuery, path[0]]
}

export const useBrowserQueryCustomerOverview: (
  dynamicPath?: string,
) => [
  IQueryCustomerOverview,
  (query: any, asPath?: string) => void,
  string?,
] = (dynamicPath?: string) => {
  const router = useRouter()
  const path = router.asPath.split('?')
  const [query] = useState<IQueryCustomerOverview>(
    protectQuery(qs.parse(path[1])),
  )

  const createAsPathObject = (updatedQuery: any, asPath?: string) =>
    asPath
      ? {
          pathname: asPath,
          query: qs.stringify(updatedQuery, { encodeValuesOnly: true }),
        }
      : undefined

  const setQuery = (updatedQuery: any, asPath?: string) => {
    router.push(
      {
        pathname: dynamicPath ? dynamicPath : router.pathname,
        query: qs.stringify(updatedQuery, { encodeValuesOnly: true }),
      },
      createAsPathObject(updatedQuery, asPath),
      { shallow: true },
    )
  }

  return [query, setQuery, path[0]]
}
