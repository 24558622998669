import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info'
import { FC } from 'react'
import { NovaColours } from '@novafuturltd/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxInfo: {
      backgroundColor: NovaColours.RI010[theme.palette.mode],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      padding: theme.spacing(1, 2, 1),
      borderRadius: theme.spacing(0.75),
      '& svg': {
        fill: theme.palette.primary.main,
        marginRight: theme.spacing(1),
      },
      '& span': {
        color: theme.palette.info.main,
      },
    },
  }),
)

interface Props {
  info: string
}

export const Info: FC<Props> = ({ info }) => {
  const classes = useStyles({})
  return (
    <Box
      className={classes.boxInfo}
      style={{ justifyContent: 'left' }}
      height="24px"
      margin="10px 0px 10px 0px"
    >
      <InfoIcon fontSize="small" />
      <Typography variant="caption" display="block">
        {info}
      </Typography>
    </Box>
  )
}
