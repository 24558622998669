import { SvgIcon, SvgIconProps } from '@mui/material'

export const UKFlagIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_816_37"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="24"
        height="18"
      >
        <path
          d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
          fill="#41479B"
        />
        <path
          d="M2.28571 3.5H21.7143C22.6778 3.5 23.5 4.32739 23.5 5.4V18.6C23.5 19.6726 22.6778 20.5 21.7143 20.5H2.28571C1.3222 20.5 0.5 19.6726 0.5 18.6V5.4C0.5 4.32739 1.3222 3.5 2.28571 3.5Z"
          stroke="black"
          strokeOpacity="0.1"
        />
      </mask>
      <g mask="url(#mask0_816_37)">
        <path
          d="M21.7143 3H2.28571C1.02335 3 0 4.07452 0 5.4V18.6C0 19.9255 1.02335 21 2.28571 21H21.7143C22.9767 21 24 19.9255 24 18.6V5.4C24 4.07452 22.9767 3 21.7143 3Z"
          fill="#41479B"
        />
        <path
          d="M24 18.6563L15.125 12L24 5.34375V3H20.875L12 9.65627L3.125 3H0V5.34375L8.87501 12L0 18.6563V21H3.12504L12 14.3438L20.875 21H24V18.6563Z"
          fill="white"
        />
        <path
          d="M10.75 12.0002L-0.449976 20.4002L0.450025 21.6002L12 12.9377L23.55 21.6002L24.45 20.4002L13.25 12.0002L24.45 3.60015L23.55 2.40015L12 11.0627L0.450022 2.40015L-0.449982 3.60015L10.75 12.0002Z"
          fill="#DC251C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 9V3H15V9H24V15H15V21H9V15H0V9H9Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 10.5V3H13.5V10.5H24V13.5H13.5V21H10.5V13.5H0V10.5H10.5Z"
          fill="#DC251C"
        />
        {/* <path
          d="M2.28571 3.5H21.7143C22.6778 3.5 23.5 4.32739 23.5 5.4V18.6C23.5 19.6726 22.6778 20.5 21.7143 20.5H2.28571C1.3222 20.5 0.5 19.6726 0.5 18.6V5.4C0.5 4.32739 1.3222 3.5 2.28571 3.5Z"
          stroke="black"
          strokeOpacity="0.1"
        /> */}
      </g>
    </SvgIcon>
  )
}
