import React from 'react'
import { Button, Tab, Tabs, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { TData, TDataArr } from './types'
import { Skeleton } from '@mui/material'
import { toTitleCase } from '../helpers/toTitleCase'
import { UseDivSize } from './hooks/useDivSize'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    overflowContainer: {
      flex: 1,
      overflow: 'auto',
      marginBottom: spacing(1),
    },
    root: {
      flex: 1,
      marginRight: spacing(1),
      marginLeft: spacing(1),
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'stretch',
    },
    selectedTab: {},
    unselectedTab: {},
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      marginLeft: spacing(2),
    },
  }),
)

interface Props<DataPoint> {
  data?: TDataArr<DataPoint>
  divSize: UseDivSize
  handleDataSourceSelect: (data: TData<DataPoint>) => void
  loading: boolean
  selectedDataSourceTitle: string
}

const SelectChartDataSource = <DataPoint,>({
  data,
  divSize,
  handleDataSourceSelect,
  loading,
  selectedDataSourceTitle,
}: Props<DataPoint>) => {
  const classes = useStyles({})
  const selectedTab = data?.find(e => e.title === selectedDataSourceTitle)
  if (loading) {
    return (
      <div className={classes.loading}>
        <Skeleton>
          <Button />
        </Skeleton>
      </div>
    )
  }
  const dataOrderedAlphabeticallyByTitle = data?.sort((a, b) =>
    a.title.localeCompare(b.title),
  )
  return (
    <div
      className={classes.overflowContainer}
      style={{ maxWidth: divSize.width }}
    >
      <Tabs
        indicatorColor="primary"
        onChange={(_e: React.ChangeEvent<{}>, val: TData<DataPoint>) =>
          handleDataSourceSelect(val)
        }
        textColor="primary"
        variant="scrollable"
        value={selectedTab}
        className={classes.root}
      >
        {!loading &&
          !!data?.length &&
          data.length > 1 &&
          dataOrderedAlphabeticallyByTitle?.map(e => {
            const { title: optionTitle } = e
            return (
              <Tab
                className={
                  e.title === selectedDataSourceTitle
                    ? classes.selectedTab
                    : classes.unselectedTab
                }
                data-testid="data-vis-tab-button"
                key={`data-vis-tab-button-${optionTitle}`}
                label={toTitleCase(optionTitle)}
                value={e}
              />
            )
          })}
      </Tabs>
    </div>
  )
}

export default SelectChartDataSource
