import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      display: 'flex',
      justifyContent: 'space-between',
      border: 'none',
    },
  }),
)

export const PageHeader: FC<React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>> = props => {
  const classes = useStyles({})
  return (
    <div {...props} className={`${clsx(classes.root)} ${props.className}`}>
      {props.children}
    </div>
  )
}
