/**
 * Will generate right clour with alpha base on moin - max value
 *
 * @param {Number} value value
 * @param {Number} min Minimum value of list
 * @param {Number} max Maximum value of list
 * @param {String} color Color base
 * @return {String} Generated color with alpha
 */
const alfaGenerator = (value = 0, min = 0, max = 1, color = '255,0,0') => {
  const alpha = (value - min) / (max - min);
  return `rgba(${color},${alpha})`;
};

export default alfaGenerator
