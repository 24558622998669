import { AUTHORIZATION_HEADER_KEY } from '@novafuturltd/core'
import { AxiosResponse } from 'axios'
import { useContext } from 'react'
import { createContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import client from '../../../api/client'
import { getOrgModules } from '../../../api/nHub/userPreference'
import { Module } from '../types'

export interface IOrgSettingsContext {
  query: UseQueryResult<AxiosResponse<{ data: Module[] }>, unknown>
  defaultOrgCurrency: string
}

export const OrgSettingsContext = createContext<IOrgSettingsContext>(
  null as any,
)

export const useOrganizationSettings = () => useContext(OrgSettingsContext)

export const OrgSettingsProvider = (props: { children: React.ReactNode }) => {
  const authHeader = client.defaults.headers.common[AUTHORIZATION_HEADER_KEY]

  const query = useQuery<AxiosResponse<{ data: Module[] }>>(
    ['getOrgModules', authHeader],
    () => getOrgModules(),
    {
      enabled: !!authHeader,
      retry: 1,
      refetchOnWindowFocus: false,
      onError: () => {
        console.error('Error fetching organization settings')
      },
    },
  )

  const currencyExchangeModule = query?.data?.data.data.find(
    module => module.name === 'currency_exchange',
  )
  const currencyExchangeSubModule = currencyExchangeModule?.sub_modules?.find(
    subModule => subModule.name === 'exchange_rates',
  )
  const defaultOrgCurrency = currencyExchangeSubModule?.params?.defaultCurrency

  return (
    <OrgSettingsContext.Provider
      value={{
        query,
        defaultOrgCurrency,
      }}
    >
      {props.children}
    </OrgSettingsContext.Provider>
  )
}
