import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { startCase } from 'lodash'
import { FC, useState } from 'react'
import { TFilterColumnComponentProps } from '.'
import { toTitleCase } from '../helpers/toTitleCase'

export const FilterDrawerAutocompleteAsync = (
  fetchOptions: () => Promise<any>,
) => {
  const WithState: FC<any> = ({ children, currentValue }) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<string[]>([])
    const [values, setValue] = useState<string[]>(
      currentValue !== undefined ? [currentValue] : [],
    )
    const [error, setError] = useState(false)
    const loading = open && !error && options.length === 0
    return (
      <>
        {children({
          open,
          setOpen,
          options,
          setOptions,
          error,
          setError,
          loading,
          values,
          setValue,
        })}
      </>
    )
  }
  return ({
    onChangeFilter,
    currentValue,
    inputName,
    queryKey,
    error,
    isMultiple,
  }: TFilterColumnComponentProps) => {
    return (
      <WithState currentValue={currentValue}>
        {({
          open,
          setOpen,
          options,
          setOptions,
          setError,
          loading,
          values,
          setValue,
        }: // error,
        any) => (
          <Autocomplete
            multiple={isMultiple}
            id={inputName}
            options={options}
            getOptionLabel={option => String(option)}
            open={open}
            style={{ width: '100%' }}
            onOpen={async () => {
              setOpen(true)
              if (options.length === 0 && !error) {
                try {
                  const {
                    data: { data },
                  } = await fetchOptions()
                  setOptions(data[0].value)
                } catch (err) {
                  setError(true)
                }
              }
            }}
            onClose={() => setOpen(false)}
            // tslint:disable-next-line: no-shadowed-variable
            onChange={(_, newValue) => {
              setValue(newValue)
              return onChangeFilter(queryKey, newValue)
            }}
            value={Array.isArray(values?.[0]) ? values[0] : values}
            loading={loading}
            renderInput={params => (
              <TextField
                error={error}
                helperText={
                  error ? (
                    <Box display="flex" alignItems="center">
                      {/* <InfoIcon fontSize="small" /> */}
                      <Typography variant="caption" display="block">
                        {startCase(error?.[currentValue?.op || 'eq'])}
                      </Typography>
                    </Box>
                  ) : null
                }
                variant="outlined"
                {...params}
                size="medium"
                label={`${inputName}`}
                onChange={({ target }) => {
                  if (error) {
                    setValue(target.value)
                    return onChangeFilter(queryKey, target.value)
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: '3.3rem' },
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </WithState>
    )
  }
}

export const FilterDrawerAutocomplete = (
  options:
    | string[]
    | { name: string; tag_id: string }[]
    | { name: string; id: string }[]
    | [],

  optionType?: string,
) => ({
  onChangeFilter,
  currentValue,
  inputName,
  queryKey,
  onOptionSelect,
}: TFilterColumnComponentProps) => {
  const valueObj =
    typeof currentValue === 'string'
      ? // @ts-ignore
        options?.find((e: { tag_id: string }) => e.tag_id === currentValue)
      : null

  const value = valueObj?.name ? valueObj?.name : currentValue
  return (
    <Autocomplete
      options={options || []}
      getOptionLabel={(option: string | any) => {
        if (optionType === 'object' && typeof option !== 'string') {
          return toTitleCase(option.name)
        }
        return toTitleCase(option)
      }}
      onChange={(_, value) => {
        if (onOptionSelect) {
          onOptionSelect(queryKey, value)
        }
        onChangeFilter(queryKey, value)
      }}
      style={{ width: '100%' }}
      value={value}
      renderInput={params => (
        <TextField
          variant="outlined"
          {...params}
          size="medium"
          label={`${inputName}...`}
        />
      )}
    />
  )
}
