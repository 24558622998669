// import { Link } from '@mui/material'
import { useOAuth } from '@novafuturltd/core'
import permissionValidation from '../helpers/permissionValidation'

export function CustomerProfileLink(): (
  row: any,
  columnName: string,
) => JSX.Element {
  const { permissions } = useOAuth()

  const LinkFunc = (row: any, columnName: string) => (
    <div>{row[columnName]}</div>
    // <Link target="_blank" href={`/customers/${row[columnName]}`}>
    //   {row[columnName]}
    // </Link>
  )

  const TextFunc = (row: any, columnName: string) => row[columnName]

  return permissionValidation(permissions, ['looker_gateway.customers.profile'])
    ? LinkFunc
    : TextFunc
}
