import { useState } from "react"
import { useEffectIgnoreFirstRender } from "../../hooks/useEffectIgnoreFirstRender"

interface Props<Data> {
    data: Data
}

export interface UseChartData<Data> {
    data: Data
}

export const useChartData = <Data>({ data }: Props<Data>): UseChartData<Data> => {
    const [dataForDisplay, setDataForDisplay] = useState<Data>(data)
    useEffectIgnoreFirstRender(() => {
        setDataForDisplay(data)
    }, [data])
    return {
        data: dataForDisplay
    }
}
