import AppBar from '@mui/material/AppBar'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar'
import { NovaColours } from '@novafuturltd/core'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      padding: theme.spacing(0, 2),
      minHeight: theme.spacing(9),
      backgroundColor: NovaColours.S010[theme.palette.mode],
    },
    appBar: {
      boxShadow: 'none',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
)

export const AppHeader: FunctionComponent = ({ children }) => {
  const classes = useStyles({})
  return (
    <AppBar
      data-testid="app-bar"
      position="fixed"
      color="inherit"
      className={clsx(classes.appBar, {})}
    >
      <Toolbar className={classes.toolBar} data-testid="app-toolbar">
        {children}
      </Toolbar>
    </AppBar>
  )
}
