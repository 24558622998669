import { useEffect, useRef } from 'react'

export const useEffectIgnoreFirstRender = (fn: Function, inputs: any[]) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      fn()
    } else {
      didMountRef.current = true
    }
  }, inputs)
}
