import { Box, Button, IconButton, Paper, Snackbar, Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close'
import { Alert } from '@mui/material';
import { NovaColours } from '@novafuturltd/core'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    standardSuccess: {
      backgroundColor: NovaColours.RP010[theme.palette.mode],
      color: NovaColours.RP080[theme.palette.mode],
      '& svg': {
        fill: NovaColours.RP050[theme.palette.mode],
      },
    },
    standardError: {
      backgroundColor: NovaColours.RN010[theme.palette.mode],
      color: NovaColours.RN080[theme.palette.mode],
      '& svg': {
        fill: NovaColours.RN080[theme.palette.mode],
      },
    },
    customAction: {
      backgroundColor: NovaColours.S000[theme.palette.mode],
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      color: NovaColours.N040[theme.palette.mode],
      borderRadius: '6px',
      paddingLeft: theme.spacing(3),
      fontSize: '18px',
      fontWeight: 500,
      justifyContent: 'space-between',
      padding: theme.spacing(1.25),
      minWidth: '435px',
      height: '56px',
      top: '60px',
      zIndex: 9999,
    },
    customActionButton: {
      margin: '0px !important',
      paddingRight: theme.spacing(1.375),
    },
  }),
)

export interface TActionButtonProps {
  label: string
  callBack: () => void
  icon: React.ReactNode
}

interface Props {
  type?: 'error' | 'info' | 'warning' | 'success'
  open: boolean
  onClose: () => void
  message?: string
  actionButtonProps?: TActionButtonProps
}

export const Notification: FC<Props> = ({
  type,
  open,
  onClose,
  message,
  actionButtonProps,
}) => {
  const classes = useStyles({})
  const theme = useTheme()
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      open={open}
      onClose={onClose}
    >
      {actionButtonProps ? (
        <Paper className={classes.customAction} elevation={5}>
          <Box>
            <p>{message}</p>
          </Box>
          <Box
            style={{
              paddingLeft: '96px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={actionButtonProps.callBack}
              startIcon={actionButtonProps.icon}
              className={classes.customActionButton}
            >
              {actionButtonProps?.label}
            </Button>

            <IconButton style={{ marginLeft: theme.spacing(1.125) }} size="large">
              <CloseIcon onClick={onClose} />
            </IconButton>
          </Box>
        </Paper>
      ) : (
        <Alert
          classes={{
            standardSuccess: classes.standardSuccess,
            standardError: classes.standardError,
          }}
          severity={type}
        >
          {message}
        </Alert>
      )}
    </Snackbar>
  );
}
