import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

const drawerWidth = 240

interface Props {
  open: boolean
  hover: boolean
  isMobile: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hide: {
      display: 'none !important',
    },
    drawer: {
      position: props => (!props.isMobile ? 'relative' : 'absolute'), // completely hide on mobile but show icons when closed on desktop
      zIndex: theme.zIndex.appBar - 1,
      border: 'none',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    hideFooter: {
      height: '100% !important',
    },
    drawerOpen: {
      height: `calc(100% - calc(${theme.spacing(1)} + 64px))`,
      background: NovaColours.S000[theme.palette.mode],
      width: drawerWidth,
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: '0.3s',
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      display: (props: Props) =>
        !props.open && !props.hover && props.isMobile ? 'none' : 'block',
      height: `calc(100% - calc(${theme.spacing(5)} + 64px))`,
      background: NovaColours.S000[theme.palette.mode],
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: '0.35s',
      }),
      overflowX: 'hidden',
      width: theme.spacing(6.25),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7.5) + 1,
      },
    },
    drawerHover: {
      height: `calc(100% - 10px)`,
      paddingBottom: theme.spacing(6.25),
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: '0.3s',
      }),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 0.5),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      background: NovaColours.N010[theme.palette.mode],
    },
  }),
)

export default useStyles
