import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { FC, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import {
  ActionButton,
  CancelButton,
} from '..'
import { useConfirmDialogStyles } from './styles'

export { useConfirmDialogStyles }

interface Props {
  title: string
  content: string | ReactNode
  extraContent: string | ReactNode
  open: boolean
  setOpen: Function
  onConfirm: Function
  confirmText?: string
  keepModalOpenAfterConfirm?: boolean
  confirmIcon?: ReactNode | undefined
  usePrimaryStylesForConfirm?: boolean
  cancelNotRequired?: boolean
}

export const ConfirmDialog: FC<Props> = ({
  title,
  content,
  extraContent,
  open,
  setOpen,
  confirmText,
  onConfirm,
  keepModalOpenAfterConfirm,
  confirmIcon,
  usePrimaryStylesForConfirm,
  cancelNotRequired,
}) => {
  const intl = useIntl()
  const classes = useConfirmDialogStyles({ usePrimaryStylesForConfirm })
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      aria-describedby="alert-dialog-description"
      data-test="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        <div className={classes.title}>{title}</div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {extraContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!cancelNotRequired && (
          <CancelButton onClick={() => setOpen(false)}>
            {intl.formatMessage({ id: 'dialog.option.cancel' })}
          </CancelButton>
        )}
        <ActionButton
          startIcon={confirmIcon}
          variant={usePrimaryStylesForConfirm ? 'contained' : undefined}
          color={usePrimaryStylesForConfirm ? 'primary' : undefined}
          data-test="confirm-button"
          onClick={() => {
            onConfirm()
            if (keepModalOpenAfterConfirm) {
              return
            }
            setOpen(false)
          }}
          autoFocus={true}
        >
          {confirmText
            ? confirmText
            : intl.formatMessage({ id: 'dialog.option.confirm' })}
        </ActionButton>
      </DialogActions>
    </Dialog>
  )
}
