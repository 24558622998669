import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { FC } from 'react'

export interface INovaButton {
  loading?: boolean
  onClick: (e: any) => void
  style?: { [key: string]: any }
}

export const NovaButton: FC<INovaButton & ButtonProps> =({ loading, ...props}) => {
  const isLoading = loading && loading === true
  const startIcon = isLoading ? (
    <CircularProgress
      style={{ height: '1rem', width: '1rem', fill: 'white' }}
    />
  ) : (
    props.startIcon
  )

  const onClick = isLoading ? () => ({}) : props.onClick

  return (
    <Button
      {...props}
      onClick={onClick}
      startIcon={props.disabled ? props.startIcon : startIcon}
    >
      {props.children}
    </Button>
  )
}
