import { SvgIconTypeMap, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconProps } from '@mui/material/SvgIcon'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import SearchIcon from '@mui/icons-material/Search'
import React from 'react'

// height of the TextField
const height = 48

// magic number which must be set appropriately for height

interface IsIcons {
  [key: string]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}
const icons: IsIcons = {
  save: SaveIcon,
  search: SearchIcon,
  clear: ClearIcon,
  add: AddIcon,
}

const FieldIcon: React.FC<SvgIconProps> = props => {
  let Icon = null
  if (props.name) {
    Icon = icons[props.name]
    Icon = Icon && <Icon {...props} />
  }
  return <>{Icon}</>
}

interface IProps {
  iconName?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '&.outlined': {
        padding: theme.spacing(1.8),
      },
    },
    label: {
      textOverflow: 'ellipsis !important',
      transform: `translate(${theme.spacing(1.8)}, ${theme.spacing(
        2,
      )}) scale(1);`,
      '&.shrink': {
        transform: `translate(${theme.spacing(1.8)}, -${theme.spacing(
          0.5,
        )}) scale(0.75);`,
      },
    },
  }),
)
const BasicTextFields: React.FC<TextFieldProps & IProps> = React.forwardRef(
  ({ iconName = '', ...props }, ref) => {
    const classes = useStyles({})
    return (
      <TextField
        variant="standard"
        ref={ref}
        {...props}
        InputLabelProps={{
          classes: {
            shrink: 'shrink',
            root: classes.label,
          },
        }}
        inputProps={{
          style: {
            padding: '13.5px 14px',
          },
          ...props.inputProps,
        }}
        InputProps={{
          style: {
            height: props.multiline ? undefined : height,
            padding: '0 14px',
          },
          endAdornment:
            (iconName.length > 0 && <FieldIcon name={iconName} />) ||
            props?.InputProps?.endAdornment,
          ...props.InputProps,
        }}
      />
    )
  },
)

export { BasicTextFields }
