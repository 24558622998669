import { FC } from 'react'

interface SupportIconProps {
  className?: string
  height?: string
  width?: string
}

export const SupportIcon: FC<SupportIconProps> = ({
  className,
  height,
  width,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height ? height : '28'}
      width={width ? width : '28'}
    >
      <path
        className={className}
        d="M40 17.95Q38.6 14.55 36.025 11.975Q33.45 9.4 30.05 8L27 15.5Q28.9 16.15 30.3 17.575Q31.7 19 32.5 21.05ZM18.05 8Q14.3 9.55 11.7 12.175Q9.1 14.8 8 18.1L15.45 21.1Q16.25 19.05 17.7 17.6Q19.15 16.15 21.05 15.5ZM8 29.95Q9.55 33.5 12.1 36.1Q14.65 38.7 17.95 40L21.1 32.5Q19.2 31.85 17.725 30.425Q16.25 29 15.5 26.95ZM30.05 40Q33.5 38.6 36.05 36.025Q38.6 33.45 40 30.05L32.5 26.95Q31.75 29.05 30.275 30.475Q28.8 31.9 26.9 32.5ZM24 44Q19.85 44 16.2 42.425Q12.55 40.85 9.85 38.15Q7.15 35.45 5.575 31.8Q4 28.15 4 24Q4 19.85 5.575 16.2Q7.15 12.55 9.85 9.85Q12.55 7.15 16.2 5.575Q19.85 4 24 4Q28.15 4 31.8 5.575Q35.45 7.15 38.15 9.85Q40.85 12.55 42.425 16.2Q44 19.85 44 24Q44 28.15 42.425 31.8Q40.85 35.45 38.15 38.15Q35.45 40.85 31.8 42.425Q28.15 44 24 44ZM24 30Q26.5 30 28.25 28.25Q30 26.5 30 24Q30 21.5 28.25 19.75Q26.5 18 24 18Q21.5 18 19.75 19.75Q18 21.5 18 24Q18 26.5 19.75 28.25Q21.5 30 24 30Z"
      />
    </svg>
  )
}
