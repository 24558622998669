import { FC } from 'react'

import {
  Column,
  CustomTreeData,
  Row,
  SelectionState,
  TreeDataState,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  Table,
  TableColumnReordering,
  TableHeaderRow,
  TableSelection,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui'
import { IconButton, Paper, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import clsx from 'clsx'
import React from 'react'

import { ActionColumns } from '../../../helpers/grids/action.column'
import { LoadingOverlay } from '../../../helpers/grids/loading.spinner'

import { NovaColours } from '@novafuturltd/core'

import { NumberFormatterProvider } from '../../..'
import { useColumnReorder } from '../default/hooks/useColumnReorder'

const ROOT_ID = null

interface ActionColumn {
  columnName: string
  callBack: (props: any) => JSX.Element
}

interface Props {
  columns: Column[]
  columnOrderKey?: string
  rows: object[]
  columnExtensions?: any[]
  actionColumn?: ActionColumn
  loading?: boolean
  expandedRowIds?: string[]
  onExpandedRowIdsChange?: any
  tableTreeColumnFor: string
  checkboxSelect?: {
    enabled: boolean
    selectionState?: any
    setSelectionState?: any
  }
  rowComponent?: Row
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
      position: 'relative',
    },
  }),
)

const TableComponentBase = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    data-testid="tree-table"
    style={{ tableLayout: 'initial', overflow: 'hidden' }}
  />
)

const DataOnDemandTreeTable: FC<Props> = ({
  children,
  rows,
  columns,
  columnOrderKey,

  actionColumn,
  columnExtensions,
  loading,
  expandedRowIds,
  onExpandedRowIdsChange,
  tableTreeColumnFor,
  checkboxSelect,
  rowComponent,
}) => {
  const columnOrderEnabled = !!columnOrderKey
  const { columnOrder, setColumnOrder } = useColumnReorder(
    columns,
    columnOrderKey,
  )

  const classes = useStyles({})

  const defaultColumnExtensions = columns
    .filter((col: any) => col?.format === 'number')
    .map((col: any) => {
      return {
        columnName: col.name,
        align: 'right',
      }
    })

  const allColumnExtensions =
    columnExtensions && columnExtensions?.length > 0
      ? [...defaultColumnExtensions, ...columnExtensions]
      : defaultColumnExtensions

  const columnsWithNumberFormatter = columns
    .filter((col: any) => col?.format === 'number')
    .map((col: any) => col.name)

  const RowComponent = (props: any) => <Table.Row {...props} />

  const getRowId = (row: any) => row.id

  // tslint:disable-next-line: no-shadowed-variable
  const getChildRows = (row: any, rootRows: any) => {
    const childRows = rootRows.filter(
      (r: any) => r.parent === (row ? row.id : ROOT_ID),
    )
    if (childRows.length) {
      return childRows
    }
    return row && row?.has_items ? [] : null
  }

  interface ExpandButtonProps {
    /** Specifies whether to show the button. */
    visible: boolean
    /** Specifies whether a row is expanded. */
    expanded: boolean
    /** An event that initiates row expanding or collapsing. */
    onToggle(): void
  }

  const treeExtend = ({ visible, expanded, onToggle }: ExpandButtonProps) => {
    // tslint:disable-next-line: no-shadowed-variable
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        icon: {
          marginRight: theme.spacing(1),
        },
        IconButton: {
          padding: theme.spacing(0.125),
          marginRight: theme.spacing(1),
        },
        hidden: {
          visibility: 'hidden',
        },
      }),
    )
    // tslint:disable-next-line: no-shadowed-variable
    const classes = useStyles({})
    if (!visible) {
      return (
        <IconButton
          className={clsx(classes.IconButton, {
            [classes.hidden]: true,
          })}
          onClick={onToggle}
          color="inherit"
          size="large"
        >
          <ArrowDropDownIcon />
        </IconButton>
      )
    }
    if (expanded) {
      return (
        <>
          <IconButton
            className={classes.IconButton}
            onClick={(e: any) => {
              e.stopPropagation()
              return onToggle()
            }}
            color="inherit"
            size="large"
          >
            <ArrowDropDownIcon />
          </IconButton>
        </>
      )
    }
    return (
      <>
        <IconButton
          className={classes.IconButton}
          onClick={(e: any) => {
            e.stopPropagation()
            return onToggle()
          }}
          color="inherit"
          size="large"
        >
          <ArrowRightIcon />
        </IconButton>
      </>
    )
  }

  const checkboxRow = ({ ...props }: any) => {
    // tslint:disable-next-line: no-shadowed-variable
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        unselectableRow: {
          cursor: 'not-allowed',
        },
        selectableRow: {
          cursor: 'pointer',
          // tslint:disable-next-line: no-shadowed-variable
          backgroundColor: (props: any) =>
            props.highlighted && NovaColours.N090[theme.palette.mode],
        },
      }),
    )
    // tslint:disable-next-line: no-shadowed-variable
    const classes = useStyles({
      highlighted: checkboxSelect?.selectionState.id === props.tableRow.row.id,
    })
    if (!props.tableRow.row.is_folder) {
      return <Table.Row {...props} className={classes.unselectableRow} />
    }
    return (
      <Table.Row
        {...props}
        onClick={() => checkboxSelect?.setSelectionState(props.tableRow.row)}
        className={classes.selectableRow}
      />
    )
  }

  return (
    <Paper
      data-testid="tree-table-test-id"
      elevation={0}
      className={classes.root}
      style={{ position: 'relative' }}
    >
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        {checkboxSelect?.enabled && <SelectionState />}
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={onExpandedRowIdsChange}
        />
        <CustomTreeData getChildRows={getChildRows} />
        <Table
          tableComponent={TableComponentBase}
          columnExtensions={allColumnExtensions}
          rowComponent={rowComponent ? rowComponent : RowComponent}
        />
        {columnOrderEnabled && <DragDropProvider />}
        <TableHeaderRow />
        <NumberFormatterProvider for={columnsWithNumberFormatter} />
        <TableTreeColumn
          for={tableTreeColumnFor}
          expandButtonComponent={treeExtend}
        />
        {checkboxSelect?.enabled && (
          <TableSelection
            rowComponent={checkboxRow}
            showSelectionColumn={false}
            highlightRow={true}
          />
        )}
        {columnOrderEnabled && (
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
          />
        )}
        <LoadingOverlay loading={loading} />
        {actionColumn && (
          <ActionColumns
            actionColumns={[
              {
                columnName: actionColumn.columnName,
                render: actionColumn.callBack,
              },
            ]}
          />
        )}
        {children}
      </Grid>
    </Paper>
  )
}

export { DataOnDemandTreeTable }
