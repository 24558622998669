import { TableColumnWidthInfo } from '@devexpress/dx-react-grid'
import { useEffect, useMemo, useState } from 'react'
import { TColumn } from '..'
import { KEY_COLUMN_RESIZING_KEY_PREFIX } from '../Contstants'
import { getLocalStorageValues } from '../helpers/getLocalStorageValues'

interface Props {
  columns: TColumn[]
  columnDefaultOverrides?: ColumnSizesMemory
  localStorageKey?: string
}

interface UseColumnResizing {
  columnWidths: TableColumnWidthInfo[]
  onColumnWidthsChange: (columnWidths: TableColumnWidthInfo[]) => void
  resizingMode: 'nextColumn' | 'widget'
}

export type ColumnSizesMemory = Record<string, number>

const useColumnResizing = ({
  columns,
  columnDefaultOverrides,
  localStorageKey,
}: Props): UseColumnResizing => {
  const key: string | undefined = useMemo(() => {
    if (localStorageKey) {
      return `${KEY_COLUMN_RESIZING_KEY_PREFIX}${localStorageKey}`
    } else {
      return
    }
  }, [localStorageKey])
  const localStorageState = useMemo(() => {
    if (key) {
      return getLocalStorageValues<ColumnSizesMemory>(key)
    } else {
      return
    }
  }, [key])
  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(
    !localStorageState
      ? columns.map((e: TColumn) => ({
          columnName: e.name,
          width: 200,
        }))
      : columns.map((e: TColumn) => ({
          columnName: e.name,
          width: localStorageState[e.name] || columnDefaultOverrides?.[e.name] || 200,
        })),
  )
  const onColumnWidthsChange = (newColumnWidths: TableColumnWidthInfo[]) => {
    setColumnWidths(newColumnWidths)
  }
  useEffect(() => {
    // local storage set item with new column widths
    if (key && typeof window !== 'undefined') {
      localStorage.setItem(
        key,
        JSON.stringify(
          columnWidths.reduce(
            (acc, e) => ({ ...acc, [e.columnName]: e.width }),
            {},
          ),
        ),
      )
    }
  }, [columnWidths, key])
  return {
    columnWidths,
    onColumnWidthsChange,
    resizingMode: 'widget',
  }
}

export default useColumnResizing
