import { SortDir, useBrowserQuery } from './use.browserQuery'

export const useSortingState = (defaultSorting?: [string, string][]) => {
  const [query, setQuery, path] = useBrowserQuery()
  const sortingState = query.sorting

  const findSortingIndexByColumnName = (columnName: string) =>
    query.sorting.findIndex(
      ([itemColName, _]: [string, string]) => itemColName === columnName,
    )

  const removeSortingColumn = (columnName: string) => {
    const updatedSorting = [...query.sorting]
    updatedSorting.splice(findSortingIndexByColumnName(columnName), 1)

    return setQuery(
      {
        ...query,
        sorting: updatedSorting,
      },
      path,
    )
  }

  const addOrUpdateSortingColumn = ([columnName, direction]: [
    string,
    string,
  ]) => {
    let updatedSorting = [...query.sorting]
    const existingIndex = findSortingIndexByColumnName(columnName)

    if (existingIndex !== -1) {
      updatedSorting[findSortingIndexByColumnName(columnName)] = [
        columnName,
        direction.toUpperCase() as SortDir,
      ]
    } else {
      updatedSorting = [
        ...updatedSorting,
        [columnName, direction.toUpperCase() as SortDir
        ],
      ]
    }
    return setQuery(
      {
        ...query,
        sorting: updatedSorting,
        page: 1,
      },
      path,
    )
  }

  const singleSort = (column: [string, string], page?: any) => {
    setQuery(
      {
        ...query,
        sorting: [[column[0], column[1].toUpperCase()]],
        page: page ? page : null,
      },
      path,
    )
  }

  const clearAllSorting: () => void = () => {
    const { sorting, ...restOfQuery } = query
    return setQuery({
      ...restOfQuery,
      sorting: defaultSorting ? defaultSorting : [],
    })
  }

  const defaultReturnValues = {
    removeSortingColumn,
    addOrUpdateSortingColumn,
    singleSort,
    clearAllSorting,
  }

  if (defaultSorting && defaultSorting.length > 0) {
    return {
      sortingState: sortingState.length > 0 ? sortingState : defaultSorting,
      ...defaultReturnValues,
    }
  }
  return {
    sortingState,
    ...defaultReturnValues,
  }
}
