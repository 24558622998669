import { FC } from 'react'

import {
  Column,
  CustomPaging,
  CustomTreeData,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  Sorting,
  SortingState,
  TreeDataState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui'
import { IconButton, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import FolderIcon from '@mui/icons-material/Folder'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import clsx from 'clsx'
import React from 'react'
import { ActionColumns } from '../../../helpers/grids/action.column'

interface ActionColumn {
  columnName: string
  callBack: (props: any) => JSX.Element
}

interface Props {
  columns: Column[]
  rows: object[]
  showSortingControls?: boolean
  pagination?: any
  columnExtensions?: Table.ColumnExtension[]
  defaultSorting?: Sorting[]
  allowedPageSizes?: number[]
  actionColumn?: ActionColumn
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
      position: 'relative',
    },
  }),
)

const TableComponentBase = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    data-testid="tree-table"
    style={{ tableLayout: 'initial' }}
  />
)

const TreeTable: FC<Props> = ({
  children,
  rows,
  columns,
  actionColumn,
  defaultSorting,
  pagination,
  allowedPageSizes,
  columnExtensions,
  showSortingControls,
}) => {
  const classes = useStyles({})

  // tslint:disable-next-line: no-shadowed-variable
  const getChildRows = (row: any, rows: any[]) => {
    if (!row) {
      return rows
    }
    return row.dashboards
  }

  interface ExpandButtonProps {
    /** Specifies whether to show the button. */
    visible: boolean
    /** Specifies whether a row is expanded. */
    expanded: boolean
    /** An event that initiates row expanding or collapsing. */
    onToggle(): void
  }

  const treeExtend = ({ visible, expanded, onToggle }: ExpandButtonProps) => {
    // tslint:disable-next-line: no-shadowed-variable
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        icon: {
          marginRight: theme.spacing(1),
        },
        IconButton: {
          padding: theme.spacing(0.125),
          marginRight: theme.spacing(1),
        },
        hidden: {
          visibility: 'hidden',
        },
      }),
    )
    // tslint:disable-next-line: no-shadowed-variable
    const classes = useStyles({})
    if (!visible) {
      return (
        <IconButton
          className={clsx(classes.IconButton, {
            [classes.hidden]: true,
          })}
          onClick={onToggle}
          color="inherit"
          size="large">
          <ArrowDropDownIcon />
        </IconButton>
      );
    }
    if (expanded) {
      return <>
        <IconButton
          className={classes.IconButton}
          onClick={onToggle}
          color="inherit"
          size="large">
          <ArrowDropDownIcon />
        </IconButton>
        <FolderOpenIcon className={classes.icon} />
      </>;
    }
    return <>
      <IconButton
        className={classes.IconButton}
        onClick={onToggle}
        color="inherit"
        size="large">
        <ArrowRightIcon />
      </IconButton>
      <FolderIcon className={classes.icon} />
    </>;
  }

  return (
    <Paper
      data-testid="tree-table-test-id"
      elevation={0}
      className={classes.root}
    >
      <Grid rows={rows} columns={columns}>
        <SortingState defaultSorting={defaultSorting} />
        <IntegratedSorting />
        <TreeDataState />
        {pagination && (
          <PagingState
            currentPage={pagination.page - 1}
            // onCurrentPageChange={setCurrentPage}
            pageSize={pagination.per_page}
            // onPageSizeChange={changePageSize}
          />
        )}
        {pagination && <CustomPaging totalCount={pagination.total} />}
        {pagination && <IntegratedPaging />}
        <CustomTreeData getChildRows={getChildRows} />
        <Table
          tableComponent={TableComponentBase}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow showSortingControls={showSortingControls} />
        <TableTreeColumn for="title" expandButtonComponent={treeExtend} />
        {pagination && <PagingPanel pageSizes={allowedPageSizes} />}
        {actionColumn && (
          <ActionColumns
            actionColumns={[
              {
                columnName: actionColumn.columnName,
                render: actionColumn.callBack,
              },
            ]}
          />
        )}
        {children}
      </Grid>
    </Paper>
  )
}

TreeTable.defaultProps = {
  allowedPageSizes: [10, 25, 50],
  showSortingControls: true,
}

export { TreeTable }
