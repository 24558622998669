import { useMutation } from 'react-query'
import { updateUserSettings as updateUserSettingsK8 } from '../../../api/nHub/userPreference/indexk8'

import { useUserSettings } from '../../UserSettings/context'

const useUpdateUserSettingsParams = () => {
  const {
    query: { refetch },
  } = useUserSettings()
  const updateUserSettingsParamsURL = updateUserSettingsK8
  return useMutation(
    ({ body }: { body: { params: { [key: string]: any } } }) =>
      updateUserSettingsParamsURL(body),
    {
      onSuccess: () => {
        refetch()
      },
    },
  )
}

export default useUpdateUserSettingsParams
