import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enGBLocale from 'date-fns/locale/en-GB'
import dayjs from 'dayjs'
import React, { Dispatch, SetStateAction } from 'react'
import DatePicker from './date.picker'
import DateTimePicker from './date.time.picker'
import RangeDateTimePicker from './range.date.time.picker'

export interface DateTimePickerProps {
  disabled?: boolean
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  date: dayjs.Dayjs | null
  handleDateChange: (props: any) => void
  onAccept?: (props: any) => void
  dateLabel?: string
  placeholder?: string
  width?: string
}

export const DateTimePickerDialog: React.FC<DateTimePickerProps> = ({
  disabled,
  open,
  setOpen,
  onAccept,
  date,
  handleDateChange,
  dateLabel,
  placeholder,
}) => {
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={enGBLocale}
        dateFormats={{ fullTime24h: 'YYYY/MM/DD HH:mm' }}
      >
        <DateTimePicker
          open={open}
          setOpen={setOpen}
          date={date}
          onAccept={onAccept}
          handleDateChange={handleDateChange}
          dateLabel={dateLabel}
          placeholder={placeholder}
          width="100%"
          disabled={disabled}
        />
      </LocalizationProvider>
    </>
  )
}

export enum CustomDateTimePickerDateEnum {
  startDate = 'start_date',
  endDate = 'end_date',
}

export interface RangeDateTimePickerProps {
  handleDateChange: (date: any, type: string) => void
  startDateLabel?: string
  endDateLabel?: string
  watch: any
  register: any
  disablePast?: boolean
  disableFuture?: boolean
  disablePicker?: boolean
  minDate?: Date
  pickerType?: 'dialog' | 'inline' | 'static' | undefined
  name: {
    startDate: string
    endDate: string
  }
  strictCompareDates?: boolean
  minDateMessage?: string
  onAcceptEndDate?: any
  errors?: any
  defaultValues?: { startDate: null | string; endDate: null | string }
}

export const RangeDateTimePickerDialog: React.FC<RangeDateTimePickerProps> = ({
  startDateLabel,
  endDateLabel,
  watch,
  handleDateChange,
  register,
  disablePast,
  disableFuture,
  disablePicker,
  minDate,
  pickerType,
  name,
  strictCompareDates,
  minDateMessage,
  onAcceptEndDate,
  errors,
  defaultValues,
}) => {
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={enGBLocale}
      >
        <RangeDateTimePicker
          disablePast={disablePast}
          disableFuture={disableFuture}
          handleDateChange={handleDateChange}
          startDateLabel={startDateLabel}
          endDateLabel={endDateLabel}
          register={register}
          watch={watch}
          disablePicker={disablePicker}
          minDate={minDate}
          pickerType={pickerType}
          name={name}
          strictCompareDates={strictCompareDates}
          minDateMessage={minDateMessage}
          onAcceptEndDate={onAcceptEndDate}
          errors={errors}
          defaultValues={defaultValues}
        />
      </LocalizationProvider>
    </>
  )
}

export interface DatePickerProps {
  handleDateChange: (date: any, type: string) => void
  startDatePlaceholder?: string
  label?: string
  endDateLabel?: string
  watch: any
  register: any
  disablePast?: boolean
  disablePicker?: boolean
  minDate?: Date
  pickerType?: 'dialog' | 'inline' | 'static' | undefined
  name: string
}

export const DatePickerDialog: React.FC<DatePickerProps> = ({
  startDatePlaceholder,
  label,
  watch,
  handleDateChange,
  register,
  disablePast,
  disablePicker,
  minDate,
  pickerType,
  name,
}) => {
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={enGBLocale}
      >
        <DatePicker
          disablePast={disablePast}
          handleDateChange={handleDateChange}
          label={label}
          startDatePlaceholder={startDatePlaceholder}
          register={register}
          watch={watch}
          disablePicker={disablePicker}
          minDate={minDate}
          pickerType={pickerType}
          name={name}
        />
      </LocalizationProvider>
    </>
  )
}
