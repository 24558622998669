import { ArgumentAxis, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartLabel: {
      fontSize: theme.typography.h3.fontSize,
    },
  }),
)

export const ArgumentLabelComponentBase = (props: any) => {
  const classes = useStyles({})
  return <ArgumentAxis.Label {...props} className={classes.chartLabel} />
}

export const ValueLabelComponentBase = (props: any) => {
  const classes = useStyles({})
  return <ValueAxis.Label {...props} className={classes.chartLabel} />
}

const labelHalfWidth = 100
let lastLabelCoordinate: any

export const ArgumentLabelComponentWithReducedLabels = (props: any) => {
  const classes = useStyles({})
  if (
    lastLabelCoordinate &&
    lastLabelCoordinate < props.x &&
    props.x - lastLabelCoordinate <= labelHalfWidth
  ) {
    return null
  }
  lastLabelCoordinate = props.x
  return <ArgumentAxis.Label {...props} className={classes.chartLabel} />
}

const labelWidth = 180

export const ArgumentLabelComponentWithMoreReducedLabels = (props: any) => {
  const classes = useStyles({})
  if (
    lastLabelCoordinate &&
    lastLabelCoordinate < props.x &&
    props.x - lastLabelCoordinate <= labelWidth
  ) {
    return null
  }
  lastLabelCoordinate = props.x
  return <ArgumentAxis.Label {...props} className={classes.chartLabel} />
}

export const XAxisLabel = ({ labelText }: { labelText: string }) => (
  <Plugin>
    <Template name="root">
      <TemplatePlaceholder />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {labelText}
      </div>
    </Template>
  </Plugin>
)

export const YAxisLabel = ({
  labelText,
  leftPositionAdjustment,
}: {
  labelText: string
  leftPositionAdjustment?: number
}) => (
  <Plugin>
    <Template name="root">
      <TemplatePlaceholder />
      <div
        style={{
          position: 'absolute',
          top: '200px',
          transform: 'rotate(-90deg)',
          left: leftPositionAdjustment
            ? `${leftPositionAdjustment}px`
            : '-30px',
        }}
      >
        {labelText}
      </div>
    </Template>
  </Plugin>
)
