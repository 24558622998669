import { InputAdornment, TextField, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import { FC } from 'react'

interface Props {
  min: number
  max: number
  fieldName?: string
  onChangeMin: (newValue: string) => void
  onChangeMax: (newValue: string) => void
  minError?: string
  maxError?: string
  genericError?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    textFieldContainer: {
      display: 'flex',
      position: 'relative',
      borderRadius: theme.spacing(0.5),
    },
    min: {
      '& fieldset': {
        border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
        borderRight: 'none',
      },
      flex: 1,
    },
    max: {
      '& fieldset': {
        border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
        borderLeft: 'none',
      },
      flex: 1,
    },
    line: {
      height: '1px',
      width: '15px',
      background: NovaColours.N010[theme.palette.mode],
      position: 'absolute',
      right: '48.8%',
      top: '28px',
      zIndex: 1,
    },
    inputAdornment: {
      color: NovaColours.AP030[theme.palette.mode],
      fontSize: '12px',
    },
    genericError: {
      backgroundColor: NovaColours.RN010[theme.palette.mode],
      fontSize: '12px',
      color: 'white',
      marginTop: '8px',
      padding: '2px',
      borderRadius: theme.spacing(0.5),
    },
    errorTypography: {
      color: 'white',
      fontSize: '12px',
      paddingLeft: '4px',
    },
  }),
)

export const MinMaxFilter: FC<Props> = ({
  min,
  max,
  fieldName,
  onChangeMin,
  onChangeMax,
  minError,
  maxError,
  genericError,
}) => {
  const classes = useStyles()
  const line = () => <div className={classes.line} />
  return (
    <div className={classes.container}>
      <div className={classes.textFieldContainer}>
        {line()}
        <TextField
          id="min-stake"
          type="number"
          error={!!minError}
          name="min"
          className={classes.min}
          // placeholder="Min"
          variant="outlined"
          size="medium"
          value={min}
          style={{ width: '100%' }}
          helperText={minError}
          onChange={({ target }) => onChangeMin(target.value)}
          InputProps={{
            style: {
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
            },
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                Min {fieldName}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="max-stake"
          type="number"
          error={!!maxError}
          className={classes.max}
          variant="outlined"
          size="medium"
          // placeholder="Max"
          value={max}
          style={{ width: '100%' }}
          helperText={maxError}
          onChange={({ target }) => onChangeMax(target.value)}
          InputProps={{
            style: {
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            },
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                Max {fieldName}
              </InputAdornment>
            ),
          }}
        />
      </div>
      {genericError && (
        <div className={classes.genericError}>
          <Typography className={classes.errorTypography}>
            {genericError}
          </Typography>
        </div>
      )}
    </div>
  )
}
