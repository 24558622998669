import {
  TExchangeRateObject,
  TExchangeRatesResponse,
} from '../api/nHub/userPreference'

interface Props {
  targetCurrency: string
  exchangeRates: TExchangeRatesResponse
  valueToExchange: {
    amount: number
    currency: string
  }
}

const currencyConverter = ({
  targetCurrency,
  exchangeRates,
  valueToExchange,
}: Props) => {
  const exchangeRateForTargetCurrency = exchangeRates.data.find(
    exchangeRate =>
      exchangeRate.currency.toLowerCase() === targetCurrency.toLowerCase(),
  )
  if (!exchangeRateForTargetCurrency) {
    return NaN
  }
  const givenValueCurrencykey = valueToExchange.currency.toLowerCase() as keyof TExchangeRateObject
  if (
    !Object.keys(exchangeRateForTargetCurrency).includes(
      givenValueCurrencykey.toLowerCase(),
    )
  ) {
    return NaN
  }
  const targetCurrencyRate =
    exchangeRateForTargetCurrency[givenValueCurrencykey]
  if (!targetCurrencyRate) {
    return 0
  }
  const result = (valueToExchange.amount * Number(targetCurrencyRate)).toFixed(
    2,
  )
  // format money with commas
  return result
}

export default currencyConverter
