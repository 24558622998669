import { TextField } from '@mui/material'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { NovaColours } from '@novafuturltd/core'
import React, { useState } from 'react'
import { DatePickerProps } from '.'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme: Theme) => {
  const container = (boxShadow = 'none') => ({
    width: (props: any) => (props.width ? props.width : '50%'),
    marginRight: '24px',
    '&:hover': {
      boxShadow: theme.palette.mode === 'light' ? boxShadow : 'none',
    },
    display: 'flex',
  })

  return createStyles({
    container: container('0px 8px 10px rgba(0, 0, 0, 0.18)'),
    containerBlueShadow: container('0px 8px 10px rgba(42, 110, 251, 0.18)'),
    containerNoShadow: container(),
    dateWidth: {
      width: '100%',
    },
    notchedOutlineStartDate: {
      borderRadius: '4px',
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    notchedOutlineStartDateFocus: {
      borderRadius: '4px',
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
    },
    outlineHover: {
      '& fieldset': {
        borderColor: `${NovaColours.N070[theme.palette.mode]} !important`,
      },
    },
    focus: {
      '& fieldset': {
        borderColor: `${NovaColours.N070[theme.palette.mode]} !important`,
      },
    },
  })
})

const DatePicker: React.FC<{
  disablePast?: boolean
  minDate?: Date
} & DatePickerProps> = ({
  handleDateChange,
  label,
  register,
  watch,
  disablePast,
  disablePicker,
  name,
}) => {
  const style = useStyles({})
  const [isFocus, setIsFocus] = useState({
    start: false,
    end: false,
  })

  const containerStyle = () => {
    if (disablePicker) {
      return style.containerNoShadow
    }
    if (isFocus.start || isFocus.end) {
      return style.containerBlueShadow
    }
    return style.container
  }

  const calcValue = () => {
    if (disablePicker) {
      return null
    }
    if (watch(name)) {
      return watch(name)
    }
    return null
  }

  const cStyle = containerStyle()

  return (
    <div className={cStyle} data-test="datePicker">
      <MuiDatePicker
        value={calcValue()}
        onChange={(value: any) => handleDateChange(value, name)}
        label={label}
        // variant={pickerType ? pickerType : 'inline'}
        // inputVariant="outlined"
        // allowKeyboardControl={true}
        // ampm={false}
        disablePast={typeof disablePast !== 'undefined' ? disablePast : true}
        // format="YYYY-MM-DD HH:mm"
        // placeholder={startDatePlaceholder}
        className={style.dateWidth}
        InputProps={{
          classes: {
            root: style.outlineHover,
            focused: style.focus,
            notchedOutline: isFocus.end
              ? style.notchedOutlineStartDateFocus
              : style.notchedOutlineStartDate,
          },
        }}
        inputRef={register(name)}
        disabled={disablePicker}
        renderInput={(props: any) => (
          <TextField
            {...props}
            onFocus={() => setIsFocus({ ...isFocus, start: true })}
            onBlur={() => setIsFocus({ ...isFocus, start: false })}
          />
        )}
      />
    </div>
  )
}

export default DatePicker
