export const debounce = (func: (...args: any[]) => void, delay: any) => {
  let timer: NodeJS.Timeout
  return (...args: any) => {
    // @ts-ignore
    const context = this
    clearTimeout(timer)
    timer = setTimeout(() => func.apply(context, args), delay)
  }
}

export const transformRawFilterState = (filterState: {
  [key: string]: any
}) => {
  return Object.keys(filterState).map(key => ({
    columnName: key,
    value: filterState[key],
  }))
}

export const transformRawSortingState: (
  sortingState: [string, string][],
) => any = sortingState => {
  return sortingState.map(sort => ({
    columnName: sort[0],
    direction: sort[1].toLowerCase(),
  }))
}

export const transformRawSortingStateObject: (
  sortingState: { [key: string]: any }[],
) => any = sortingState => {
  return sortingState.map(col => ({
    columnName: col['sort'],
    direction: col.sort_direct.toLowerCase(),
  }))
}
