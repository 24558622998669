import { AxiosPromise } from 'axios'
import { Layouts } from 'react-grid-layout'
import { Module } from '../../../containers/OrganisationSettings/types'
import client from '../../client/userPreference'

export interface TExchangeRateObject {
  currency: string
  date: string
  eur: number
  gbp: number
  usd: number
  local: number
}

export interface TExchangeRatesResponse {
  data: TExchangeRateObject[]
}

export interface Currency {
  currency: string
}

export const getLinks = () => client.get('/links')

export const createLink = ({
  url,
  color,
  display_name,
  description,
  new_window,
}: {
  url: string
  color: string
  display_name: string
  description: string
  new_window: boolean
}) =>
  client.post('/links', { url, color, display_name, description, new_window })

export const deleteLink = (linkId: string) => client.delete(`/links/${linkId}`)

export const getLayout = () => client.get('/dashboard-layout')

export const getOrgModules = (): AxiosPromise<Record<'data', Array<Module>>> =>
  client.get('/org-settings/modules')

export const getOrgModule = (name: string) =>
  client.get(`/org-settings/module/${name}`)

export const getCurrencyExchangeRates = (params?: { [key: string]: any }) =>
  client.get(`/exchange-rates`, params)

export const toggleOrgModule = (name: string, body: { status: boolean }) =>
  client.patch(`/org-settings/modules/${name}`, body)

export const getOrgSubModules = () => client.get('/org-settings/submodules')

export const getOrgSubModule = (name: string) =>
  client.get(`/org-settings/submodules/${name}`)

export const toggleOrgSubModule = (name: string, body: { status: boolean }) =>
  client.patch(`/org-settings/submodules/${name}`, body)

export const updateSubModuleParams = (
  name: string,
  params: { [key: string]: any },
) => client.patch(`/org-settings/submodules/${name}`, params)

export const createLayout = (body: { json: Layouts }) =>
  client.post('/dashboard-layout', body)

export const updateLayout = (id: string | null, body: { json: Layouts }) =>
  client.put(`/dashboard-layout/${id}`, body)

export const getUserSettings = () => client.get('/user-settings')

export const updateUserSettings = (body: { params: { [key: string]: any } }) =>
  client.patch(`/user-settings`, body)
