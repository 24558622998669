import throttle from 'lodash/throttle'
import React from 'react'
const events = new Set()
// @ts-ignore
const onResize = () => events.forEach(fn => fn())

interface IOptions {
  throttleMs?: number
}
export const useWindowSize = (options: IOptions = {}) => {
  const { throttleMs = 100 } = options
  const [size, setSize] = React.useState({
    width: process.browser ? window.innerWidth : 0,
    height: process.browser ? window.innerHeight : 0,
  })

  const handle = throttle(() => {
    setSize({
      width: process.browser ? window.innerWidth : 0,
      height: process.browser ? window.innerWidth : 0,
    })
  }, throttleMs)

  React.useEffect(() => {
    if (events.size === 0) {
      window.addEventListener('resize', onResize, true)
    }

    events.add(handle)

    return () => {
      events.delete(handle)

      if (events.size === 0) {
        window.removeEventListener('resize', onResize, true)
      }
    }
  }, [])

  return size
}

export default useWindowSize
