import { MutableRefObject, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { SIZE } from "../types";

export interface UseDivSize {
    width: number | undefined
    ref: MutableRefObject<HTMLDivElement | null>
    size: SIZE
}

export const useDivSize = (): UseDivSize => {
    const { width, ref } = useResizeDetector<HTMLDivElement>();
    const [size, setSize] = useState<SIZE>(SIZE.small)
    useEffect(() => {
        const lgThreshold = 901
        const smThreshold = 466
        if (!width) {
            return
        }
        if (width > lgThreshold) {
            setSize(SIZE.large)
        } else if (width < smThreshold) {
            setSize(SIZE.small)
        } else {
            setSize(SIZE.medium)
        }
    }, [width])
    return {
        width,
        ref,
        size,
    }
}
