import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const { NOVA_ENV } = publicRuntimeConfig

const sentrySupportedEnvs = [
  // {
  //   novaEnv: 'local',
  //   sentryEnv: 'local',
  // },
  //   {
  //     novaEnv: 'dev',
  //     sentryEnv: 'development',
  //   },
  {
    novaEnv: 'uat',
    sentryEnv: 'staging',
  },
  {
    novaEnv: 'prod',
    sentryEnv: 'production',
  },
]

const currentEnv = sentrySupportedEnvs.find(
  ({ novaEnv }) => novaEnv === NOVA_ENV,
)

export { currentEnv }
