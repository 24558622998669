import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statsCards: {
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: theme.spacing(-2),
      padding: theme.spacing(3),
      flexWrap: 'wrap',
    },
    statsCardTitle: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: '28px',
      marginBottom: theme.spacing(2),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 500,
      color: NovaColours.N030[theme.palette.mode],
    },
    statsCardData: {
      fontSize: theme.typography.h2.fontSize,
      lineHeight: '36px',
      displayFlex: 'column',
      fontWeight: 500,
      alignItems: 'flex-start',
      fontFeatureSettings: `'tnum' on, 'lnum' on`,
      letterSpacing: '-0.01em',
    },
  }),
)

export default useStyles
