import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '64px',
      width: '100%',
      zIndex: 1200,
      margin: '0 auto',
      bottom: 0,
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: NovaColours.S000[theme.palette.mode],
    },
  }),
)

export const AppFooter: FunctionComponent = ({ children }) => {
  const classes = useStyles({})

  return (
    <footer data-testid="app-footer" className={classes.root}>
      {children}
    </footer>
  )
}
