import { Grid, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    paper: {
      padding: theme.spacing(4),
    },
    onError: {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97) both`,
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: '1000px',
    },

    '@keyframes shake': {
      '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)',
      },

      '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)',
      },

      '30%, 50%, 70%': {
        transform: 'translate3d(-4px, 0, 0)',
      },

      '40%, 60%': {
        transform: 'translate3d(4px, 0, 0)',
      },
    },
  }),
)

interface Props {
  isError?: boolean
}

export const FormWrapper: FC<Props> = ({ isError, children }) => {
  const classes = useStyles({})
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      container={true}
    >
      <Grid xs={12} sm={8} md={4} lg={3} item={true}>
        <Paper
          className={clsx(classes.paper, {
            [classes.onError]: isError,
          })}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}
