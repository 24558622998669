import { Chip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CancelIcon from '@mui/icons-material/Cancel'
import { NovaColours } from '@novafuturltd/core'
import { FC } from 'react'

export interface SortChipProps {
  onDirectionChange?: (isAsc: boolean) => void
  handleDelete: () => void
  label: string
  isAsc: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    backgroundColor: NovaColours.RI010[theme.palette.mode],
    color: NovaColours.AP060[theme.palette.mode],
    fontWeight: 600,
    padding: theme.spacing(2, 0),
    paddingLeft: '8px',
    paddingRight: '8px',
    border: `1px solid ${NovaColours.AP020[theme.palette.mode]}`,
    '&:hover': {
      backgroundColor: NovaColours.RI010[theme.palette.mode],
    },
    '&:focus': {
      backgroundColor: NovaColours.RI010[theme.palette.mode],
    },
    marginLeft: theme.spacing(2),
  },
  sortIcon: {
    fill: NovaColours.AP060[theme.palette.mode],
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  deleteIcon: {
    width: theme.spacing(2),
  },
}))

export const SortChip: FC<SortChipProps> = ({
  onDirectionChange,
  handleDelete,
  label,
  isAsc,
}) => {
  const classes = useStyles({})

  const toggleDirection = () => {
    if (onDirectionChange) {
      onDirectionChange(!isAsc)
    }
  }

  const directionIcon = isAsc ? (
    <ArrowUpwardIcon className={classes.sortIcon} />
  ) : (
    <ArrowDownwardIcon className={classes.sortIcon} />
  )

  return (
    <Chip
      onClick={toggleDirection}
      className={classes.chip}
      label={label}
      icon={directionIcon}
      deleteIcon={<CancelIcon className={classes.deleteIcon} />}
      onDelete={handleDelete}
    />
  )
}
