import { Context } from '../types'
import { Strategy } from './abstract.strategy'

export default class RemoteAddressStrategy extends Strategy {
  constructor() {
    super('remoteAddress')
  }

  isEnabled(parameters: any, context: Context) {
    if (!parameters.IPs) {
      return false
    }
    return parameters.IPs.split(/\s*,\s*/).some(
      (range: string): Boolean => {
        if (range === context.remoteAddress) {
          return true
        }
        return false
      },
    );
  }
}
