// react-query call to getExchangeRates

import { AUTHORIZATION_HEADER_KEY } from '@novafuturltd/core'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import client from '../api/client'
import {
  getCurrencyExchangeRates,
  TExchangeRatesResponse,
} from '../api/nHub/userPreference'

interface Props {
  onError?: (err: TError) => void
  onSuccess?: (data: AxiosResponse<TExchangeRatesResponse>) => void
  queryParams: {
    date?: string
  }
}

export type TData = AxiosResponse<TExchangeRatesResponse>

interface TError {}

export const useGetExchangeRatesQuery = ({
  onError,
  onSuccess,
  queryParams,
}: Props) => {
  const authHeader = client.defaults.headers.common[AUTHORIZATION_HEADER_KEY]

  const query = useQuery<{}, TError, TData>(
    ['exchangeRatesHook', authHeader, JSON.stringify(queryParams)],
    () => getCurrencyExchangeRates(queryParams),
    {
      enabled: !!authHeader,
      refetchOnWindowFocus: false,
      onError,
      onSuccess,
    },
  )
  return { query }
}
