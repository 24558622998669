import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      '& .MuiSelect-select': {
        padding: '0px 0px 0px 10px',
        fontSize: '14px',
      },
      '& .MuiSelect-icon': {
        top: '0%',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    page: {
      padding: '0px 10px',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    label: {
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
    },
  }),
)

interface ICustomPagination {
  allowedPageSizes?: number[]
  pagination?: {
    page?: number
    per_page?: number
    total?: number
  }
  changePageSize?: (pageSize: number) => void
  setCurrentPage?: (page: number) => void
  defaultInitialPage?: number
}

export const CustomPagination = ({
  allowedPageSizes = [5, 10, 25, 50, 100],
  pagination,
  changePageSize,
  setCurrentPage,
  defaultInitialPage = 1,
}: ICustomPagination) => {
  const classes = useStyles()

  const RowsPerPageSelector = ({
    pageSize,
    onPageSizeChange,
    options,
  }: {
    pageSize: number
    onPageSizeChange: (pageSize: number) => void
    options: number[]
  }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={classes.label}>Rows per page:</span>
      <Select
        className={classes.select}
        value={pageSize}
        onChange={event => onPageSizeChange(Number(event.target.value))}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              position: 'absolute',
              top: 0,
              left: 0,
              transform: 'translateY(-30px)',
            },
          },
        }}
      >
        {options.map((option: number) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  )

  const handleNextPage = () => {
    setCurrentPage?.(Number(pagination?.page))
  }

  const handlePreviousPage = () => {
    pagination?.page && setCurrentPage?.(Number(pagination?.page - 2))
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
      }}
    >
      <RowsPerPageSelector
        pageSize={pagination?.per_page || 10}
        onPageSizeChange={(size: number) => changePageSize?.(size)}
        options={allowedPageSizes}
      />
      <IconButton
        disabled={pagination?.page! <= defaultInitialPage}
        onClick={handlePreviousPage}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <div className={classes.page}>
        <p>{`${Number(pagination?.page) + (1 - defaultInitialPage)}`}</p>
      </div>
      <IconButton
        disabled={
          pagination?.page! >=
          Math.ceil(pagination?.total! / pagination?.per_page!) -
            (1 - defaultInitialPage)
        }
        onClick={handleNextPage}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}
