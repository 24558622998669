import { FormControl, MenuItem, Select, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 180,
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
  select: {
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  options: string[]
  selected: string
  handleRangeChange: (value: string) => void
  handleRangeClick?: any
}

export const RangeSelector: React.FC<Props> = ({
  options,
  selected,
  handleRangeChange,
  handleRangeClick,
}) => {
  const classes = useStyles({})

  const handleChange = ({ target }: any) => {
    handleRangeChange(target.value)
  }

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          variant="standard"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selected}
          onClick={(e: any) => handleRangeClick(e.target.value, selected)}
          onChange={handleChange}
          className={classes.select}
          MenuProps={{
            variant: 'menu',
          }}
        >
          {options.map((option, index) => (
            <MenuItem value={option} key={index}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default RangeSelector
