import dayjs from 'dayjs'

import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import alfaGenerator from '../../helpers/alfaGenerator'
import { memo } from 'react'
import currencyConveter from '../../helpers/currencyConverter'
import {
  currencies,
  useCurrencyPreferenceContext,
} from '../../contexts/CurrencyPreferenceContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    numBets: {
      backgroundColor: (props: any) =>
        props
          ? alfaGenerator(
              props?.betsValue,
              props?.betsExtrema?.min_num_bets,
              props?.betsExtrema?.max_num_bets,
              NovaColours.RI040_RGB[theme.palette.mode],
            )
          : 'none',
      height: '100%',
      width: '100',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '5px',
      fontFeatureSettings: `'tnum' on, 'lnum' on`,
      letterSpacing: '-0.01em',
      fontVariantNumeric: 'tabular-nums',
    },
    numericColumns: {
      fontFeatureSettings: 'tnum',
      fontVariantNumeric: 'tabular-nums',
    },
  }),
)

export const numBetsFormatter = (extrema: any) =>
  memo(({ value }: any) => {
    const classes = useStyles({ betsValue: value, betsExtrema: extrema })
    return (
      <div className={classes.numBets}>{value?.toLocaleString('en-GB')}</div>
    )
  })

const rightAlignmentFormatterBasic = ({ value }: any) => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '5px',
      }}
    >
      {value}
    </div>
  )
}

export const rightAlignmentFormatter = memo(rightAlignmentFormatterBasic)

const DateFormatter = ({ value }: { value: any }): any =>
  dayjs(value).format('YYYY-MM-DD HH:mm')

const DateTypeProviderBasic: React.FC<any> = props => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
)

export const DateTypeProvider: React.FC<any> = memo(DateTypeProviderBasic)

const MathRoundFormatter = ({ value }: { value: number }): any => {
  const classes = useStyles({})
  return (
    <div className={classes.numericColumns}>
      {value ? value.toFixed(2) : null}
    </div>
  )
}

const Stringify = ({ value }: { value: boolean }): any =>
  value ? 'true' : 'false'

const MathRoundProviderBasic: React.FC<any> = props => (
  <DataTypeProvider formatterComponent={MathRoundFormatter} {...props} />
)

export const MathRoundProvider: React.FC<any> = memo(MathRoundProviderBasic)

const StringifyBooleanProviderBaskc: React.FC<any> = props => (
  <DataTypeProvider formatterComponent={Stringify} {...props} />
)

export const StringifyBooleanProvider: React.FC<any> = memo(
  StringifyBooleanProviderBaskc,
)

export const CurrencyFormatter = ({
  value,
}: {
  value: { amount: number; currency: string }
}) => {
  const {
    exchangeRatesQuery,
    effectiveCurrency,
  } = useCurrencyPreferenceContext()
  if (!exchangeRatesQuery?.data)
    return (
      <div>
        {currencies.find(e => e.code === value.currency)?.symbol}
        {value?.amount
          ?.toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </div>
    )
  const result = currencyConveter({
    targetCurrency: effectiveCurrency.code,
    exchangeRates: exchangeRatesQuery.data.data,
    valueToExchange: value,
  }) as string
  return (
    <div
      style={{
        fontFeatureSettings: 'tnum',
        fontVariantNumeric: 'tabular-nums',
      }}
    >
      {isNaN(Number(result))
        ? `${
            currencies.find(e => e.code === value.currency)?.symbol
          } ${value?.amount
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : result.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    </div>
  )
}
