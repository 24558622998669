import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Heading } from '..'
import NovaDrawer from '../drawer'
import { groupColumnsForFilterDrawer } from './helper'
export interface TFilterDrawerFilters {
  [key: string]: any
}

export interface TFilterColumnComponentProps {
  onChangeFilter: (key: string, value: any) => void
  onOptionSelect?: (key: string, value: any) => void
  currentValue: any
  inputName: string
  queryKey: string
  error: any
  isMultiple?: boolean
}

export interface TFilterDrawerColumn {
  group?: string
  title: string
  name: string
  queryKey?: string
  component?: FC<TFilterColumnComponentProps>
  type?: string
}

export interface FilterDrawerProps {
  defaultFilterState?: TFilterDrawerFilters
  open: boolean
  onClose: () => void
  onApplyFilters: (filterState: TFilterDrawerFilters) => void
  selectedColumns: TFilterDrawerColumn[]
  currentFilterState: TFilterDrawerFilters
  children?: (props: any) => void
  onOptionSelect?: (props: any) => void
  onReset?: (newFilterState: TFilterDrawerFilters) => void
  groupMode?: boolean
  isError?: boolean
  error?: string
  loading?: boolean
}

export const FilterDrawer: FC<FilterDrawerProps> = ({
  defaultFilterState = {},
  open,
  onClose,
  // if not dynamic, can pass in the same columns object that table recieves as opposed to object returned by useDynamicColumns
  selectedColumns,
  currentFilterState,
  onApplyFilters,
  children,
  onOptionSelect,
  onReset,
  groupMode,
  loading,
  error,
}) => {
  // transforms selectedColumns && currentFilterState in the following way
  // selectedColumns = [{title: 'columnOne'}, {title: 'columnTwo'}]
  // currentFilterState = { columnOne: 'already filtered' }
  // => { columnOne: 'already filtered', columnTwo: undefined  }
  const intl = useIntl()
  const groupedColumns = groupColumnsForFilterDrawer(selectedColumns)
  const test = () =>
    Object.keys(groupedColumns).map(group => {
      return (
        <div key={`filter-column-group-${group}`}>
          {/* <span>{group}</span> */}
          <Heading variant="h3" style={{ marginBottom: '10px' }}>
            {group}
          </Heading>
          {groupedColumns[group]
            .filter((column: TFilterDrawerColumn) => column.component)
            .map((column: TFilterDrawerColumn) => {
              return (
                <Box
                  data-test="filterDrawerInputs"
                  key={column.queryKey ? column.queryKey : column.name}
                  style={{ marginBottom: '20px' }}
                  display="flex"
                  alignItems="center"
                >
                  {column.component && (
                    <>
                      {column.component({
                        error,
                        onChangeFilter,
                        currentValue:
                          localFilterState[
                            column.queryKey ? column.queryKey : column.name
                          ],
                        inputName: column.title,
                        queryKey: column.queryKey
                          ? column.queryKey
                          : column.name,
                        onOptionSelect: (key: string, value: any) => {
                          if (onOptionSelect) {
                            return onOptionSelect(
                              filterNullValues({
                                ...localFilterState,
                                [key]: value,
                              }),
                            )
                          }
                        },
                      })}
                    </>
                  )}
                </Box>
              )
            })}
          <hr style={{ border: '1px solid #F3F4F6' }} />
        </div>
      )
    })

  const mergeColumnsAndFilterState = React.useCallback(
    () => ({
      ...selectedColumns.reduce(
        (ac, column) => ({
          ...ac,
          [column.queryKey ? String(column.queryKey) : column.name]: null,
        }),
        {},
      ),
      ...currentFilterState,
    }),
    [currentFilterState],
  )

  const [localFilterState, setLocalFilterState] = useState<
    TFilterDrawerFilters
  >({})

  const resetFilters = () => {
    setLocalFilterState(defaultFilterState)
    onReset?.(defaultFilterState)
  }

  useEffect(() => {
    setLocalFilterState(mergeColumnsAndFilterState())
  }, [currentFilterState])

  const updateLocalFilterByKey = (key: string, value: any) => {
    setLocalFilterState(currentState => {
      return { ...currentState, [key]: value }
    })
  }

  const removeLocalFilterByKey = (key: string) => {
    const { [key]: omit, ...restOfLocalFilterState } = localFilterState
    setLocalFilterState(restOfLocalFilterState)
  }

  const onChangeFilter = (key: string, value: any) => {
    if (value === null) {
      return removeLocalFilterByKey(key)
    }
    return updateLocalFilterByKey(key, value)
  }

  const filterComponents = () =>
    groupMode
      ? test()
      : selectedColumns
          .filter(column => column.component)
          .map(column => {
            return (
              <Box
                data-test="filterDrawerInputs"
                key={column.queryKey ? column.queryKey : column.name}
                style={{ marginBottom: '20px' }}
                display="flex"
                alignItems="center"
              >
                {column.component && (
                  <>
                    {column.component({
                      onChangeFilter,
                      currentValue:
                        localFilterState[
                          column.queryKey ? column.queryKey : column.name
                        ],
                      inputName: column.title,
                      queryKey: column.queryKey ? column.queryKey : column.name,
                      error,
                      onOptionSelect: (key: string, value: any) => {
                        if (onOptionSelect) {
                          return onOptionSelect(
                            filterNullValues({
                              ...localFilterState,
                              [key]: value,
                            }),
                          )
                        }
                      },
                    })}
                  </>
                )}
              </Box>
            )
          })

  const filterNullValues = (state: TFilterDrawerFilters) => {
    const filters: TFilterDrawerFilters = {}
    Object.keys(state).forEach(key => {
      if (state[key]) {
        filters[key] = state[key]
      }
    })
    return filters
  }

  // useEffect(() => {
  //   setLocalFilterState(mergeColumnsAndFilterState())
  // }, [selectedColumns])

  const theme = useTheme()

  return (
    <NovaDrawer
      open={open}
      onClose={() => {
        setLocalFilterState(mergeColumnsAndFilterState())
        onClose()
      }}
      action={{
        onClick: () => onApplyFilters(filterNullValues(localFilterState)),
        disabled: loading,
      }}
      data-testid="app-sidebar"
      anchor="right"
      headerText="Add Filters"
      headerButtons={
        <div style={{ marginLeft: theme.spacing(1) }}>
          {
            <Tooltip
              title={intl.formatMessage({
                id: 'filters.reset',
              })}
            >
              <IconButton
                data-testid="reset-filters"
                onClick={() => {
                  resetFilters()
                }}
                size="large"
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
      }
    >
      {/* {isError && (
        <div className={classes.boxBlock}>
          <Box
            className={classes.boxError}
            style={{ justifyContent: 'left' }}
            height="24px"
          >
            <InfoIcon fontSize="small" />
            <Typography variant="caption" color="error" display="block">
              {error}
            </Typography>
          </Box>
        </div>
      )} */}
      <div style={{ marginTop: '10px' }}>
        {filterComponents()}
        {children &&
          children({
            localFilterState,
            updateLocalFilterByKey,
            removeLocalFilterByKey,
          })}
      </div>
    </NovaDrawer>
  )
}
