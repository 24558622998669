import { IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import React, { forwardRef } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    menuButton: {},
    hide: {
      display: 'none',
    },
  }),
)

interface DrawerMenuProps {
  onToggleHandle: (props: any) => void
  // shows if drawer open or not
  state?: boolean
  view?: string
}

const SidebarToggle = forwardRef<HTMLButtonElement, DrawerMenuProps>(
  ({ state, onToggleHandle, view }, ref) => {
    const classes = useStyles({})
    return (
      <div>
        <IconButton
          data-testid="app-drawer"
          edge="start"
          className={classes.menuButton}
          color="inherit"
          ref={ref}
          onClick={onToggleHandle}
          aria-label="open drawer"
          size="large">
          {view === 'MobileView' && state ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
    );
  },
)

export { SidebarToggle }
