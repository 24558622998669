import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  IconButton,
  InputAdornment,
  StyledEngineProvider,
  TextField,
  Theme,
  ThemeProvider,
} from '@mui/material'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import React from 'react'
import {
  materialTheme,
  useStyles,
} from '../custom-date-time-picker/styles'
import { IDatePickerProps } from './use.date.picker'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const DatePicker: React.FC<IDatePickerProps> = ({
  errorHandler,
  date,
  handleDateChange,
  dateLabel,
  dateStructure,
  ...rest
}) => {
  const style = useStyles({})
  // const [isFocus, setIsFocus] = useState(false)
  // const containerStyle = () => {
  //   if (isFocus) {
  //     return style.containerBlueShadow
  //   }
  //   return style.container
  // }

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={materialTheme}>
      <MUIDatePicker
          {...rest}
          renderInput={(props: any) => <TextField {...props} />}
          // fullWidth={true}
          onError={errorHandler}
          value={date}
          onChange={(value: any) => handleDateChange(value)}
          // placeholder={'No Date'}
          // format={dateStructure}
          // autoOk={true}
          disableFuture={true}
          // variant="inline"
          // inputVariant="outlined"
          label={dateLabel}
          // inputProps={{
          //   'aria-label': `datepicker`,
          //   'data-testid': `datepicker`,
          // }}
          // onFocus={() => setIsFocus(true)}
          // onBlur={() => setIsFocus(false)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {date !== null && (
                  <IconButton
                    onClick={() => handleDateChange(null)}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            classes: {
              root: style.outlineHover,
              focused: style.focus,
              // notchedOutline: isFocus
              //   ? style.notchedOutlineStartDateFocus
              //   : style.notchedOutlineStartDate,
            },
          }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default DatePicker
