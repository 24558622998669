import { isEqual } from 'lodash'
import { IFeature } from '../types'

export interface RepositoryInterface {
  getToggle(name: string): IFeature | undefined
  updateData(newData: IFeature[]): boolean // return true only if data updated
}

interface RepositoryOptions {
  data: IFeature[]
}

export default class Repository implements RepositoryInterface {
  private collection: Map<string, IFeature>

  constructor({ data }: RepositoryOptions) {
    this.collection = new Map()
    data.forEach((feature: IFeature) => {
      this.addToggleFeature(feature)
    })
  }
  private addToggleFeature(newFeature: IFeature): void {
    this.validateFeature(newFeature)
    this.collection.set(newFeature.name, newFeature)
  }
  public updateData(newData: IFeature[]): boolean {
    let updated = false
    // remove expired features
    // get list of existing names
    const names = this.collection.keys()
    // for each existing name
    for (const name of names) {
      // check if it is in the new data
      if (!newData.some(newVal => newVal.name === name)) {
        // // if it's not, delete it
        this.collection.delete(name)
        updated = true
      }
    }
    // add new features
    // update updated features
    newData.forEach((newVal: IFeature) => {
      const existingToggleFeature = this.getToggle(newVal.name)
      if (!existingToggleFeature) {
        updated = true
        this.addToggleFeature(newVal)
      } else if (!isEqual(existingToggleFeature, newVal)) {
        updated = true
        this.addToggleFeature(newVal)
      }
    })
    return updated
  }

  public getToggle(name: string): IFeature | undefined {
    return this.collection.get(name)
  }

  private validateFeature(feature: IFeature) {
    const errors: string[] = []
    if (!Array.isArray(feature.strategies)) {
      errors.push(
        `feature.strategies should be an array, but was ${typeof feature.strategies}`,
      )
    }

    if (typeof feature.enabled !== 'boolean') {
      errors.push(
        `feature.enabled should be an boolean, but was ${typeof feature.enabled}`,
      )
    }

    if (errors.length > 0) {
      // const err = new Error(errors.join(', '))
      // console.error(err)
    }
  }
}
