import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import { Heading } from '../typography/heading'

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    margin: theme.spacing(0, 3, 0, 3),
    padding: '0',
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  fullWidth?: boolean
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  dialogHeading: string
  dialogHeadingSize?: any
  dialogContent: React.ReactNode
  dialogActions?: React.ReactNode
}

const Modal: React.FC<Props> = ({
  open,
  onClose,
  fullWidth,
  maxWidth,
  dialogHeading,
  dialogHeadingSize,
  dialogContent,
  dialogActions,
  ...props
}) => {
  const classes = useStyles({})
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth || 'sm'}
      {...props}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Heading variant={dialogHeadingSize || 'h2'}>{dialogHeading}</Heading>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      {dialogActions && (
        <DialogActions className={classes.dialogActions}>
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  )
}

export { Modal }
