import Autocomplete from '@mui/material/Autocomplete'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface IControlledAutocomplete {
  renderInput: any
  control: Control
  defaultValue: any[]
  name: string
  disabled?: boolean
}

// CUSTOM REUSABLE COMPONENT FOR FREESOLO/MULTI USER INPUTS - HAS BEEN WRAPPED IN R-H-F CONTROLLER TO REG FORM VALUES

export const ControlledAutocomplete: React.FC<IControlledAutocomplete> = ({
  renderInput,
  control,
  defaultValue,
  name,
  disabled,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={[]}
          multiple={true}
          disabled={disabled}
          fullWidth={true}
          disableClearable={true}
          freeSolo={true}
          id="freeSolo"
          renderInput={renderInput}
          onChange={(_, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]: any) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  )
}

export default ControlledAutocomplete
