import { TUser } from '@novafuturltd/core'
import { AxiosPromise } from 'axios'
import { TUserInformation } from '../../../types/models/user'
import client from '../../client/auth'
import { TResponse } from '@novafuturltd/shared'
import { TEmployeeInviteBody } from '../employees'

export interface TResetPasswordBody {
  password: string
}

export interface TPasswordResetBody {
  email: string
}

export interface TUpdateDetailsResponse {
  details: TUserInformation
}

export interface TGetUserResponse {
  user: TUser
}

export const resetPassword = (
  body: TPasswordResetBody,
): AxiosPromise<TResponse> => client.post('user/reset', body)

export const registerPassword = (
  token: string | undefined,
  body: TResetPasswordBody,
): AxiosPromise<TResponse> => client.post(`user/reset/${token}`, body)

export const updateDetails = (
  body: TUserInformation,
): AxiosPromise<TResponse<TUpdateDetailsResponse>> =>
  client.patch('user/details', body)

export const getInvite = (
  token: string,
): AxiosPromise<TResponse<TEmployeeInviteBody>> =>
  client.get(`user/invite/${token}`)

export const invite = (
  token: string,
  body: TUser,
): AxiosPromise<TResponse<TGetUserResponse>> =>
  client.post(`user/invite/${token}`, body)

const get = (id: string): AxiosPromise<TUser> =>
  // TODO @goughjo03 rmove the coercion to unknown here
  (client.get(`resources/users/${id}`) as unknown) as AxiosPromise<TUser>

export default get
