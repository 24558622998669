import {
  IntegratedPaging,
  PagingState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  VirtualTable as Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@mui/material/Paper'
import {
  InfinityPagingPanel,
  LoadingOverlay,
  TColumn,
} from '@novafuturltd/shared'
import React, { FC, ReactNode, RefObject, memo, useMemo } from 'react'
import { CustomPagination } from '../default/Components/CustomPagination'
import useColumnResizing from '../default/hooks/useColumnResizing'

import { useColumnReorder } from '../default/hooks/useColumnReorder'
import useStyles from './styles'

interface IInfinityPaging {
  show: boolean
  handleNextPage?: (props: any) => void
  handlePreviousPage?: (props: any) => void
  currentPage?: number
  disablePreviousButton?: boolean
  disableNextButton?: boolean
  lastPage?: boolean
  totalRows?: number
  totalUsers?: number
}

interface Props {
  columnExtensions?: Table.ColumnExtension[]
  customPagination?: boolean
  infinityPanelLeftBox?: ReactNode | undefined
  sortingExtensions?: SortingState.ColumnExtension[]
  infinityPagingPanel?: IInfinityPaging
  customTestId?: any
  defaultHiddenColumnNames?: string[]
  columnOrderKey?: string
  defaultInitialPage?: number
  sorting?: Sorting[]
  innerRef?: RefObject<HTMLTableElement>
  columns: TColumn[]
  infinityPaging?: boolean
  showColumnHeaders?: boolean
  disablePageSizes?: boolean
  setSorting?: (sorting: Sorting[]) => void
  rows: object[]
  changePageSize?: (pageSize: number) => void
  loading?: boolean
  allowedPageSizes?: number[]
  showSortingControls?: boolean
  showFilterSelector?: boolean
  rowComponent?: React.ComponentType<any>
  onCurrentPageChange?: (currPage: number) => void
  columnResizingKey?: string
  disableFiltering?: boolean
  pagination?: {
    page?: number
    per_page?: number
    total?: number
  }
}
const CellComponentBase = memo((props: any) => {
  return <Table.Cell {...props} />
})
const RowComponent = memo((props: any) => <Table.Row {...props} />)

/**
 *
 * @param columnResizingKey: if supplied, the column resizing will be enabled. This key needs to be unique for each datatable.
 * @param columnOrderKey: if supplied, the column reorder(persist) will be enabled. This key needs to be unique for each datatable.
 * @returns
 */
export const VirtualTable: FC<Props> = ({
  changePageSize,
  rowComponent,
  customPagination,
  loading,
  infinityPaging,
  disablePageSizes,
  infinityPanelLeftBox,
  sorting,
  setSorting,
  sortingExtensions,
  customTestId,
  innerRef,
  columnOrderKey,
  showSortingControls,
  rows,
  allowedPageSizes,
  defaultInitialPage = 1,
  defaultHiddenColumnNames,
  columns,
  infinityPagingPanel,
  columnResizingKey,
  pagination,
  onCurrentPageChange,
  columnExtensions,
  children,
}) => {
  const columnOrderEnabled = !!columnOrderKey
  const classes = useStyles({})

  const columnResizingEnabled = !!columnResizingKey
  const resizing = useColumnResizing({
    columns,
    localStorageKey: columnResizingKey,
  })
  const defaultColumnExtensions: Table.ColumnExtension[] = useMemo(
    () =>
      columns
        .filter((col: TColumn) => col?.format === 'number')
        .map((col: TColumn) => {
          return {
            columnName: col.name,
            align: 'right',
          }
        }),
    [columns],
  )

  const allColumnExtensions = useMemo(
    () =>
      columnExtensions && columnExtensions?.length > 0
        ? [...defaultColumnExtensions, ...columnExtensions]
        : defaultColumnExtensions,
    [columnExtensions, defaultColumnExtensions],
  )
  const { columnOrder, setColumnOrder } = useColumnReorder(
    columns,
    columnOrderKey,
  )
  return (
    <Paper
      data-testid={customTestId ? customTestId : 'virtual-table-test-id'}
      elevation={0}
      className={classes.root}
      ref={innerRef}
    >
      <Grid rows={rows} columns={columns}>
        <PagingState
          currentPage={pagination?.page ? pagination.page - 1 : 0}
          pageSize={pagination?.per_page}
          onPageSizeChange={changePageSize}
        />
        <IntegratedPaging />
        {columnOrderEnabled && <DragDropProvider />}
        <Table
          rowComponent={rowComponent ? rowComponent : RowComponent}
          columnExtensions={allColumnExtensions}
          cellComponent={CellComponentBase}
          height="calc(100vh - 380px)"
          estimatedRowHeight={40}
        />
        {columnResizingEnabled && <TableColumnResizing {...resizing} />}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingExtensions}
        />
        <TableHeaderRow showSortingControls={showSortingControls} />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        {columnOrderEnabled && (
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
          />
        )}
        <LoadingOverlay loading={loading} />
        {infinityPagingPanel?.show && pagination && (
          <InfinityPagingPanel
            totalRows={infinityPagingPanel.totalRows}
            totalUsers={infinityPagingPanel.totalUsers}
            disablePageSizes={disablePageSizes}
            pageSizes={allowedPageSizes}
            pageSize={pagination.per_page}
            currentPage={infinityPagingPanel.currentPage}
            handlePageSizeChange={(e: {
              target: { value: number | undefined }
            }) => {
              if (changePageSize) {
                changePageSize(e.target.value || pagination?.per_page || 25)
              }
            }}
            handleNextPage={infinityPagingPanel.handleNextPage}
            handlePreviousPage={infinityPagingPanel.handlePreviousPage}
            disablePreviousButton={infinityPagingPanel?.disablePreviousButton}
            infinityPanelLeftBox={infinityPanelLeftBox}
            disableNextButton={infinityPagingPanel?.disableNextButton}
            lastPage={infinityPagingPanel?.lastPage}
          />
        )}
        {children}
      </Grid>
      {!infinityPaging && customPagination && (
        <CustomPagination
          allowedPageSizes={allowedPageSizes}
          pagination={pagination}
          changePageSize={changePageSize}
          setCurrentPage={onCurrentPageChange}
          defaultInitialPage={defaultInitialPage}
        />
      )}
    </Paper>
  )
}
VirtualTable.defaultProps = {
  allowedPageSizes: [5, 10, 25, 50, 100],
  showSortingControls: true,
}
export default VirtualTable
