import { findFeatureStrategy } from '../contexts/toggle.feature.context'
import { FeatureToggle } from './feature.toggle'

export const UserFeatureOnly = ({
  toggleTitle,
  children,
}: {
  toggleTitle: string
  children: React.ReactNode
}) => {
  return (
    <FeatureToggle.On name={toggleTitle}>
      {findFeatureStrategy(toggleTitle, 'userWithId') &&
        !findFeatureStrategy(toggleTitle, 'default') &&
        children}
    </FeatureToggle.On>
  )
}
