// check as main permission validator with fail back if needed
// service/module/action
// validate by can - cannot permissions token data or special enpoint
// +- if token no additional request
// parse organisation token in organisation auth action
// add new reducer to add parsed information to store ? is how with ssr

/**
 *
 * @param {Array} perform array of permissions
 * @returns {Array} List of perm
 */
const permissionVariations = (perform: any[]): string[] => {
  // creates array of all the permission
  const output = ['*']
  perform.forEach((s: string) => {
    const parts = s.split('.')
    output.push(s)
    let start = ''
    parts.forEach((part: string) => {
      if (part !== '*') {
        output.push(`${start + part}.*`)
        start += `${part}.`
      }
    })
  })
  return output
}

/**
 * @param { Array } permissions. list of permissions of the user
 * @returns {boolean} can-cannot
 */
const check = (permissions: any, perform: any = '*'): boolean => {
  if (perform === '*') {
    return true
  }
  let perf = perform
  if (typeof perform === 'string' || perform instanceof String) {
    perf = [perform]
  }

  const canPerms = new Set<any>([].concat(permissions))
  // needs to be tested
  // main flow as child born from parens should have access to
  // simulate test flows when it will not work base on security
  // tslint:disable-next-line: no-unused-expression
  permissions &&
    permissions.length &&
    permissions.forEach((p: string) => {
      const parents = p.split('.')
      while (parents.length > 0) {
        parents.pop()
        canPerms.add([...parents].join('.'))
      }
    })

  const variations = permissionVariations(perf)

  const permCan = variations.reduce(
    (perm, variation) => perm || canPerms.has(variation),
    false,
  )

  return permCan
}

export default check
