import { IconButton } from '@mui/material'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import React, { FunctionComponent } from 'react'

interface Props {
  isDark: boolean
  onChangeThemeMode: () => void
}

export const ThemeModeSwitcher: FunctionComponent<Props> = ({
  isDark,
  onChangeThemeMode,
  ...props
}) => {
  return (
    <IconButton
      data-testid="app-test-mode-switcher"
      aria-label="label"
      aria-haspopup="true"
      color="inherit"
      onClick={onChangeThemeMode}
      {...props}
      size="large">
      {isDark ? (
        <Brightness7Icon role="dark" />
      ) : (
        <Brightness4Icon role="light" />
      )}
    </IconButton>
  );
}
