import dayjs from 'dayjs'
import React, { useRef } from 'react'

export interface IDateRangeFilterProps {
  startDate: dayjs.Dayjs | string | null
  endDate: dayjs.Dayjs | string | null
  setStartDate: (value: dayjs.Dayjs | null | string) => void
  setEndDate: (value: dayjs.Dayjs | null | string) => void
  startDateLabel: string
  endDateLabel: string
  dateStructure: string
  isFutureAllowed?: boolean
  error?: boolean
  isCustomStyle?: boolean
}

export interface IHookProps {
  setStartDate: (value: dayjs.Dayjs | null | string) => void
  setEndDate: (value: dayjs.Dayjs | null | string) => void
  dateStructure: string
}

export interface IDatePickerProps {
  setDateLimit: (
    localDateState: dayjs.Dayjs | null,
    paramsDate: dayjs.Dayjs | null,
  ) => any
  errorHandler: (error: React.ReactNode) => void
  startDate: dayjs.Dayjs | string | null
  endDate: dayjs.Dayjs | string | null
  handleStartDateChange: (date: dayjs.Dayjs | null) => void
  handleEndDateChange: (date: dayjs.Dayjs | null) => void
  startDateLabel: string
  endDateLabel: string
  dateStructure: string
  isFutureAllowed?: boolean
  error?: boolean
  isCustomStyle?: boolean
}

export const useDateRangePicker = ({
  setStartDate,
  setEndDate,
  dateStructure,
}: IHookProps): [
  (
    localDateState: dayjs.Dayjs | null,
    paramsDate: dayjs.Dayjs | null,
  ) => string | dayjs.Dayjs | null,
  (error: React.ReactNode) => void,
  (date: dayjs.Dayjs | null) => void,
  (date: dayjs.Dayjs | null) => void,
] => {
  const errorRef = useRef<any>()

  const handleStartDateChange = (date: dayjs.Dayjs | null): void => {
    if (date !== null) {
      return setStartDate(dayjs(date).format(dateStructure))
    }
    return setStartDate(null)
  }

  const handleEndDateChange = (date: dayjs.Dayjs | null): void => {
    if (date !== null) {
      return setEndDate(dayjs(date).format(dateStructure))
    }
    return setEndDate(null)
  }

  const formatCurrentValue = (
    localDateState: dayjs.Dayjs | null,
    paramsDate: dayjs.Dayjs | null,
  ): any => {
    const initialDateValue = paramsDate
      ? dayjs(paramsDate).format(dateStructure)
      : dayjs(undefined).format(dateStructure)
    const changeDateValue = localDateState
    return localDateState ? changeDateValue : initialDateValue
  }

  const errorHandler = (error: React.ReactNode) => {
    errorRef.current = true
    if (error) {
      errorRef.current = false
    }
  }

  return [
    formatCurrentValue,
    errorHandler,
    handleStartDateChange,
    handleEndDateChange,
  ]
}

export const validateDates = (
  startDate: dayjs.Dayjs | null,
  endDate: dayjs.Dayjs | null,
) => (startDate && !endDate) || (!startDate && endDate)
