import { Column } from '@devexpress/dx-react-grid'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { TColumn } from '..'
import { KEY_COLUMN_ORDER_KEY_PREFIX } from '../Contstants'
import { getLocalStorageValues } from '../helpers/getLocalStorageValues'

export const useColumnReorder = (
  columns: TColumn[],
  columnOrderKey: string | undefined,
): {
  columnOrder: string[]
  setColumnOrder: Dispatch<SetStateAction<string[]>>
} => {
  const key: string | undefined = useMemo(() => {
    if (columnOrderKey) {
      return `${KEY_COLUMN_ORDER_KEY_PREFIX}${columnOrderKey}`
    } else {
      return
    }
  }, [columnOrderKey])
  const localStorageState = useMemo(() => {
    if (key) {
      return getLocalStorageValues<string[]>(key)
    } else {
      return
    }
  }, [key])
  const [columnOrder, setColumnOrder] = useState<string[]>(
    localStorageState ? localStorageState : columns.map(e => e.name),
  )

  useEffect(() => {
    if (columns.length < 1) return // In case of columns being called from api call (not yet loaded)

    // Find columns that are not included in columnOrder array
    const newColumns = columns.filter(
      (e: Column) => !columnOrder.includes(e.name),
    )
    // Find names in columnOrder array that are not in the columns array
    const removeColumns = columnOrder.filter(
      (e: string) => !columns.map((col: Column) => col.name).includes(e),
    )
    setColumnOrder((prevState: string[]) => [
      ...prevState.filter((e: string) => !removeColumns.includes(e)),
      ...newColumns.map((e: Column) => e.name),
    ])
    // Filter removed columns from previous state. Add new column to the end of the array.
  }, [columns])

  useEffect(() => {
    if (key && typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(columnOrder))
    }
  }, [columnOrder, key])

  return {
    columnOrder,
    setColumnOrder,
  }
}
