import { Context } from '../types'

export abstract class Strategy {
  public name: string

  constructor(name: string) {
    this.name = name || 'unknown'
  }

  abstract isEnabled(parameters: any, context: Context): boolean
}
