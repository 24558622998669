import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NovaColours } from '@novafuturltd/core'
import AllCharts, {
  BarchartConfig,
  TableViewConfig,
  TimeSeriesConfig,
} from './AllCharts'
import { useDivSize } from './hooks/useDivSize'
import SelectChartDataSource from './SelectChartDataSource'
import SelectChartType, { CHART_OPTION } from './SelectChartType'
import { SIZE, SizeConfig, TChartOptions, TData, TDataArr } from './types'

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '36px',
      color: NovaColours.N030[palette.mode],
    },
    topRow: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
    },
    titleRow: {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: 1,
      justifyContent: 'space-between',
      maxWidth: '100vw',
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    fullWidth: {
      flex: '1 1 100%',
    },
    topRowButtons: {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 9,
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    selectChartType: {
      marginRight: 'auto',
    },
  }),
)

interface Props<DataPoint> {
  barchartConfig?: BarchartConfig<DataPoint>
  chartOptions: TChartOptions
  defaultGraph?: CHART_OPTION
  data?: TDataArr<DataPoint>
  error?: boolean
  graphHeight?: SizeConfig<number>
  loading: boolean
  tableViewConfig?: TableViewConfig<DataPoint>
  timeseriesConfig?: TimeSeriesConfig<DataPoint>
  title: string
}

/**
 *
 * Visualise a given collection of data.
 *
 * The generic type `DataPoint` is the type of each individual datapoint, e.g. row in table, point in time series, or bar in barchart
 *
 * @param {string} title the title for the component
 * @param {TDataArr<DataPoint>} data an array of "data sources". Data sources are objects with title and datapoints properties.
 * @param {TChartOptions} chartOptions an array of chart options. The list of charts to display. Note some options require otherwise optional configs to be passed.
 * @param {CHART_OPTION} defaultGraph the chart to display when the component renders
 * @param {boolean} error whether the api has errors, i.e. display error messages
 * @param {number} graphHeight the height of the svg graphs. this number also affects the height of loading indicators for the svg charts
 * @param {boolean} loading whether or not to show the loading state
 * @param {BarchartConfig<DataPoint>} barchartConfig configuration specific for bar chart
 * @param {TableViewConfig<DataPoint>} tableViewConfig configuration specific for table
 * @param {TimeSeriesConfig<DataPoint>} timeseriesConfig configuration specific for time series chart
 * @returns Datavisualization component
 */
const DataVisualizations = <DataPoint,>({
  barchartConfig,
  chartOptions,
  defaultGraph,
  data,
  error,
  graphHeight = {
    [SIZE.small]: 400,
    [SIZE.medium]: 500,
    [SIZE.large]: 600,
  },
  loading,
  tableViewConfig,
  timeseriesConfig,
  title,
}: Props<DataPoint>) => {
  const [currChart, setCurrChart] = useState<CHART_OPTION>(
    defaultGraph ? defaultGraph : chartOptions[0],
  )
  const [currDataSourceTitle, setCurrDataSourceTitle] = useState<string>(
    !!data && data.length > 0 ? data[0].title : '',
  )
  useEffect(() => {
    // allow for async data fetching
    if (!currDataSourceTitle || currDataSourceTitle.length < 1) {
      setCurrDataSourceTitle(!!data && data.length > 0 ? data[0].title : '')
    }
  }, [data])
  const classes = useStyles({})
  const handleDataSourceSelect = ({
    title: newDataSourceTitle,
  }: TData<DataPoint>) => {
    setCurrDataSourceTitle(newDataSourceTitle)
  }

  const activeDataSource: TData<DataPoint> = data?.find(
    e => e.title.toLowerCase() === currDataSourceTitle.toLowerCase(),
  ) as TData<DataPoint>

  const divSize = useDivSize()

  return (
    <div ref={divSize.ref}>
      <div className={classes.topRow}>
        {loading ? (
          <h2 className={`${classes.fullWidth} ${classes.titleRow}`}>
            <Skeleton variant="text" height={24} width={140} />
            <div
              style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              <Skeleton
                style={{ marginLeft: '6px', marginRight: '6px' }}
                height={36}
                width={36}
                variant="circular"
              />
              <Skeleton
                style={{ marginLeft: '6px', marginRight: '6px' }}
                height={36}
                width={36}
                variant="circular"
              />
              <Skeleton
                style={{ marginLeft: '6px', marginRight: '6px' }}
                height={36}
                width={36}
                variant="circular"
              />
            </div>
          </h2>
        ) : (
          <div className={`${classes.fullWidth} ${classes.titleRow}`}>
            <h2 className={classes.title}>{title}</h2>
            <div>
              <SelectChartType
                chartOptions={chartOptions}
                currChart={currChart}
                error={error}
                loading={loading}
                rootClassName={classes.selectChartType}
                setCurrChart={setCurrChart}
              />
            </div>
          </div>
        )}
        <div className={classes.topRowButtons}>
          <SelectChartDataSource
            data={data}
            divSize={divSize}
            handleDataSourceSelect={handleDataSourceSelect}
            loading={loading}
            selectedDataSourceTitle={currDataSourceTitle}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton data-testid="loading" height={graphHeight[divSize.size]} />
      ) : (
        <AllCharts<DataPoint>
          barchartConfig={barchartConfig}
          currChartType={currChart}
          data={activeDataSource?.dataPoints}
          divSize={divSize}
          error={error}
          graphHeight={graphHeight}
          tableViewConfig={tableViewConfig}
          timeseriesConfig={timeseriesConfig}
        />
      )}
    </div>
  )
}

export { DataVisualizations }
