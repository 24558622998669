import { TDynamicColumn } from '../types/columns'

export const groupColumns = (
  selectedColumns: TDynamicColumn[],
): { [key: string]: string[] } =>
  selectedColumns.reduce((acc: any, curr: TDynamicColumn) => {
    if (curr.group && curr.group?.name) {
      if (acc[curr.group?.name] && acc[curr.group?.name].length > 0) {
        acc[curr.group?.name].push(curr.details.name)
        return acc
      }
      acc[curr.group?.name] = [curr.details.name]
      return acc
    }
    if (curr.group === null || curr.group?.id === null) {
      if (acc['Ungrouped'] && acc['Ungrouped'].length > 0) {
        acc['Ungrouped'].push(curr.details.name)
        return acc
      }
      acc['Ungrouped'] = [curr.details.name]
      return acc
    }
    if (curr.group) {
      if (acc[curr.group.name] && acc[curr.group.name].length > 0) {
        acc[curr.group.name].push(curr.details.name)
        return acc
      }
      acc[curr.group.name] = [curr.details.name]
      return acc
    }
  }, {})

export const groupColumnsForFilterDrawer = (
  selectedColumns: { group?: string }[],
) =>
  selectedColumns.reduce((acc: any, curr: any) => {
    if (curr.group && curr.group?.name) {
      if (acc[curr.group?.name] && acc[curr.group?.name].length > 0) {
        acc[curr.group?.name].push(curr)
        return acc
      }
      acc[curr.group?.name] = [curr]
      return acc
    }
    if (curr.group === null || curr.group?.id === null) {
      if (acc['Ungrouped'] && acc['Ungrouped'].length > 0) {
        acc['Ungrouped'].push(curr)
        return acc
      }
      acc['Ungrouped'] = [curr]
      return acc
    }
    if (curr.group) {
      if (acc[curr.group] && acc[curr.group].length > 0) {
        acc[curr.group].push(curr)
        return acc
      }
      acc[curr.group] = [curr]
      return acc
    }
  }, {})
