import { useAppContext } from "@novafuturltd/core"
import { NovaColours } from '@novafuturltd/core'

interface UseChartColors {
    axesColor: string
    backgroundColor: string
    colors: string[]
    getColor: (index: number) => string
    labelColor: string
    lineColor: string
    outlineColor: string
    themeMode: string
}

export const useChartColors = (): UseChartColors => {
    const { mode: themeMode } = useAppContext()
    const colors: string[] = [
        NovaColours.WidgetColours.SPP050[themeMode],
        NovaColours.SSB050[themeMode],
        NovaColours.BRAND1[themeMode],
        NovaColours.SCR050[themeMode],
        NovaColours.ST050[themeMode],
        NovaColours.WidgetColours.SB050[themeMode],
        NovaColours.SP050[themeMode],
        NovaColours.WidgetColours.SLG050[themeMode],
        NovaColours.SY050[themeMode],
    ]
    const getColor = (index: number) => {
        let color: string = 'black'
        if (index < colors.length) { // Default to black
            color = colors[index]
        }
        return color
    }
    const backgroundColor = NovaColours.S000[themeMode]
    const outlineColor = NovaColours.N070[themeMode]
    const lineColor = themeMode === 'light' ? 'black' : 'white'
    const axesColor = NovaColours.N040[themeMode]
    const labelColor = NovaColours.N030[themeMode]
    return {
        axesColor,
        backgroundColor,
        colors,
        getColor,
        labelColor,
        lineColor,
        outlineColor,
        themeMode
    }
}
