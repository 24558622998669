import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import Link, { LinkProps } from 'next/link'
import React, { forwardRef, Ref } from 'react'

type LinkRef = HTMLAnchorElement
type NextLinkProps = Omit<MuiLinkProps, 'href' | 'classes'> &
  Pick<LinkProps, 'href' | 'as' | 'prefetch'>

const NextLinkWitRef = (
  { href, prefetch, ...props }: LinkProps,
  ref: Ref<LinkRef>,
) => (
  <Link href={href} prefetch={prefetch} passHref={true} legacyBehavior>
    <MuiLink ref={ref} {...props} underline="hover" />
  </Link>
)

export const NextLink = forwardRef<LinkRef, NextLinkProps>(NextLinkWitRef)
