import SearchIcon from '@mui/icons-material/Search'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useStyles } from './styles'

const mockGroups = [
  {
    name: 'Group Name 1',
    columns: [
      { name: 'Selection 1' },
      { name: 'Selection 2' },
      { name: 'Selection 3' },
      { name: 'Selection 4' },
    ],
  },
  {
    name: 'Group Name 2',
    columns: [
      { name: 'Selection 1' },
      { name: 'Selection 2' },
      { name: 'Selection 3' },
      { name: 'Selection 4' },
    ],
  },
  {
    name: 'Group Name 3',
    columns: [
      { name: 'Selection 1' },
      { name: 'Selection 2' },
      { name: 'Selection 3' },
      { name: 'Selection 4' },
    ],
  },
  {
    name: 'Group Name 4',
    columns: [
      { name: 'Selection 1' },
      { name: 'Selection 2' },
      { name: 'Selection 3' },
      { name: 'Selection 4' },
    ],
  },
]

const ColumnsPanel = () => {
  const [query, setQuery] = useState('')
  const classes = useStyles({})

  const renderOptions = (columns: any[]) => {
    return columns.map((opt: any) => (
      <MenuItem key={opt.name} value={opt.name}>
        <Checkbox color="primary" className={classes.check} />
        <ListItemText primary={opt.name} />
      </MenuItem>
    ))
  }

  const filtered = (arr: any[]) =>
    arr.filter(col => col.name.toLowerCase().includes(query.toLowerCase()))

  return (
    <div className={classes.container}>
      <ViewWeekIcon className={classes.icon} />
      {mockGroups.map(group => (
        <FormControl variant="standard" key={group.name} className={classes.formControl}>
          <InputLabel className={classes.label} shrink={false}>
            {group.name}
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.menu,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            multiple={true}
            className={classes.select}
            variant="outlined"
            value={group.columns}
          >
            <TextField
              key="searchOptions"
              size="small"
              label="Search..."
              value={query}
              className={classes.search}
              type="search"
              onChange={e => setQuery(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton size="large">
                    <SearchIcon style={{ fill: '#2A6EFB' }} />
                  </IconButton>
                ),
              }}
            />
            {query
              ? renderOptions(filtered(group.columns))
              : renderOptions(group.columns)}
          </Select>
        </FormControl>
      ))}
    </div>
  )
}

export default ColumnsPanel
