// This file refers to the design figma file which lists all Nova Colours and corresponding dark colours
// Each design has a colour code matching to the below
// The comment in each colour code represents the typical use for it

const NovaColours = {
  // Actions
  AP010: {
    // Hover
    light: '#F5F8FF',
    dark: '#18191F',
  },
  AP010G: {
    // Gradient
    light: '#F5F8FF',
    dark: '#18191F',
  },
  AP015: {
    // Secondary Selected
    light: '#F5F5FF',
    dark: '#1E1F2A',
  },
  AP020: {
    // Track background for switches/progress bars
    light: '#D6E3FB',
    dark: '#363E52',
  },
  AP030: {
    // Input field Text Default
    light: '#5571A5',
    dark: '#4C7EE5',
  },
  AP040: {
    // No Usage
    light: '#8398BF',
    dark: '#2A6EFB',
  },
  AP050: {
    // Low Contrast AP
    light: '#84C8FF',
    dark: '#2A6EFB',
  },
  AP060: {
    // Primary Action
    light: '#1E68FA',
    dark: '#61A0FF',
  },
  AP070: {
    // No Usage
    light: '#2A6EFB',
    dark: '#8398BF',
  },
  AP080: {
    // No Usage
    light: '#2A6EFB',
    dark: '#8398BF',
  },
  AP090: {
    // Text Input Complete
    light: '#455882',
    dark: '#D6E3FB',
  },
  AP100: {
    // Text Input Focus
    light: '#0E1B38',
    dark: '#F5F8FF',
  },
  // Naturals
  N010: {
    // High Contrast
    light: '#1D1D1D',
    dark: '#EAEAEA',
  },
  N020: {
    // Emphasis Text - highest contract
    light: '#363636',
    dark: '#C5CFD6',
  },
  N025: {
    light: '#505152',
    dark: '#BEC4C9',
  },
  N030: {
    // Main Text Colour
    light: '#606060',
    dark: '#E4E4E4',
  },
  N035: {
    light: '#6B6D70',
    dark: '#ABB3B7',
  },
  N040: {
    // Deempasis Text Colous - low contrast
    light: '#797979',
    dark: '#9BA5A7',
  },
  N045: {
    light: '#888B8E',
    dark: '#6D767A',
  },
  N050: {
    // Disabled Text and Icons
    light: '#9699A0',
    dark: '#444A51',
  },
  N055: {
    light: '#B7B9C0',
    dark: '#3A424B ',
  },
  N060: {
    // Empasis Borders, Disabled Background, Disabled Text on N090 Surface
    light: '#D5D8DC',
    dark: '#32343D',
  },
  N070: {
    // Borders
    light: '#E9E9E9',
    dark: '#2B2B32',
  },
  N080: {
    // Surface Shade
    light: '#F3F4F6',
    dark: '#1D1D20',
  },
  N090: {
    // Surface Alternate, Active Input
    light: '#FAFBFC',
    dark: '#181818',
  },
  N100: {
    // Low Contrast
    light: '#FFFFFF',
    dark: '#101012',
  },
  ST100: {
    // Text colour for surface background
    light: '#1D1D1D',
    dark: '#1D1D1D',
  },
  // Surfaces
  S000: {
    // Site Background
    light: '#F8F9FA',
    dark: '#0B0B0C',
  },
  SC000: {
    light: '#FFFFFF',
    dark: '#FFFFFF',
  },
  S010: {
    // Surface Elevation, Main Level Cards
    light: '#FFFFFF',
    dark: '#131315',
  },
  S090: {
    // Screen Overlay
    light: '#838DC3',
    dark: '#838DC3',
  },
  S100: {
    // Notification Background
    light: '#0A0B0B',
    dark: '#F8F9FA',
  },
  SG050: {
    // Green Surface Colour
    light: '#18A57B',
    dark: '#18A57B',
  },
  SB050: {
    // Blue Surface Colour
    light: '#42A5F6',
    dark: '#42A5F6',
  },
  ST050: {
    // Turqoise Surface Colour
    light: '#38E2AF',
    dark: '#72C8AE',
  },
  SLG050: {
    // Lime Green Surface Colour
    light: '#9BFA4E',
    dark: '#85C86E',
  },
  SAG050: {
    // Acid Green Surface Colour
    light: '#C6FF00',
    dark: '#A9C88A',
  },
  SPP050: {
    // Purple Surface Colour
    light: '#B180FF',
    dark: '#BE9AF9',
  },
  SSB050: {
    // Sky Blue Surface Colour
    light: '#5ED2FA',
    dark: '#9FC7E4',
  },
  SP050: {
    // Pink Surface Colour
    light: '#FF79D9',
    dark: '#FF8BDE',
  },
  SCR050: {
    // Coral Red Surface Colour
    light: '#FF5656',
    dark: '#FF6464',
  },
  SY050: {
    // Yellow Surface Colour
    light: '#FFDF39',
    dark: '#F8CF67',
  },
  // Positive Response
  RP010: {
    // Background
    light: '#F2F9F0',
    dark: '#0E1D0C',
  },
  RP050: {
    // Buttons, Stand alone objects
    light: '#55B342',
    dark: '#55B342',
  },
  RP080: {
    // Text Colour/Icons
    light: '#418334',
    dark: '#F2F9F0',
  },
  // Negative Response
  RN010: {
    // Background
    light: '#FFF1F0',
    dark: '#2A1E1E',
  },
  RN020: {
    light: '#F9D6D3',
    dark: '#4E2D2D',
  },
  RN040: {
    light: '#F35961',
    dark: '#8D1818',
  },
  RN040_RGB: {
    light: '243, 89, 97',
    dark: '141, 24, 24',
  },
  RN050: {
    // Buttons, Stand alone objects
    light: '#F5222D',
    dark: '#F5222D',
  },
  RN080: {
    // Text Colour/Icons
    light: '#C63F3F',
    dark: '#FFF1F0',
  },
  // Information Response
  RI010: {
    // Background
    light: '#E6F7FF',
    dark: '#2A3243',
  },
  RI040: {
    light: '#54ADFF',
    dark: '#1C4AAA',
  },
  RI040_RGB: {
    light: '84, 172, 255',
    dark: '28, 73, 170',
  },
  RI050: {
    // Buttons, Stand alone objects
    light: '#1890FF',
    dark: '#1890FF',
  },
  RI080: {
    // Text Colour/Icons
    light: '#33719E',
    dark: '#E6F7FF',
  },
  WidgetColours: {
    ST050: {
      light: '#38E2AF',
      dark: '#72C8AE',
    },
    SLG050: {
      light: '#9BFA4E',
      dark: '#85C86E',
    },
    SAG050: {
      light: '#C6FF00',
      dark: '#A9C88A',
    },
    SPP050: {
      light: '#B180FF',
      dark: '#BE9AF9',
    },
    SB050: {
      light: '#407EFF',
      dark: '#6C9CFF',
    },
    SSB050: {
      light: '#5ED2FA',
      dark: '#9FC7E4',
    },
    SP050: {
      light: '#FF79D9',
      dark: '#FF8BDE',
    },
    SCR050: {
      light: '#FF5656',
      dark: '#FF6464',
    },
    SY050: {
      light: '#FFDF39',
      dark: '#F8CF67',
    },
  },
  BRAND1: {
    light: '#F59C0D',
    dark: '#F59C0D',
  },
} as const

export default NovaColours
