import { SvgIcon } from '@mui/material'
import React, { FC } from 'react'

export const BetjuegoIcon: FC = () => {
  return (
    <SvgIcon>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.32959 10.2861L7.56104 21.6328L20.2809 19.2189L21.9138 6.37891L10.2014 0.85788L1.32959 10.2861ZM16.0004 9.15332H16.1308H17.0885H17.0911V15.1333C17.0911 15.4918 17.0565 15.835 16.9874 16.1579C16.9182 16.4834 16.7932 16.7656 16.6149 17.0071C16.4367 17.2487 16.1866 17.4419 15.8674 17.5817C15.5482 17.7216 15.1438 17.7928 14.6543 17.7928C14.516 17.7928 14.3644 17.7851 14.2021 17.7699C14.0398 17.7572 13.9015 17.7318 13.7871 17.7012L13.9228 16.0969C13.9973 16.1223 14.0717 16.1376 14.1489 16.1452L14.1797 16.1473C14.244 16.1516 14.3005 16.1554 14.3511 16.1554C14.6464 16.1554 14.8406 16.0588 14.939 15.8681C15.0374 15.6749 15.0853 15.4333 15.0853 15.1358V10.5822H13.2737C13.2737 10.5822 13.952 9.15332 16.0004 9.15332ZM12.2986 11.0455C12.2986 11.0455 12.952 9.5163 13.8764 9.15332H11.7971L10.6575 12.899H10.622L9.34306 9.15332H7.24609L9.6419 14.8889L9.45703 15.3678C9.36585 15.6175 9.25442 15.8105 9.12273 15.947C8.99103 16.0834 8.78083 16.1529 8.48958 16.1529C8.39841 16.1529 8.29457 16.1426 8.18314 16.1246C8.07171 16.104 7.97041 16.0808 7.8767 16.0474L7.66903 17.6641C7.80832 17.7027 7.97041 17.7336 8.15782 17.7567C8.34523 17.7799 8.52757 17.7928 8.70485 17.7928C9.06448 17.7928 9.37852 17.749 9.64444 17.6641C9.91036 17.5791 10.1408 17.4504 10.3358 17.2831C10.5308 17.1157 10.7005 16.9124 10.8423 16.6755C10.9842 16.4361 11.1133 16.1658 11.2273 15.862L13.0583 11.0789L12.2986 11.0455ZM15.6493 8.14872L14.6802 6.72497L15.6933 5.33588L17.2921 5.89844L17.2662 7.63681L15.6493 8.14872Z"
        fill="#FF9001"
      />
    </SvgIcon>
  )
}
