import { DataTypeProvider } from '@devexpress/dx-react-grid'
import dayjs from 'dayjs'
import React from 'react'
import { DefaultTable, TColumn } from '../../grids/tables/default'

const DateFormatter = ({ value }: { value: any }): any =>
  dayjs(value).format('YYYY-MM-DD HH:mm')

export const DateTypeProvider: React.FC<any> = props => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
)
export interface TableViewProps<DataPoint> {
  columns: TColumn[]
  data: DataPoint[]
  datetimeColumns?: string[]
}

const TableView = <DataPoint,>({
  columns,
  data,
  datetimeColumns
}: TableViewProps<DataPoint>) => {
  return (
    <div data-testid="data-viz-table-view">
      <DefaultTable
        disableFiltering={true}
        columns={columns}
        // TODO @goughjo03 remove the conversion to unkown here. probably should make DefaultTable accept generic types
        rows={data as unknown as object[]}
      >
        {!!datetimeColumns && <DateTypeProvider for={datetimeColumns} />}
      </DefaultTable>
    </div>
  )
}

export default TableView
