import { TOAuthTokenResponse } from '@novafuturltd/core'
import { AxiosPromise } from 'axios'
import { jwtConfig, redirectUri } from '../../../config/index'
import { TOAuthParams } from '../../../types/models/oAuth/'
import client from '../../client/auth'

export const exchangeAuthCodeForToken = (
  code: string,
): AxiosPromise<TOAuthTokenResponse> => {
  const credentials = {
    grant_type: 'authorization_code',
    client_id: jwtConfig.clientId,
    client_secret: jwtConfig.clientSecret,
    code,
    redirect_url: redirectUri,
  }

  const concatedParams = { ...credentials, code }
  const formParams = new URLSearchParams()
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(concatedParams)) {
    formParams.append(key, value)
  }

  return client.post(`oauth/token/?grant_type=authorization_code`, formParams, {
    'Content-Type': 'application/x-www-form-urlencoded',
  })
}

export const login = (
  params: TOAuthParams,
): AxiosPromise<TOAuthTokenResponse> => {
  const credentials = {
    grant_type: 'authorization_code',
    code: '',
    client_id: jwtConfig.clientId,
    client_secret: jwtConfig.clientSecret,
  }
  const concatedParams = { ...credentials, ...params }
  const formParams = new URLSearchParams()
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(concatedParams)) {
    formParams.append(key, value)
  }
  return client.post(`oauth/token`, formParams, {
    Authorization: null,
    'Content-Type': 'application/x-www-form-urlencoded',
  })
}

export const logout = (): AxiosPromise<any> =>
  client.patch('authentication/logout', {}, {})
