import engTradingMessages from '../locales/trading'
import engAdministrationMessages from '../locales/administration/en_US.json'
import enCampaignMgmntMessages from '../locales/campaign-mgmt/en_US.json'
import enExperimentsMessages from '../locales/experiments/en_US.json'
import enReportsMessages from '../locales/reports/en_US.json'
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);
import enCustomersMessages from '../locales/customers/en_US.json'
import enHrBotMessages from '../../../../modules/hr-bot/locales/en_US.json'
import toggleFeaturesMessages from '../../../../modules/toggle-features/locales/en_US.json'
import checkReleasesMessages from '../../../../modules/release-check/locales/en_US.json'
import enSimulateMessages from '../../../../modules/simulate/locales/en_US.json'
import enSDKDebuggerMessages from '../../../../modules/sdk-debugger/locales/en_US.json'
import auditCommunication from '../../../../modules/audit/locales/en_US.json'
import customersOverviewMessages from '../../../../modules/customer-overview/locales/en_US.json'
import enDashboardMessages from '../locales/dashboard/en_US.json'
import enResponsibleGamblingMessages from '../locales/responsible-gambling/en_US.json'
import enCasinoMessages from '../locales/casino/en_US.json'
import enAutomatedCampaignMessages from '../../../../modules/automated-campaigns/locales/en-US.json'
import enFraudDetectionMessages from '../../../../modules/fraud-detection/locales/en_US.json'
import {
  sharedMessages,
  enDataVizTranslations,
  enCopyToClipboardTranslations,
} from '@novafuturltd/shared'

const EnLang = {
  messages: {
    ...customersOverviewMessages,
    ...sharedMessages,
    ...engTradingMessages,
    ...engAdministrationMessages,
    ...enCampaignMgmntMessages,
    ...enExperimentsMessages,
    ...enReportsMessages,
    ...enSDKDebuggerMessages,
    ...enSimulateMessages,
    ...enCustomersMessages,
    ...enHrBotMessages,
    ...toggleFeaturesMessages,
    ...auditCommunication,
    ...enDataVizTranslations,
    ...enCopyToClipboardTranslations,
    ...enDashboardMessages,
    ...checkReleasesMessages,
    ...enResponsibleGamblingMessages,
    ...enCasinoMessages,
    ...enAutomatedCampaignMessages,
    ...enFraudDetectionMessages,
  },
  locale: 'en-US',
}
export default EnLang
