import { NovaChip, useElapsedTime } from '@novafuturltd/shared'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  timeSince: number
  dataString?: string
}

export const DisplayElapsedTime: FC<Props> = ({ timeSince, dataString }) => {
  const { whenOccured } = useElapsedTime({ timeSince })
  const { formatMessage } = useIntl()
  const string = dataString
    ? `${ dataString } - ${formatMessage({ id: 'trading.data.lastFetched' }, { whenOccured })}`
    : formatMessage({ id: 'trading.data.lastFetched' }, { whenOccured })
  return (
    <NovaChip
      size="small"
      label={string}
      variant="outlined"
    />
  )
}

export default DisplayElapsedTime
