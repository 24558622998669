import { AUTHORIZATION_HEADER_KEY } from '@novafuturltd/core'
import { AxiosResponse } from 'axios'
import { useContext } from 'react'
import { createContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import client from '../../../api/client'
import { getUserSettings as getUserSettingsK8 } from '../../../api/nHub/userPreference/indexk8'
import { captureMessage } from '../../../modules/sentry-integration'
import { UserSettingModule } from '../types'

export interface IUserSettingsContext {
  query: UseQueryResult<AxiosResponse<{ data: UserSettingModule }>, unknown>
  selectedUserCurrency: string | undefined
}

export const UserSettingsContext = createContext<IUserSettingsContext>(
  null as any,
)

export const useUserSettings = () => useContext(UserSettingsContext)

export const UserSettingsProvider = (props: { children: React.ReactNode }) => {
  const authHeader = client.defaults.headers.common[AUTHORIZATION_HEADER_KEY]

  const getUserSettingModulesUrl = getUserSettingsK8
  const query = useQuery<AxiosResponse<{ data: UserSettingModule }>>(
    ['getUserSettings', authHeader],
    () => getUserSettingModulesUrl(),
    {
      enabled: !!authHeader,
      retry: 1,
      refetchOnWindowFocus: false,
      onError: error => {
        captureMessage(`'Error fetching users currency preference'`, {
          level: 'warning',
          extra: {
            error,
          },
        })
      },
    },
  )
  const userSettings = query?.data?.data.data.json
  const selectedUserCurrency = userSettings?.currency

  return (
    <UserSettingsContext.Provider
      value={{
        query,
        selectedUserCurrency,
      }}
    >
      {props.children}
    </UserSettingsContext.Provider>
  )
}
