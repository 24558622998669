import React from 'react'

import { Button, Grid, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { FOOTER_HEIGHT } from './constants/layout'
import { PopupFooterProps } from './props'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: FOOTER_HEIGHT,
      margin: 0,

      '& > div': { padding: theme.spacing(0, 1) + ' !important' },
    },

    count: {
      display: 'block',
      marginLeft: theme.spacing(1),

      fontFeatureSettings: '"tnum"',
      color: theme.palette.text.disabled,
      userSelect: 'none',
    },

    button: { display: 'flex' },
  }),
)

export default function PopupFooter({
  multiple,
  selectAll,
  clearable,
  onSelectAll,
  onClear,
  onClose,
  countText,
  value,
  options,
  max,
}: PopupFooterProps) {
  const classes = useStyles({})

  const clearButton = (
    <Button
      onClick={onClear}
      className={classes.button}
      color="primary"
      disabled={!value || value?.length === 0}
    >
      Clear
    </Button>
  )

  if (multiple) {
    return (
      <Grid
        container={true}
        spacing={2}
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item={true}>
          <Typography variant="button" className={classes.count}>
            {countText ??
              `${value.length} of ${options.length}${
                max ? ', max ' + max : ''
              }`}
          </Typography>
        </Grid>
        {selectAll ? (
          <Grid item={true} xs={true}>
            {value.length > 0 && clearable !== false ? (
              clearButton
            ) : (
              <Button
                onClick={onSelectAll}
                className={classes.button}
                color="primary"
                disabled={value.length > 0}
              >
                Select All
              </Button>
            )}
          </Grid>
        ) : (
          clearable !== false && (
            <Grid item={true} xs={true}>
              {clearButton}
            </Grid>
          )
        )}

        <Grid item={true}>
          <Button onClick={onClose} className={classes.button} color="primary">
            Done
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (clearable) {
    return (
      <Grid
        container={true}
        spacing={2}
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item={true}>{clearButton}</Grid>
      </Grid>
    );
  }

  return null
}
