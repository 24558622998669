import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

interface Props {
  elementAccessor?: (element: HTMLElement) => HTMLElement
  debounceTime?: number
  component: string
  mxpTrack?: any
}

/**
 * This is a custom hook that is used to track the scroll position of a table.
 *
 * It is used to track the scroll position of a table when the table is scrolled horizontally.
 *
 * Arguments passed to this hook:
 * elementAccessor: a function that takes a table element and returns the table element
 * debounceTime: the amount of time to wait before sending a scroll event
 * page: the page that the table is on
 *
 * @param {Props} props
 *
 * What is returned from this hook:
 * tableRef: a reference to the table element
 */
export const useScrollTracking = ({
  elementAccessor = e => e,
  debounceTime,
  component,
  mxpTrack,
}: Props) => {
  const [lastKnownScrollPosition, setLastKnownScrollPosition] = useState(0)
  const handleNewScrollPosition = useCallback((newScrollPosition: number) => {
    const isLeftScroll = newScrollPosition < lastKnownScrollPosition
    mxpTrack('Scroll', {
      component,
      direction: isLeftScroll ? 'left' : 'right',
    })
    setLastKnownScrollPosition(newScrollPosition)
  }, [])

  const debounceMS = debounceTime || 5000

  const listenForHorizontalScroll = useCallback(
    debounce(e => {
      if (e.target.scrollLeft > 0) {
        handleNewScrollPosition(e.target.scrollLeft)
      }
    }, debounceMS),
    [debounceMS, handleNewScrollPosition],
  )

  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    const target = tableRef.current && elementAccessor(tableRef.current)
    target?.addEventListener('scroll', listenForHorizontalScroll, true)
    return () => {
      target?.removeEventListener('scroll', listenForHorizontalScroll)
    }
  }, [tableRef.current, elementAccessor, listenForHorizontalScroll])

  return { tableRef }
}

export default useScrollTracking
