import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

const height = 'auto'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      transform: `translate(${theme.spacing(1.8)}, ${theme.spacing(
        2,
      )}) scale(1);`,
      '&.shrink': {
        transform: `translate(${theme.spacing(1.8)}, -${theme.spacing(
          0.5,
        )}) scale(0.75);`,
      },
    },
    formControl: {
      height,
      borderRadius: '6px',

      '& div.MuiFormControl-root.MuiTextField-root': {
        borderRadius: '6px',
        height,
      },
      '& div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        height,
      },
      '& div.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
        borderRadius: '6px',
        padding: 14,
      },
    },
  }),
)

interface Props {
  control: Control
  rules?: any
  errors?: any
  name: string
  disabled?: boolean
}

const FormSelect: React.FC<FormControlProps & TextFieldProps & Props> = ({
  name,
  label,
  control,
  defaultValue,
  rules,
  variant,
  errors,
  children,
  disabled,
  ...props
}) => {
  const classes = useStyles({})
  return (
    <FormControl variant="standard" {...props} className={classes.formControl}>
      <Controller
        as={
          <TextField
            error={errors && true}
            select={true}
            variant={variant}
            label={label}
            disabled={disabled}
            InputLabelProps={{
              classes: {
                shrink: 'shrink',
                root: classes.label,
              },
            }}
          >
            {children}
          </TextField>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
      />
      {errors && <FormHelperText error={true}>{errors.message}</FormHelperText>}
    </FormControl>
  )
}

export { FormSelect }
