import { Box, Chip, IconButton, Tooltip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { Heading } from '../..'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { BaseTablePanel } from '../base-panel'
import useStyles from '../styles'

export interface TFilterState {
  [key: string]: any
}
interface SortingPanelProps {
  onClear?: () => void
  filterState?: TFilterState
  handleDelete?: (key: string) => void
}

export const FilterPanel: FC<SortingPanelProps> = ({
  onClear,
  filterState,
  handleDelete,
}) => {
  const classes = useStyles({})
  const intl = useIntl()
  const filterChips = () =>
    filterState &&
    Object.keys(filterState).map(key => {
      return (
        <Chip
          key={key}
          className={classes.chip}
          data-testid={`filter-chip-${key}`}
          label={`${key}: ${filterState[key]}`}
          deleteIcon={
            handleDelete ? (
              <CancelIcon
                data-testid={`filter-chip-cancel-button-${key}`}
                className={classes.cancelIcon}
              />
            ) : (
              <></>
            )
          }
          onDelete={handleDelete ? () => handleDelete(key) : () => {}}
        />
      )
    })

  return (
    <BaseTablePanel>
      <Box className={classes.headingContainerFilter}>
        <FilterListRoundedIcon className={classes.icon} />
        <Heading variant="h3">
          {intl.formatMessage({
            id: 'general.filter.panel.header',
          })}
        </Heading>
      </Box>
      <Box className={classes.chipContainer}>{filterChips()}</Box>
      {onClear && (
        <Box className={classes.removeIconContainer}>
          <Tooltip
            title={intl.formatMessage({
              id: 'general.filter.panel.clear.all.tooltip',
            })}
          >
            <IconButton
              data-testid={'clear-all-filters'}
              onClick={onClear}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </BaseTablePanel>
  )
}
