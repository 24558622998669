import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { withDirection as WithDirection } from '../withDirection'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 30,
      WebkitOverflowScrolling: 'touch',
      '& .demoBtn': {
        marginRight: (props: any) =>
          props['data-rtl'] === 'rtl' ? '0' : '10px',
        marginLeft: (props: any) =>
          props['data-rtl'] === 'rtl' ? '10px' : '0',
      },
      '& .demoBtnsWrapper': {
        '&:nth-child(1)': {
          marginLeft: (props: any) =>
            props['data-rtl'] === 'rtl' ? '0' : '70px',
          marginRight: (props: any) =>
            props['data-rtl'] === 'rtl' ? '70px' : '0',
          whiteSpace: 'nowrap',
        },
        '&:nth-child(2)': {
          width: 70,
          float: (props: any) =>
            props['data-rtl'] === 'rtl' ? 'right' : 'left',
        },
        '&:nth-child(3)': {
          width: 70,
          marginLeft: (props: any) =>
            props['data-rtl'] === 'rtl' ? '0' : '295px',
          marginRight: (props: any) =>
            props['data-rtl'] === 'rtl' ? '295px' : '0',
        },
        '&:nth-child(4)': {
          marginLeft: (props: any) =>
            props['data-rtl'] === 'rtl' ? '0' : '70px',
          marginRight: (props: any) =>
            props['data-rtl'] === 'rtl' ? '70px' : '0',
          clear: 'both',
          whiteSpace: 'nowrap',
        },
        [`${theme.breakpoints.down('xs')}`]: {
          marginLeft: (props: any) =>
            props['data-rtl'] === 'rtl' ? '8px' : '0 !important',
          whiteSpace: 'nowrap',
          marginRight: (props: any) =>
            props['data-rtl'] === 'rtl' ? '0 !important' : '8px',
          float: 'none !important',
        },
        '& .demoPosBtn': {
          width: 70,
          marginRight: (props: any) =>
            props['data-rtl'] === 'rtl' ? '0' : '8px',
          marginLeft: (props: any) =>
            props['data-rtl'] === 'rtl' ? '8px' : '0',
          marginBottom: 8,
          padding: 0,
          [`${theme.breakpoints.down('xs')}`]: {
            width: 65,
          },
        },
      },
      '& .ant-btn': {
        '&:not(.demoPosBtn)': {
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
      '& .tooltipBtn': {
        display: 'inline-block',
        lineHeight: 32,
        height: 32,
        width: 70,
        fontSize: 14,
        textAlign: 'center',
        background: theme.palette.secondary.main,
        marginRight: (props: any) =>
          props['data-rtl'] === 'rtl' ? 'auto' : '6px',
        marginLeft: (props: any) => (props['data-rtl'] === 'rtl' ? '6px' : '0'),
        marginBottom: 6,
        borderRadius: 6,
      },
      '& .ant-progress': {
        '&.ant-progress-circle': {
          marginRight: (props: any) =>
            props['data-rtl'] === 'rtl' ? '0' : '15px',
          marginLeft: (props: any) =>
            props['data-rtl'] === 'rtl' ? '15px' : '0',
          '&:last-child': {
            marginRight: (props: any) =>
              props['data-rtl'] === 'rtl' ? '0' : '0',
            marginLeft: (props: any) =>
              props['data-rtl'] === 'rtl' ? '0' : '0',
          },
        },
      },
      '& strong': {
        fontWeight: 700,
        fontSize: 14,
      },
    },
  }),
)

const WDContentHolderWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const styles = useStyles({})
  return <div className={styles.root}>{children}</div>
}

const ContentHolderWrapper = WithDirection(WDContentHolderWrapper)

export { ContentHolderWrapper }
