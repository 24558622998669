import { Box, Grid, Skeleton, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { Container } from '..'
import useStyles from './styles'

export interface Props<T> {
  data: T
  isLoading: boolean
}

const DynamicCards = <T extends any[]>({ data, isLoading }: Props<T>) => {
  const classes = useStyles({})
  const theme = useTheme()
  const { formatMessage } = useIntl()
  return (
    <Box className={classes.statsCards} data-testid="dynamic-cards-container">
      {isLoading ? (
        <Skeleton
          style={{ height: 80, flexGrow: 1, marginRight: theme.spacing(2) }}
        />
      ) : (
        <Grid container={true}>
          {data?.map(
            ({
              label,
              count,
            }: {
              label: string
              count: number | undefined
            }) => {
              return (
                <Grid
                  item={true}
                  key={`dynamic-card-div-${label}`}
                  lg={data.length === 2 ? 6 : data.length === 3 ? 4 : 3}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Container
                    marginRight={16}
                    flexGrow={1}
                    customClass="stats_card"
                  >
                    <Box className={classes.statsCardTitle}>
                      {formatMessage({
                        id: String(label),
                      })}
                    </Box>
                    <Box className={classes.statsCardData}> {count}</Box>
                  </Container>
                </Grid>
              )
            },
          )}
        </Grid>
      )}
    </Box>
  )
}

export { DynamicCards }
