import { ConfirmDialog } from '../'
import { ReactNode, useCallback, useState } from 'react'

interface TDialog {
  title: string
  content?: string | ReactNode
  extraContent?: string | ReactNode
  onConfirm: Function
  confirmText?: string
  keepModalOpenAfterConfirm?: boolean
  confirmIcon?: ReactNode | undefined
  usePrimaryStylesForConfirm?: boolean
  cancelNotRequired?: boolean
}

type AskMethod = ({}: TDialog) => void

export interface TAsk {
  confirm: AskMethod
}

export const useConfirmDialog = () => {
  const [dialog, _setDialog] = useState<TDialog>({
    title: '',
    content: '',
    extraContent: '',
    onConfirm: () => {},
    confirmText: '',
    keepModalOpenAfterConfirm: false,
    confirmIcon: undefined,
    usePrimaryStylesForConfirm: false,
    cancelNotRequired: false,
  })

  const [isOpen, setIsOpen] = useState(false)

  const setDialog = (state: TDialog) => {
    setIsOpen(true)
    _setDialog(state)
  }

  const ask: TAsk = {
    confirm: ({
      title,
      content,
      extraContent,
      confirmText,
      onConfirm,
      keepModalOpenAfterConfirm,
      confirmIcon,
      usePrimaryStylesForConfirm,
      cancelNotRequired,
    }: TDialog) =>
      setDialog({
        title,
        content,
        extraContent,
        confirmText,
        onConfirm,
        keepModalOpenAfterConfirm,
        confirmIcon,
        usePrimaryStylesForConfirm,
        cancelNotRequired,
      }),
  }
  return {
    ConfirmDialog: useCallback(
      () => (
        <ConfirmDialog
          open={isOpen}
          setOpen={setIsOpen}
          title={dialog.title}
          content={dialog.content || ''}
          extraContent={dialog.extraContent || ''}
          confirmText={dialog.confirmText || ''}
          onConfirm={dialog.onConfirm}
          keepModalOpenAfterConfirm={dialog.keepModalOpenAfterConfirm}
          confirmIcon={dialog.confirmIcon}
          usePrimaryStylesForConfirm={dialog.usePrimaryStylesForConfirm}
          cancelNotRequired={dialog.cancelNotRequired}
        />
      ),
      [isOpen, dialog],
    ),
    ask,
  }
}
