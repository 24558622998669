export const getLocalStorageValues = <T,>(
  localStorageKey?: string,
): T | undefined => {
  if (!localStorageKey) {
    return
  }
  if (typeof window == 'undefined') {
    return
  }
  const storedValuesString = localStorage.getItem(localStorageKey)
  if (!storedValuesString) {
    return
  }
  const storedValues = JSON.parse(storedValuesString)
  if (typeof storedValues !== 'object') {
    return
  } else {
    return storedValues
  }
}
