import RemoveIcon from '@mui/icons-material/Remove'
import {
  StyledEngineProvider,
  TextField,
  Theme,
  ThemeProvider,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { DateEnum } from '.'
import { materialTheme, useStyles } from '../custom-date-time-picker/styles'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const DateRangePickerForForm: React.FC<any> = ({
  startDateLabel,
  endDateLabel,
  disablePast,
  disableFuture,
  register,
  getValues,
  handleDateChange,
}) => {
  const style = useStyles({})
  const [isFocus, setIsFocus] = useState({
    start: false,
    end: false,
  })
  const containerStyle = () => {
    if (isFocus.start || isFocus.end) {
      return style.containerBlueShadow
    }
    return style.container
  }
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={materialTheme}>
        <div className={containerStyle()}>
          <DatePicker
            value={getValues(DateEnum.startDate) || null}
            maxDate={getValues(DateEnum.endDate)}
            onChange={(value: any) =>
              handleDateChange(value, DateEnum.startDate)
            }
            disablePast={
              typeof disablePast !== 'undefined' ? disablePast : false
            }
            disableFuture={
              typeof disableFuture !== 'undefined' ? disableFuture : true
            }
            label={startDateLabel}
            InputProps={{
              classes: {
                root: style.outlineHover,
                focused: style.focus,
                notchedOutline: isFocus.end
                  ? style.notchedOutlineStartDateFocus
                  : style.notchedOutlineStartDate,
              },
            }}
            inputRef={register ? register('startDate') : null}
            renderInput={(props: any) => (
              <TextField
                {...props}
                placeholder={'No Date'}
                variant="outlined"
                inputProps={{
                  'aria-label': `datepicker-from`,
                  'data-testid': `datepicker-from`,
                }}
                onFocus={() => setIsFocus({ ...isFocus, start: true })}
                onBlur={() => setIsFocus({ ...isFocus, start: false })}
                value={
                  getValues(DateEnum.startDate)
                    ? dayjs(getValues(DateEnum.startDate)).format('MM/DD/YYYY')
                    : ''
                }
              />
            )}
          />
          <div
            className={
              isFocus.start || isFocus.end ? style.dashFocus : style.dash
            }
          >
            <RemoveIcon className={style.dashIcon} />
          </div>
          <DatePicker
            value={getValues(DateEnum.endDate) || null}
            minDate={getValues(DateEnum.startDate)}
            onChange={(value: any) => handleDateChange(value, DateEnum.endDate)}
            // autoOk={true}
            // format={dateStructure}
            disablePast={
              typeof disablePast !== 'undefined' ? disablePast : false
            }
            disableFuture={
              typeof disableFuture !== 'undefined' ? disableFuture : true
            }
            // fullWidth={true}
            // variant="inline"
            // inputVariant="outlined"
            label={endDateLabel}
            InputProps={{
              classes: {
                root: style.outlineHover,
                focused: style.focus,
                notchedOutline: isFocus.start
                  ? style.notchedOutlineEndDateFocus
                  : style.notchedOutlineEndDate,
              },
            }}
            inputRef={register ? register('endDate') : null}
            renderInput={(props: any) => (
              <TextField
                {...props}
                inputProps={{
                  'aria-label': `datepicker-to`,
                  'data-testid': `datepicker-to`,
                }}
                variant="outlined"
                onFocus={() => setIsFocus({ ...isFocus, end: true })}
                onBlur={() => setIsFocus({ ...isFocus, end: false })}
                placeholder={'No Date'}
                value={
                  getValues(DateEnum.endDate)
                    ? dayjs(getValues(DateEnum.endDate)).format('MM/DD/YYYY')
                    : ''
                }
              />
            )}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default DateRangePickerForForm
