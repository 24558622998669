import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingRight: theme.spacing(0),
    },
  }),
)

export const PageHeaderToolBar: FC = ({ children }) => {
  const classes = useStyles({})
  return <div className={classes.root}>{children}</div>
}
