import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  IconButton,
  InputAdornment,
  StyledEngineProvider,
  TextField,
  Theme,
  ThemeProvider,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import {
  materialTheme,
  useStyles,
} from '../custom-date-time-picker/styles'
import { IDatePickerProps } from './use.date.range.picker'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const DateRangePicker: React.FC<IDatePickerProps> = ({
  setDateLimit,
  errorHandler,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  startDateLabel,
  endDateLabel,
  isFutureAllowed,
  error,
  isCustomStyle,
}) => {
  const style = useStyles({})
  const [isFocus, setIsFocus] = useState({
    start: false,
    end: false,
  })
  const containerStyle = () => {
    if (isFocus.start || isFocus.end) {
      return style.containerBlueShadow
    }
    return style.container
  }
  const minDate =
    typeof startDate === 'string'
      ? dayjs(startDate + 'T16:00:00.000Z')
      : startDate
  const maxDate =
    typeof endDate === 'string' ? dayjs(endDate + 'T16:00:00.000Z') : endDate
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={materialTheme}>
        <div
          className={containerStyle()}
          data-test="rangeDatePicker"
          style={{
            marginTop: isCustomStyle ? '0px' : '20px',
            display: 'flex',
            width: '100%',
          }}
        >
          <DatePicker
            onError={errorHandler}
            maxDate={!isFutureAllowed ? setDateLimit(maxDate, minDate) : ''}
            value={minDate?.toDate() || null}
            onChange={(value: any) => {
              handleStartDateChange(value)
            }}
            disableFuture={!isFutureAllowed}
            label={startDateLabel}
            renderInput={(props: any) => (
              <TextField
                {...props}
                fullWidth={true}
                placeholder={'No Date'}
                variant="outlined"
                onFocus={() => setIsFocus({ ...isFocus, start: true })}
                onBlur={() => setIsFocus({ ...isFocus, start: false })}
                inputProps={{
                  ...props.inputProps,
                  placeholder: 'No Date',
                  'aria-label': `datepicker-from`,
                  'data-testid': `datepicker-from`,
                }}
                InputProps={{
                  ...props.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {minDate !== null && (
                        <IconButton
                          onClick={() => handleStartDateChange(null)}
                          size="large"
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  classes: {
                    root:
                      error && !isFocus.end && !isFocus.start
                        ? style.outlineHoverError
                        : style.outlineHover,
                    focused: style.focus,
                    notchedOutline: isFocus.end
                      ? style.notchedOutlineStartDateFocus
                      : style.notchedOutlineStartDate,
                  },
                }}
              />
            )}
          />
          <div
            className={
              isFocus.start || isFocus.end
                ? style.dashFocus
                : error
                ? style.dashError
                : style.dash
            }
          >
            <RemoveIcon className={style.dashIcon} />
          </div>
          <DatePicker
            onError={errorHandler}
            minDate={setDateLimit(minDate, maxDate)}
            value={maxDate}
            onChange={(value: any) => handleEndDateChange(value)}
            disableFuture={!isFutureAllowed}
            label={endDateLabel}
            renderInput={(props: any) => (
              <TextField
                {...props}
                fullWidth={true}
                placeholder={'No Date'}
                onFocus={() => setIsFocus({ ...isFocus, end: true })}
                onBlur={() => setIsFocus({ ...isFocus, end: false })}
                inputProps={{
                  ...props.inputProps,
                  placeholder: 'No Date',
                  'aria-label': `datepicker-to`,
                  'data-testid': `datepicker-to`,
                }}
                InputProps={{
                  ...props.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {maxDate !== null && (
                        <IconButton
                          onClick={() => handleEndDateChange(null)}
                          size="large"
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  classes: {
                    root:
                      error && !isFocus.end && !isFocus.start
                        ? style.outlineHoverError
                        : style.outlineHover,
                    focused: style.focus,
                    notchedOutline: isFocus.start
                      ? style.notchedOutlineEndDateFocus
                      : style.notchedOutlineEndDate,
                  },
                }}
              />
            )}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default DateRangePicker
