import { TextField } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { DateTimePicker as DateTimePickerMUI } from '@mui/x-date-pickers/DateTimePicker'
import React, { useState } from 'react'
import { DateTimePickerProps } from './'
import { useStyles } from './styles'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  date,
  disabled,
  handleDateChange,
  dateLabel,
  onAccept,
  open,
  setOpen,
  width,
}) => {
  const style = useStyles({width})
  const theme = useTheme()
  const [isFocus, setIsFocus] = useState(false)
  const containerStyle = () => {
    if (isFocus) {
      return style.containerBlueShadow
    }
    return style.container
  }
  return (
    <div
      className={containerStyle()}
      style={{ marginTop: '10px' }}
      data-testid="date-time-picker"
    >
      <DateTimePickerMUI
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disabled={disabled}
        value={date}
        label={dateLabel ? dateLabel : null}
        onChange={handleDateChange}
        onAccept={onAccept}
        inputFormat="yyyy-MM-dd HH:mm"
        disablePast={true}
        InputProps={{
          classes: {
            root: style.outlineHover,
            focused: style.focus,
          },
        }}
        renderInput={(props: any) => (
          <TextField
            {...props}
            style={{ marginRight: theme.spacing(3) }}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
        )}
      />
    </div>
  )
}

export default DateTimePicker
