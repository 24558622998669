import { AxiosPromise } from 'axios'
import qs from 'qs'
import { authenticationServerUri } from '../../config/index'
import {
  THeaders,
  TQueryParams,
  TResponse,
  // TOAuthTokenResponse,
} from '@novafuturltd/shared'
import client from './index'

export default {
  get: (
    url: string,
    params: TQueryParams = {},
    headers: THeaders = {},
  ): AxiosPromise<TResponse> =>
    client({
      method: 'GET',
      url,
      baseURL: `${authenticationServerUri}/`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  post: (
    url: string,
    data: { [key: string]: any },
    headers: THeaders = {},
  ): AxiosPromise<any> =>
    client({
      method: 'POST',
      url,
      baseURL: `${authenticationServerUri}/`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  patch: (
    url: string,
    data: {} = {},
    headers: THeaders = {},
  ): AxiosPromise<TResponse> =>
    client({
      method: 'PATCH',
      url,
      baseURL: `${authenticationServerUri}/`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  delete: (
    url: string,
    params: TQueryParams = {},
    headers: THeaders = {},
  ): AxiosPromise<TResponse> =>
    client({
      method: 'DELETE',
      url,
      baseURL: `${authenticationServerUri}/`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
}
