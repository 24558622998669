import { Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { useIntl } from 'react-intl'

export const TotalUsers = ({
  rows,
  users,
}: {
  rows?: number
  users?: number
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  if (!rows && !users) return null

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={theme.spacing(0, 2)}
        fontSize="12px"
        fontWeight="bold"
        whiteSpace={'nowrap'}
      >
        {formatMessage(
          { id: users ? 'total.users' : 'total.rows' },
          { users, rows },
        )}
      </Typography>
    </Box>
  )
}
