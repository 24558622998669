import { useMemo, useState } from 'react'
import {
  useBrowserQuery,
  useBrowserQueryCustomerOverview,
} from './use.browserQuery'

export interface SortingStateLocal {
  removeSortingColumn: (columnName: string) => [string, string][]
  addOrUpdateSortingColumn: ([columnName, direction]: [string, string]) => void
  singleSort: (column: [string, string]) => void
  clearAllSorting: () => void
  sortingState: [string, string][]
}

export const useSortingStateLocal = (
  defaultSorting?: [string, string][],
): SortingStateLocal => {
  const [query] = useBrowserQuery()
  const defaultSort = defaultSorting || []
  const [sortingState, setSortingState] = useState<[string, string][]>(
    query.sorting.length > 0 ? query.sorting : defaultSort,
  )

  const findSortingIndexByColumnName = (columnName: string) =>
    sortingState.findIndex(
      ([itemColName, _]: [string, string]) => itemColName === columnName,
    )

  const removeSortingColumn = (columnName: string) => {
    const updatedSorting = [...sortingState]
    updatedSorting.splice(findSortingIndexByColumnName(columnName), 1)
    setSortingState(updatedSorting.length > 0 ? updatedSorting : defaultSort)
    return updatedSorting
  }

  const addOrUpdateSortingColumn = ([columnName, direction]: [
    string,
    string,
  ]) => {
    let updatedSorting = [...sortingState]
    const existingIndex = findSortingIndexByColumnName(columnName)
    if (existingIndex !== -1) {
      updatedSorting[findSortingIndexByColumnName(columnName)] = [
        columnName,
        direction.toUpperCase(),
      ]
    } else {
      updatedSorting = [
        ...updatedSorting,
        [columnName, direction.toUpperCase()],
      ]
    }
    return setSortingState(updatedSorting)
  }

  const singleSort = (column: [string, string]) => {
    setSortingState([[column[0], column[1].toUpperCase()]])
  }

  const clearAllSorting: () => void = () => {
    return setSortingState([])
  }

  return useMemo(() => {
    const defaultReturnValues = {
      removeSortingColumn,
      addOrUpdateSortingColumn,
      singleSort,
      clearAllSorting,
    }

    if (defaultSorting && defaultSorting.length > 0) {
      return {
        sortingState: sortingState.length > 0 ? sortingState : defaultSorting,
        ...defaultReturnValues,
      }
    }
    return {
      sortingState,
      ...defaultReturnValues,
    }
  }, [sortingState, defaultSorting])
}

export const useSortingStateLocalObject = (
  defaultSorting?: { [key: string]: any }[],
) => {
  const defaults = defaultSorting || [{}]
  const [query] = useBrowserQueryCustomerOverview()
  const [sortingState, setSortingState] = useState<{ [key: string]: any }[]>(
    query.sorting.length > 0 ? query.sorting : defaults,
  )

  const findSortingIndexByColumnName = (columnName: string) =>
    sortingState.findIndex(col => col['sort'] === columnName)

  const removeSortingColumn = (columnName: string) => {
    const updatedSorting = [...sortingState]
    updatedSorting.splice(findSortingIndexByColumnName(columnName), 1)
    setSortingState(updatedSorting.length > 0 ? updatedSorting : defaults)
    return updatedSorting
  }

  const addOrUpdateSortingColumn = (col: { [key: string]: any }) => {
    let updatedSorting = [...sortingState]
    const existingIndex = findSortingIndexByColumnName(col.sort)
    if (existingIndex !== -1) {
      updatedSorting[findSortingIndexByColumnName(col.sort)] = {
        ['sort']: col.sort,
        sort_direct: col.sort_direct.toUpperCase(),
      }
    } else {
      updatedSorting = [
        ...updatedSorting,
        { ['sort']: col.sort, sort_direct: col.sort_direct.toUpperCase() },
      ]
    }
    return setSortingState(updatedSorting)
  }

  const singleSort = (col: { [key: string]: any }) => {
    setSortingState([
      { sort: col.sort, sort_direct: col.sort_direct.toUpperCase() },
    ])
  }

  const clearAllSorting: () => void = () => {
    return setSortingState([])
  }

  const defaultReturnValues = {
    removeSortingColumn,
    addOrUpdateSortingColumn,
    singleSort,
    clearAllSorting,
    setSortingState,
  }

  if (defaultSorting && defaultSorting.length > 0) {
    return {
      sortingState: sortingState.length > 0 ? sortingState : defaultSorting,
      ...defaultReturnValues,
    }
  }

  return {
    sortingState,
    ...defaultReturnValues,
  }
}
