/**
 * Test any variable to see if it is an object, a.k.a. curly braces: {}
 * @param  {any} variable The variable to test
 * @return {boolean}      Wether it is an object or not
 */
const isObject = (variable: any): boolean => {
  return (
    typeof variable === 'object' &&
    !Array.isArray(variable) &&
    variable !== null
  )
}

/**
 * Test any variable to see if it is an empty object, i.e. `{}` return `true`
 * @param  {any} variable The variable to test
 * @return {boolean}      Wether it is an empty object or not
 */
export const isEmptyObject = (variable: any): boolean => {
  return (
    isObject(variable) &&
    Object.keys(variable).length === 0 &&
    Object.getPrototypeOf(variable) === Object.prototype
  )
}
