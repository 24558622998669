import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { forwardRef } from 'react'

export const PopupIcon = forwardRef((props: SvgIconProps, ref) => {
  return (
    <SvgIcon {...props} ref={ref as any}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 19.8787V6H4V19.8293H17.8293V21.8293H3.95059C2.87331 21.8293 2 20.956 2 19.8787Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03096 4.03096V15.969H19.969V4.03096H8.03096ZM8 2C6.89543 2 6 2.89543 6 4V16C6 17.1046 6.89543 18 8 18H20C21.1046 18 22 17.1046 22 16V4C22 2.89543 21.1046 2 20 2H8Z"
      />
      <path d="M16 7H19V8H16V7Z" />
      <path d="M16 9H19V10H16V9Z" />
      <path d="M16 11H19V12H16V11Z" />
      <path d="M16 13H18V14H16V13Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7H9V15H15V7ZM14.1431 14L12.6871 11L11.5766 13.288L10.9082 12.0435L9.85742 14H14.1431Z"
      />
    </SvgIcon>
  )
})
