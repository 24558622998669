import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import clsx from 'clsx'
import { FC, MutableRefObject } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: (props: any) =>
        props.minHeight === false ? 'none' : `calc(100vh - 168px)`,
      border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      borderRadius: '12px',
      background: NovaColours.N100[theme.palette.mode],
      position: 'relative',
      flexGrow: 1,
      paddingBottom: (props: any) =>
        props.paddingBottom ? props.paddingBottom : '18px',
    },
  }),
)

export const BorderedContainer: FC<{
  paddingBottom?: any
  className?: any
  style?: any
  minHeight?: boolean
  innerRef?: MutableRefObject<HTMLDivElement | null>
}> = ({
  paddingBottom,
  children,
  className,
  style,
  minHeight,
  innerRef,
  ...props
}) => {
  const classes = useStyles({ minHeight, paddingBottom })

  return (
    <div
      className={className ? clsx(classes.root, className) : classes.root}
      ref={innerRef}
      style={style ? style : {}}
      {...props}
    >
      {children}
    </div>
  )
}
