import { IconButton, InputBase, MenuItem, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import React, { FC, useEffect, useState } from 'react'
import { CustomInput, useStyles } from './style'

interface Props {
  onWordChange: (props: any) => void
  handleSubmit: (props: any) => void
  onSelectChange: (props: any) => void
  searchWord: string
  searchTerm: string
  handleClearSearch: () => void
  menuItems: { name: string; value: string }[]
}

export const ExpandableSelectSearch: FC<Props> = ({
  onWordChange,
  handleSubmit,
  onSelectChange,
  searchWord,
  searchTerm,
  handleClearSearch,
  menuItems,
}) => {
  const [focus, setFocus] = useState(false)
  const [width, setWidth] = useState('0ch')
  const classes = useStyles({ width })

  const clearSearch = () => {
    handleClearSearch()
    setFocus(false)
  }

  useEffect(() => {
    if (!focus) {
      const timer = setTimeout(() => {
        setWidth('0ch')
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [focus])

  return (
    <div>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon className={classes.icon} />
        </div>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={({ target: { value } }) => {
            if (value !== '' && width !== '10ch') {
              setWidth('10ch')
            }
            onWordChange(value)
          }}
          value={searchWord}
          onKeyPress={event => handleSubmit(event)}
          placeholder="Search..."
          onFocus={() => {
            setWidth('10ch')
            setFocus(true)
          }}
          onBlur={event => {
            if (!event.target.value) {
              setWidth('0ch')
              setFocus(false)
            }
          }}
          startAdornment={
            <Select
              variant="outlined"
              className={classes.select}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={searchTerm}
              onChange={event => onSelectChange(event.target.value)}
              onFocus={() => {
                setWidth('10ch')
                setFocus(true)
              }}
              onBlur={() => setFocus(false)}
              MenuProps={{
                classes: { paper: classes.dropdown },
                variant: 'menu',
              }}
              input={<CustomInput />}
            >
              {menuItems.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          }
          endAdornment={
            <>
              <div style={{ width: width === '10ch' ? '56px' : '0' }}>
                {searchWord && (
                  <IconButton onClick={clearSearch} size="large">
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}
