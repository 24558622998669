import RemoveIcon from '@mui/icons-material/Remove'
import {
  Box,
  StyledEngineProvider,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import { RangeDateTimePickerProps } from '.'
import { materialTheme, useStyles } from './styles'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const RangeDateTimePicker: React.FC<{
  disablePast?: boolean
  minDate?: Date
  disableFuture?: boolean
} & RangeDateTimePickerProps> = ({
  handleDateChange,
  startDateLabel,
  endDateLabel,
  register,
  watch,
  disablePast,
  disablePicker,
  minDate,
  name,
  onAcceptEndDate,
  errors,
  disableFuture,
  defaultValues = { startDate: null, endDate: null },
}) => {
  const style = useStyles({})
  const [isFocus, setIsFocus] = useState({
    start: false,
    end: false,
  })
  const containerStyle = () => {
    if (isFocus.start || isFocus.end) {
      return style.containerBlueShadow
    }
    return style.container
  }

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={materialTheme}>
        <div className={containerStyle()} data-test="rangeDateTimePicker">
          <DateTimePicker
            value={
              watch(name.startDate)
                ? watch(name.startDate)
                : defaultValues?.startDate
                ? defaultValues?.startDate
                : null
            }
            label={startDateLabel ? startDateLabel : null}
            onChange={(value: any) => handleDateChange(value, name.startDate)}
            // variant={pickerType ? pickerType : 'inline'}
            // inputVariant="outlined"
            // allowKeyboardControl={true}
            // ampm={false}
            disablePast={
              typeof disablePast !== 'undefined' ? disablePast : true
            }
            disableFuture={
              typeof disableFuture !== 'undefined' ? disableFuture : true
            }
            // format="YYYY/MM/DD HH:mm"
            // placeholder="YYYY/MM/DD HH:mm"
            className={style.dateWidth}
            InputProps={{
              classes: {
                root: style.outlineHover,
                focused: style.focus,
                notchedOutline: isFocus.end
                  ? style.notchedOutlineStartDateFocus
                  : style.notchedOutlineStartDate,
              },
            }}
            maxDate={watch(name.endDate) || undefined}
            inputRef={register(name.startDate)}
            disabled={disablePicker}
            // minDateMessage={minDateMessage || ''}
            minDate={minDate}
            renderInput={(props: any) => (
              <TextField
                {...props}
                onFocus={() => setIsFocus({ ...isFocus, start: true })}
                onBlur={() => setIsFocus({ ...isFocus, start: false })}
              />
            )}
          />
          <div
            className={
              isFocus.start || isFocus.end ? style.dashFocus : style.dash
            }
          >
            <RemoveIcon className={style.dashIcon} />
          </div>
          <DateTimePicker
            value={
              watch(name.endDate)
                ? watch(name.endDate)
                : defaultValues?.endDate
                ? defaultValues?.endDate
                : null
            }
            label={endDateLabel ? endDateLabel : null}
            onChange={(value: any) => handleDateChange(value, name.endDate)}
            // variant={pickerType ? pickerType : 'inline'}
            // inputVariant="outlined"
            // allowKeyboardControl={true}
            // ampm={false}
            disablePast={
              typeof disablePast !== 'undefined' ? disablePast : true
            }
            disableFuture={
              typeof disableFuture !== 'undefined' ? disableFuture : true
            }
            // format="YYYY/MM/DD HH:mm"
            // placeholder="YYYY/MM/DD HH:mm"
            className={style.dateWidth}
            InputProps={{
              classes: {
                root: style.outlineHover,
                focused: style.focus,
                notchedOutline: isFocus.start
                  ? style.notchedOutlineEndDateFocus
                  : style.notchedOutlineEndDate,
              },
            }}
            minDate={watch(name.startDate) || undefined}
            inputRef={register(name.endDate)}
            disabled={disablePicker}
            // minDateMessage={minDateMessage || ''}
            onAccept={onAcceptEndDate}
            renderInput={(props: any) => (
              <TextField
                {...props}
                variant="outlined"
                onFocus={() => setIsFocus({ ...isFocus, end: true })}
                onBlur={() => setIsFocus({ ...isFocus, end: false })}
              />
            )}
          />
        </div>
        {errors && (
          <Box
            className={style.boxError}
            height="24px"
            style={{ justifyContent: 'left' }}
          >
            <Typography variant="caption">{errors}</Typography>
          </Box>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default RangeDateTimePicker
