import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      width: '100%',
      padding: theme.spacing(0),
      position: 'relative',
    },
    filterInput: {
      [`& fieldset`]: {
        border: 'none',
      },
    },
    bottomBorderlessRow: {
      '& .MuiTableRow-root': {
        '&:not(:hover)': {
          background: 'transparent',
        },
        '&:last-child td': {
          borderBottom: 0,
        },
      },
      borderRadius: '6px',
    },
  }),
)

export default useStyles;
